<template>
    <div>

      <v-row class="pl-10 pr-10" v-show="showWelcome && fullName">
        <v-col cols="6">
          <span class="title"> {{$vuetify.lang.t('$vuetify.dashboard.welcomeBack')}}</span>
          <span class="title" style="color:#f44305"> {{fullName}} </span>
        </v-col>
      </v-row>

      <v-row class="pl-10 pr-10">

        <v-col justify="center" align="center"
                v-for="(link,idx)  in links"
                :key="idx"
                cols="4"
        >
          <summary-link :title=link.title :description="link.description" :url="link.url"></summary-link>
        </v-col>
      </v-row>

      <v-row class="pl-10 pr-10" v-if="company">
        <v-col cols="12">
          <code-box
                  :title="this.$vuetify.lang.t('$vuetify.dashboard.code')"
                  :help="this.$vuetify.lang.t('$vuetify.dashboard.codeHelp')"
                  :code="companyData.on_boarding_script"
          />
        </v-col>

      </v-row>
    </div>

</template>

<script>

  import summaryLink from "../base/summaryLink"
  import codeBox from "../base/codeBox"

  export default {
    name: "dashboard-old",
    props: {
      company: {
        type: Object,
        default: null
      },
    },
    components: {
      summaryLink,
      codeBox
    },
    data () {
      return {
        showWelcome: true,
        fullName: this.getFullName(),
        companyData: {
          on_boarding_script: ''
        },
        links: [{
          title: this.$vuetify.lang.t('$vuetify.dashboard.links.list.title'),
          description: this.$vuetify.lang.t('$vuetify.dashboard.links.list.description'),
          url: '/contact-list'
        },{
          title: this.$vuetify.lang.t('$vuetify.dashboard.links.campaigns.title'),
          description: this.$vuetify.lang.t('$vuetify.dashboard.links.campaigns.description'),
          url: '/campaigns'
        },{
          title: this.$vuetify.lang.t('$vuetify.dashboard.links.documents.title'),
          description: this.$vuetify.lang.t('$vuetify.dashboard.links.documents.description'),
          url: '/documents'
        },

        ]
      }
    },
    methods: {
      getFullName() {
        let name = null
        if(this.$store.getters.user.name){
          name = this.$store.getters.user.name
        }
        if(name && this.$store.getters.user.last_name){
          name +=" " + this.$store.getters.user.last_name
        }

        return name
      }
    },
    mounted() {
      //this.showWelcome = this.$store.getters.showWelcome
    },
    destroyed() {
      //this.$store.commit('setShowWelcome', false);
    }
  }
</script>

<style scoped>

</style>