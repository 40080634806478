<template>
  <img :src="dsConversations">
</template>

<script>
  import dsConversations from '../../images/icons/ds-conversations-selected.svg'

  export default {
    name: "ds-conversations-selected",
    components: {
      dsConversations
    },
    data () {
      return {
        dsConversations: dsConversations,

      }
    }
  }
</script>

<style scoped>

</style>