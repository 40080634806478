import connector from './connector'
import {EventBus} from "../packs/app";

const CAMPAIGN_URI = '/campaign';
const CAMPAIGN_ESTIMATES_URI = CAMPAIGN_URI + '/estimate';

const campaignConnector = {

  schedule (component,campaign) {
    connector.sendWithFile(component,
    CAMPAIGN_URI,
    campaign,
      "POST",
    function (component,response) {
      component.showResultOfAction(response.data.response);
    });
  },
  update (component,campaign) {
    connector.sendWithFile(component,
      CAMPAIGN_URI,
      campaign,
      "PUT",
      function (component,response) {
        component.showResultOfAction(response.data.response);
      });
  },
  deleteCampaign (component,campaignId) {
    connector.send(component,
      {
        method: 'DELETE',
        url: CAMPAIGN_URI + "/" + campaignId,
      },function (component,response) {
        component.receiveUpdateStatusResponse(response.data.response);
      });
  },
  estimates (component,campaignFacts) {
    connector.send(component,
      {
        method: 'post',
        url: CAMPAIGN_ESTIMATES_URI,
        data: campaignFacts
      },function (component,response) {
        component.updateEstimates(response.data.response);
      });
  },
  findCampaignDetails(component, campaignId){
    connector.send(component,
      {
        method: 'get',
        url: CAMPAIGN_URI+ "/"+ campaignId +"/details",
      },function (component,response) {
        component.receiveCampaignDetails(response.data.response);
      },true,null,
      function (component) {
        component.campaignNotFound();
      }
    );
  },
  findCampaignDetailsToEdit(component, campaignId){
    connector.send(component,
      {
        method: 'get',
        url: CAMPAIGN_URI+ "/"+ campaignId,
      },function (component,response) {
        component.receiveCampaignToEdit(response.data.response);
      },true
    );
  },
  updateStatus(component, campaignId, newStatus){
    connector.send(component,
      {
        method: 'post',
        url: CAMPAIGN_URI+ "/"+ campaignId + "/status",
        data: {status: newStatus}
      },function (component,response) {
        component.receiveUpdateStatusResponse(response.data.response);
      },false
    );
  },
  sendTestCampaign (component,data) {
    connector.sendWithFile(component,CAMPAIGN_URI+ "/test", data, 'POST',
      function (component,response) {

        EventBus.$emit('flash-message', {
          text: response.data.response,
          color: 'info',
          textColor: 'white',
          outlined: false
        });
      }
    );
  },
 };
export default campaignConnector
