<template>
  <line-chart
    v-if="loaded"
    :labels="labels"
    :data="data"
    title="Contacts"
  />

</template>


<script>

  import lineChart from '../base/lineChart'
  import contactGrowthReportConnector from '../../connectors/reports/contactGrowthReportConnector'

  export default {
    name: "contact-chart",
    props: {
      listId: {
        type: Number
      },
    },
    components: {
      lineChart
    },
    data () {
      return {
        loaded:false,
        data: [],
        labels: []
      }
    },
    mounted () {
      if(this.listId){
        contactGrowthReportConnector.findItems(this,this.receiveReport,{list_id: this.listId})
      }
    },
    methods: {
      receiveReport(result) {
        this.data = result.data
        this.labels = result.labels
        this.loaded = true
      }
    }
  }
</script>

<style scoped>

</style>