<template>
  <secure-content :remove-padding="true">
    <create-automated-campaign-form/>
  </secure-content>
</template>

<script>

  import createAutomatedCampaignForm from './create-automated-campaign-form'
  import secureContent from "../secure-content";

  export default {
    name: "create-automated-campaign-viewer",
    components: {
      createAutomatedCampaignForm,
      secureContent
    },
  }
</script>

<style>

</style>