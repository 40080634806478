<template>
  <secure-content :show-header="false" :show-back="false" :remove-padding="true" :check-for-user-logged="checkForUserLogged">
    <v-main style="height: 100vh;">
      <v-row class="pa-0 mr-0">
        <v-col cols="4" class="pa-0 mr-0">
          <v-card tile
                  color="background"
                  max-height='102vh'
                  min-height='102vh'
                  class="disable-events pa-0 mr-0 square"
          >
            <br>
            <v-card-title class="pt-16 pb-16">
              <p class="text-h3 pt-16 pl-16 secondary--text">{{$vuetify.lang.t('$vuetify.profile.welcome')}}</p>
              <p class="text-h3 pl-16 ml-16 secondary--text">{{$vuetify.lang.t('$vuetify.profile.shopsend')}}</p>
            </v-card-title>

            <v-row class="pl-0 ml-n16">
              <img :src="squareLogo" alt="logo" width="330px" height="512px">
            </v-row>

          </v-card>
        </v-col >
        <v-col cols="8" class="pa-0 mr-0">
          <v-card tile
                  class="pa-0 mr-0 square"
                  height='102vh'>

            <v-card-title>
              <v-row>
                <v-col offset="1" cols="10">
                  <v-stepper flat class="pt-4" alt-labels>
                    <v-stepper-header style="box-shadow: 0 0 0 0">
                      <template v-for="(status,idx) in onBoardingProcessStatuses">
                        <v-stepper-step
                            step=""
                            :complete="status.complete"
                            :complete-icon='status.icon'
                        >
                          <p class="text-subtitle-2 text--background text-start mb-0">{{ status.title }}</p>
                        </v-stepper-step>

                        <v-divider
                            class="divider-border"
                            v-if="status.title !== 'Compliance'"
                        ></v-divider>
                      </template>
                    </v-stepper-header>
                  </v-stepper>
                </v-col>
              </v-row>

            </v-card-title>

            <setup-company v-if="setupPage === 'E-Comm'"/>
            <profile :show-welcome="true" v-else-if="setupPage === 'Profile'" :company-hash-id.sync="companyHashId"/>
            <company :show-welcome="true" v-else-if="setupPage === 'Company'" :company-hash-id.sync="companyHashId"/>
            <email-service :show-welcome="true" v-else-if="setupPage === 'Email'" :company-hash-id.sync="companyHashId"/>
            <compliance :show-welcome="true" v-else-if="setupPage === 'Compliance'" :company-hash-id.sync="companyHashId"/>


          </v-card>
        </v-col>


      </v-row>
    </v-main>
  </secure-content>

</template>

<script>

  import profile from "../user/profile"
  import setupCompany from "./setup-company"
  import logo from "../../images/shopsendiconbeige.svg"
  import companyConnector from "../../connectors/companyConnector";
  import secureContent from "../secure-content";
  import Company from "./company";
  import EmailService from "./email-service";
  import Compliance from "./compliance";

  export default {
    name: "onBoardNewCustomer",
    components: {
      Compliance,
      EmailService,
      Company,
      profile,
      setupCompany,
      secureContent,
    },
    data () {
      return {
        squareLogo: logo,
        onBoardingProcessStatuses: [
            {title: 'E-Comm', complete: true, icon:''},
            {title: 'Profile', complete: false, icon:''},
            {title: 'Company', complete: false, icon:''},
            {title: 'Email', complete: false, icon:''},
            {title: 'Compliance', complete: false, icon:''},
          ],
        statusIndex: 0,
        setupPage: '',
        checkForUserLogged: false,
        companyHashId: null
      }
    },
    methods: {
      handleFail(error){
        console.log(error)
      }
    },
    async mounted() {
      if(this.$route.params.companyId){
        this.companyHashId = this.$route.params.companyId
        this.statusIndex = await companyConnector.findCompanyOnBoardingStatus(this, this.companyHashId)
        for(let i = 0; i<= this.statusIndex;i++){
          if(i!==5){
            this.onBoardingProcessStatuses[i].complete = true
          }
          if(i > 0){
            this.onBoardingProcessStatuses[i-1].icon ='$complete'
          }

        }
        if( this.statusIndex >= this.onBoardingProcessStatuses.length){
          this.$router.push("/new-popup")
        }else{
          this.setupPage = this.onBoardingProcessStatuses[this.statusIndex].title
        }
        if(this.statusIndex >= 2){
          this.checkForUserLogged = true
        }else{
          this.checkForUserLogged = false
        }
        console.log(this.statusIndex)
        console.log(this.checkForUserLogged)

      }else {
        this.setupPage = 'E-Comm'
      }
    },
  }
</script>

<style scoped>
  .disable-events {
    pointer-events: none;
  }
  .square {
    border-radius: 0px !important;
  }

  .container {
    padding: 0px !important;
  }

  .divider-border {
    border-width: medium;
    border-style: dashed none none;
  }

</style>