<template>
  <bar-chart
    v-if="loaded"
    :chart-data="chartData"
  />

</template>

<script>

  import barChart from '../base/barChart'

  export default {
    name: "dashboard-chart",
    props: {
      dataCollection: {
        type: Array
      },
      labels:{
        type: Array
      }
    },
    components: {
      barChart
    },
    data () {
      return {
        loaded:false,
        chartData: null,
        datasets: [
          {
            borderColor: [
              'rgb(53,125,237, 0)'
            ],
            borderWidth: 0,
          },
          {
             borderColor: [
              'rgb(153,25,37, 0)'
            ],
            borderWidth: 0,
          },
          {

            borderColor: [
              'rgb(153,25,37, 0)'
            ],
            borderWidth: 0,
          },
          {
            borderColor: [
              'rgb(153,25,37, 0)'
            ],
            borderWidth: 0,
          }
        ]
      }
    },
    mounted () {
      this.loaded = false
      this.prepareDataToShow()

      this.loaded = true
    },
    methods: {
      prepareDataToShow(){
        this.datasets[0].label = this.dataCollection[0].label
        this.datasets[0].data = this.dataCollection[0].data
        this.datasets[0].backgroundColor = Array(this.labels.length).fill( 'rgb(92,122,255)')
        this.datasets[1].label = this.dataCollection[1].label
        this.datasets[1].data = this.dataCollection[1].data
        this.datasets[1].backgroundColor = Array(this.labels.length).fill(  'rgb(133,155,255)')
        this.datasets[2].label = this.dataCollection[2].label
        this.datasets[2].data = this.dataCollection[2].data
        this.datasets[2].backgroundColor = Array(this.labels.length).fill( 'rgb(173,188,255)')
        this.datasets[3].label = this.dataCollection[3].label
        this.datasets[3].data = this.dataCollection[3].data
        this.datasets[3].backgroundColor = Array(this.labels.length).fill( 'rgb(187,202,221,0.5)')

        this.chartData = {
          labels: this.labels,
          datasets: this.datasets
        }
      }
    },
    watch: {
      dataCollection: function() {
        this.prepareDataToShow()
      }
    }
  }
</script>

<style scoped>

</style>