import connector from '../connector'

const CONTACT_REPORT_SUMMARY_URI = '/report/contact/summary';

const contactReportSummaryConnector = {

  // this method must have this estructure in order to works with the summary component
  findItems (component,callback, params=null) {
    let queryString = ""
    if(params && Object.keys(params).length !== 0) {
      queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }

    connector.send(component,
    {
      method: 'get',
      url: CONTACT_REPORT_SUMMARY_URI + queryString,
    },function (component,response) {

      component.$store.commit('setActiveContacts', response.data.response['Active']);
      callback(response.data.response);
    });
  }
 };
export default contactReportSummaryConnector
