<template>

  <v-menu bottom left offset-y max-width="300px" min-width="300px"
          :close-on-content-click="false" ref="reportPicker">
    <template v-slot:activator="{ on }">
      <v-btn
          color="primary"
          elevation="0"
          text
          v-on="on"
      >
        <span class="primary--text text-subtitle-2">Generate a CSV report</span>
      </v-btn>
    </template>

    <v-list class="pb-0">

      <v-list-item-content class="pb-0">
        <v-list-item-subtitle>
          <span class="text-subtitle-2 text-left pt-0 pb-0 pl-4">
            Select a date range
          </span>
          <v-select
              v-model="interval"
              :items="intervalOptions"
              class="px-4"
              @change="updateDatesBySelector()">
          </v-select>
          <v-text-field
              class="px-4 py-0"
              v-model="dateRangeText"
              prepend-icon="mdi-calendar"
              readonly
              :error-messages="errors"
          ></v-text-field>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-date-picker
              color="primary"
              no-title
              v-model="dates"
              range
              reactive
              :min="getStartDate"
              :max="getEndDate"
              @change="dateChanged()"
          ></v-date-picker>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item>
        <v-list-item-content  class="pt-0">
          <v-btn color="primary" rounded @click="getReport()" :loading="loading">
            <span class="pl-2 accent--text text-subtitle-2"> Generate csv report </span>
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

import onDemandReportConnector from '../../connectors/reports/onDemandReportConnector'

export default {
  name: "report-picker",
  props: {
    reportType: {
      type: String,
      default: 'campaign-standard' //campaign-standard, campaign-automation, popup
    },
  },
  data: () => ({
    dates: [],
    errors: [],
    loading: false,
    today: new Date(),
    interval: "Last 7 Days",
    intervalOptions: ['Last 7 Days', 'Last 30 Days', 'Last 90 Days','Custom']
  }),
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    getEndDate() {
      return this.today.toISOString().slice(0,10)
    },
    getStartDate() {
      let date = new Date()
      date.setDate(this.today.getDate() - 364)
      return date.toISOString().slice(0,10)
    }
  },
  mounted() {
    this.dates= this.initDates(6)
  },
  methods: {
    initDates(days) {
      let myCurrentDate=new Date();
      let myPastDate=new Date(myCurrentDate);
      myPastDate.setDate(myPastDate.getDate() - days);
      return [myPastDate.toISOString().slice(0,10), myCurrentDate.toISOString().slice(0,10)]
    },
    updateDatesBySelector() {
      if(this.interval === 'Last 7 Days'){
        this.dates = this.initDates(6)
      }else if(this.interval === 'Last 30 Days'){
        this.dates = this.initDates(29)
      }else if(this.interval === 'Last 90 Days'){
        this.dates = this.initDates(89)
      }
    },
    dateChanged(){
      this.interval = "Custom"
    },
    async getReport() {

      if(this.dates.length == 2){
        this.errors = []
        this.loading = true
        if(this.dates[0] !== this.dates[1]){
          let result = await onDemandReportConnector.getCSV(this,{
            start_date: this.dates[0],
            end_date: this.dates[1],
            report_type: this.reportType
          })

          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', result.filename); // setted by the header, see the connector
          document.body.appendChild(link);
          link.click();
          this.loading=false
          if(this.$refs.reportPicker) {
            this.$refs.reportPicker.save();
          }
        }else {
          this.errors = ["You can't use the same day"]
          this.loading=false
        }

      }else {
        this.errors = ["You must select a valid date range"]
        this.loading=false
      }


    },
  }
}
</script>

<style scoped>

</style>