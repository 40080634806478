<template>
  <ValidationObserver ref="vChange" v-slot="{ validate, reset }">

    <div class="pl-16" >
      <p v-if="showWelcome" class="text-subtitle-1 font-weight-bold pl-4 text--background text-start mb-0">{{$vuetify.lang.t('$vuetify.profile.subtitle')}}</p>
    </div>

    <v-form  @keyup.native.enter="keyPress($event)" type="" class="pl-16 pr-16" autocomplete="on">

      <v-card-text class="text-subtitle-1 text--background">

        <p class="text-subtitle-1 text--background text-start mb-0">{{$vuetify.lang.t('$vuetify.profile.fullname')}}</p>
        <v-row>
          <v-col>
            <ValidationProvider v-slot="{ errors }" name="name" rules="required|max:25">
              <v-text-field name="name" outlined autocomplete="on"
                            id="name" type="text" v-model="user.name"
                            placeholder="First"
                            dense
                            :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>

          <v-col>
            <ValidationProvider v-slot="{ errors }" name="last name" rules="required|max:25">
              <v-text-field name="lastName" outlined autocomplete="on"
                            id="lastName" type="text" v-model="user.lastName"
                            placeholder="Last"
                            dense
                            :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>

        </v-row>

        <p class="text-subtitle-1 text--background text-start mb-0">{{$vuetify.lang.t('$vuetify.profile.phone')}}</p>
        <v-row>
          <v-col cols="7">
            <ValidationProvider v-slot="{ errors }" name="phone" vid="phone" rules="required|intPhone">
              <vue-tel-input-vuetify outlined dense
                                     autocomplete="on" label=""
                                     placeholder="+1 201-555-0123"
                                     selectLabel="country"
                                     inputId="phone"
                                     name="phone"
                                     mode="international"
                                     defaultCountry="US"
                                     :error-messages="errors"
                                     v-model="user.phone"/>
            </ValidationProvider>
          </v-col>
        </v-row>

        <p v-if='askForEmail' class="text-subtitle-1 text--background text-start mb-0">{{$vuetify.lang.t('$vuetify.profile.email')}}</p>
        <v-row v-if='askForEmail'>
          <v-col cols="7">
            <ValidationProvider v-slot="{ errors }" name="email" vid="email" rules="required|email|businessEmail">
              <v-text-field name="email" outlined autocomplete="on"
                            id="email" v-model="user.email"
                            :error-messages="errors" dense
                            placeholder="billing@brand.com"
                            type="text"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>


        <p class="text-subtitle-1 font-weight-bold text--background text-start">{{$vuetify.lang.t('$vuetify.profile.passwordSubtitle')}}</p>

        <p class="text-subtitle-1 text--background text-start mb-0">{{$vuetify.lang.t('$vuetify.profile.newPassword')}}</p>
        <v-row>
          <v-col cols="7">
            <ValidationProvider v-slot="{ errors }" name="new password" vid="newPassword" rules="required|min:8|upCase|number|specialChar">
              <v-text-field name="newPassword" outlined autocomplete="on"
                            id="newPassword" v-model="user.newPassword"
                            :error-messages="errors" dense
                            :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showNew = !showNew"
                            :type="showNew ? 'text' : 'password'"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <p class="text-subtitle-1 text--background text-start mb-0">{{$vuetify.lang.t('$vuetify.profile.newPasswordConfirmation')}}</p>

        <v-row>
          <v-col cols="7">
            <ValidationProvider v-slot="{ errors }" name="new password confirmation" rules="required|confirmed:newPassword">
              <v-text-field name="newPasswordConfirmation" outlined autocomplete="on"
                            id="newPasswordConfirmation"v-model="user.newPasswordConfirmation"
                            :error-messages="errors" data-vv-as="password" dense
                            :append-icon="showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showConfirmation = !showConfirmation"
                            :type="showConfirmation ? 'text' : 'password'">
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row class="mt-0" v-if="!termsAndPolicyAreAccepted()">
          <v-col cols="7" class="pt-0">
            <ValidationProvider v-slot="{ errors }" name="terms" :rules="{ requiredTerms: { allowFalse: false } }">
              <v-checkbox v-model="user.terms_and_policy" class="px-6 caption" align="center" :error-messages="errors">
                <template v-slot:label>
                  <div>
                    By clicking “Create Account”, you agree to ShopSend’s
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                                target="_blank"
                                href="https://www.shopsend.io/terms"
                                @click.stop
                                v-on="on"
                        >
                          Terms of use
                        </a>
                      </template>
                      Opens in new window
                    </v-tooltip>
                    and
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                                target="_blank"
                                href="https://www.shopsend.io/privacy"
                                @click.stop
                                v-on="on"
                        >
                          Privacy Policy.</a>
                      </template>
                      Opens in new window
                    </v-tooltip>
                    By adding your phone number, you agree to receive recurring automated messages from Shopsend at the
                    phone number and email address provided. Msg & data rates may apply.
                  </div>
                </template>
              </v-checkbox>
            </ValidationProvider>

          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions>
        <v-btn right color="primary" @click="sendData()"  :disabled='buttonDisable'>
          <div class="pa-16">
            {{$vuetify.lang.t('$vuetify.profile.finishButton')}}
          </div>
        </v-btn>

      </v-card-actions>

    </v-form>
  </ValidationObserver>

</template>

<script>


  import userConnector from "../../connectors/userConnector";
  import {EventBus} from "../../packs/app";
  import router from "../../router";
  import auth from "../../models/auth";

  export default {
    name: "profile",
    components: {
    },
    props: {
      companyHashId: {
        type: String,
        default: null
      },
      showWelcome: false
    },
    data () {
      return {
        user:{
          name: '',
          phone: '',
          email: '',
          lastName: '',
          newPassword: '',
          newPasswordConfirmation: '',
          terms_and_policy: false
        },
        showNew: false,
        showConfirmation: false,
        askForEmail: this.$route.path.includes('/on-board/new-costumer'),
        buttonDisable: false
      }
    },
    methods: {
      async sendData(){
        let result= await this.$refs.vChange.validate()
        if (result) {
          this.buttonDisable = true
          let userData = this.prepareUser(this.user)
          if(this.$route.path.includes('/on-board/new-costumer')){
            let result = await userConnector.createUser(this,userData,this.companyHashId)
            this.runLogin(result.user)
            this.cleanData()
          }else {
            await this.updateUser(userData)
          }
        }
      },
      prepareUser(user){
        let userData= {}
        if(user.name){
          userData.name = user.name
        }
        if(user.lastName){
          userData.last_name = user.lastName
        }
        if(user.terms_and_policy){
          userData.terms_and_policy = user.terms_and_policy
        }
        if(user.newPassword){
          userData.new_password = user.newPassword
          userData.password_confirmation = user.newPasswordConfirmation
        }
        if(user.phone){
          userData.phone = user.phone.replaceAll('-','').replaceAll(' ','')
              .replaceAll('(','').replaceAll(')','')

        }
        if(user.email){
          userData.email = user.email
        }
        return userData
      },
      async updateUser(userData) {
        let response = await userConnector.updateUser(this, {userData})
        let user = response.user
        if (user) {
          //redirect
          if(this.$store.getters.abilities.rol === 'staff'){
            this.runLogin(user)
          } else {
            this.$store.commit('setUser', user);
            if(response.setup_store){
              EventBus.$emit('setupPage', 'company');
            }else {
              router.push('/dashboard')
              EventBus.$emit('flash-message', {
                text: this.$vuetify.lang.t('$vuetify.profile.userUpdated'),
                color: 'info',
                textColor: 'white',
                html: false,
                outlined: false
              });
            }
          }

          this.cleanData()

        }
      },
      cleanData(){
        this.user = {
          name: '',
          lastName: '',
          newPassword: null,
          newPasswordConfirmation: null,
          phone: '',
          email: '',
        }
        this.$refs.vChange.reset()
        this.buttonDisable = false
      },
      runLogin(user){
        auth.login(this, {
          email: user.email,
          password: this.user.newPassword
        })
      },
      keyPress (event) {
        event.preventDefault()
        event.stopPropagation()
        this.sendData()
      },
      termsAndPolicyAreAccepted() {
        return this.$store.getters.user.terms_and_policy
      },
    },
    mounted() {
    }
  }
</script>

<style scoped>

</style>