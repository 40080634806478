<template>
  <img :src="dsPopup">
</template>

<script>
  import dsPopup from '../../images/icons/ds-popup.svg'

  export default {
    name: "ds-popup",
    components: {
      dsPopup
    },
    data () {
      return {
        dsPopup: dsPopup,

      }
    }
  }
</script>

<style scoped>

</style>