<template>
  <img :src="dsInfo">
</template>

<script>
  import dsInfo from '../../images/icons/ds-info-white-trans.svg'

  export default {
    name: "ds-info-white-trans",
    components: {
      dsInfo
    },
    data () {
      return {
        dsInfo: dsInfo

      }
    }
  }
</script>

<style scoped>

</style>