
const utils = {
  apiUrl () {
    return this.baseUrl() + 'api/v1'
  },
  publicUrl () {
    return this.baseUrl() + 'api/public'
  },
  baseUrl () {
    let url = window.location.protocol + '//' + window.location.hostname;
    if (window.location.port !== '') {
      url = url + ':' + window.location.port + '/';
    } else {
      url = url + '/';
    }
    return url;
  }
};
export default utils;
