<template>
  <ValidationObserver ref="vChange" v-slot="{ validate, reset }">
    <v-form  @keyup.native.enter="keyPress($event)" type="" class="pl-16 pr-16" autocomplete="on">
      <div class="">
        <p class="text-subtitle-1 pl-4 text--background text-start" v-html="$vuetify.lang.t('$vuetify.compliance.welcome')"></p>
      </div>
      <div >
        <p class="text-caption pl-4 text--background text-start" v-html="$vuetify.lang.t('$vuetify.compliance.subtext')"></p>
      </div>

      <v-card-text class="text-subtitle-1 text--background">

        <v-row>
          <v-col cols="12" class="pt-2">
            <code-box
                :title="$vuetify.lang.t('$vuetify.compliance.terms')"
                :code="terms"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pt-2">
            <code-box
                :title="$vuetify.lang.t('$vuetify.compliance.privacy')"
                :code="privacy"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pt-2">
            <code-box
                :title="$vuetify.lang.t('$vuetify.compliance.checkout')"
                :code="checkout"
                rows=4
            />
          </v-col>
        </v-row>


        <v-row class="mt-0">
          <v-col cols="7" class="pt-0">
            <ValidationProvider v-slot="{ errors }" name="terms" :rules="{ requiredCompliance: { allowFalse: false } }">
              <v-checkbox v-model="terms_and_policy" class="px-6 text-caption" :error-messages="errors">
                <template v-slot:label>
                  <div>
                    By checking this box and clicking “Finish Setup”, you are agreeing that you have completed the above steps to ensure full TCPA compliance and have had everything reviewed by your legal council.
                  </div>
                </template>
              </v-checkbox>
            </ValidationProvider>

          </v-col>
        </v-row>


      </v-card-text>

      <v-card-actions>
        <v-btn right color="primary" @click="sendData()"  :disabled='buttonDisable'>
          <div class="pa-16">
            {{$vuetify.lang.t('$vuetify.compliance.finish')}}
          </div>
        </v-btn>

      </v-card-actions>
    </v-form>
  </ValidationObserver>


</template>

<script>


  import CompanyConnector from "../../connectors/companyConnector"
  import codeBox from "../base/codeBox";
  import documentConnector from "../../connectors/documentConnector";

  export default {
    name: "compliance",
    components: {
      codeBox
    },
    props: {
      companyHashId: {
        type: String,
        default: null
      },
    },
    data () {
      return {
        terms:"",
        checkout: "",
        privacy: "",
        terms_and_policy: false,
        onBoardingStep: 5,
        buttonDisable: false
      }
    },
    methods: {
      async sendData(){
        if(this.$refs.vChange){
          let result= await this.$refs.vChange.validate()
          if (result) {
            this.buttonDisable = true
            await CompanyConnector.updateCompany(this, {
              onBoardingStep: this.onBoardingStep
            }) //returns company
            this.$router.go()
            this.cleanData()
          }
        }
      },
      cleanData(){
        this.$refs.vChange.reset()
        this.buttonDisable = false
      },

      keyPress (event) {
        event.preventDefault()
        event.stopPropagation()
        this.updateUser()
      }
    },
    async mounted() {
      let response = await documentConnector.findCompliance(this)
      this.terms = response.terms
      this.privacy = response.privacy
      this.checkout = response.checkout
    }
  }
</script>

<style scoped>

</style>