<template>
  <ValidationObserver ref="vChange" v-slot="{ validate, reset }">

    <div class="pl-16" >
      <p v-if="showWelcome" class="text-subtitle-1 font-weight-bold pl-4 text--background text-start mb-0">{{$vuetify.lang.t('$vuetify.company.tellUs')}}</p>
    </div>

    <v-form  @keyup.native.enter="keyPress($event)" type="" class="pl-16 pr-16" autocomplete="on">

      <v-card-text class="text-subtitle-1 text--background">

        <p class="text-subtitle-1 text--background text-start mb-0">{{$vuetify.lang.t('$vuetify.company.name')}}</p>
        <v-row>
          <v-col cols="6">
            <ValidationProvider v-slot="{ errors }" name="name" rules="required|max:25">
              <v-text-field name="name" outlined autocomplete="on"
                            id="name" type="text" v-model="company.name"
                            placeholder="Cool Brand"
                            dense
                            :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>

        </v-row>

        <p class="text-subtitle-1 text--background text-start mb-0">{{$vuetify.lang.t('$vuetify.company.website')}}</p>
        <v-row>
          <v-col cols="6">
            <ValidationProvider v-slot="{ errors }" name="webDomain" vid="webDomain" rules="required">
              <v-text-field name="webDomain" outlined autocomplete="on" prefix="http(s)://"
                            id="webDomain" type="text" v-model="company.webDomain"
                            placeholder="https://www.coolbrand.com"
                            dense
                            :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>


      </v-card-text>
      <v-card-actions>
        <v-btn right color="primary" @click="sendData()"  :disabled='buttonDisable'>
          <div class="pa-16">
            {{$vuetify.lang.t('$vuetify.company.continueButton')}}
          </div>
        </v-btn>

      </v-card-actions>

    </v-form>
  </ValidationObserver>

</template>

<script>

  import companyConnector from "../../connectors/companyConnector";
  import CompanyConnector from "../../connectors/companyConnector";

  export default {
    name: "company",
    components: {
    },
    props: {
      companyHashId: {
        type: String,
        default: null
      },
      showWelcome: false
    },
    data () {
      return {
        company:{
          name: '',
          webDomain: '',
          onBoardingStep: 3
        },
        buttonDisable: false
      }
    },
    methods: {
      async sendData(){
        let result= await this.$refs.vChange.validate()
        if (result) {
          this.buttonDisable = true
          if(this.$route.path.includes('/on-board/new-costumer')){
            await CompanyConnector.updateCompany(this, this.company) //returns company
            this.$router.go()
            this.cleanData()
          }else {
           // put here code for edit company inside app
          }
        }
      },
      cleanData(){
        this.company = {
          name: '',
          webDomain: '',
        }
        this.$refs.vChange.reset()
        this.buttonDisable = false
      },
      keyPress (event) {
        event.preventDefault()
        event.stopPropagation()
        this.sendData()
      },
      receiveCompany(company){
        this.$store.commit('setCompany', company)
        this.company.name = company.name
        this.company.webDomain = company.web_domain
      },
      handleFail(error){

      }
    },
    mounted() {
      companyConnector.findCompany(this);
    }
  }
</script>

<style scoped>

</style>