<template>
  <img :src="dsCustomer">
</template>

<script>
  import dsCustomer from '../../images/icons/ds-customer.svg'

  export default {
    name: "ds-customer",
    components: {
      dsCustomer
    },
    data () {
      return {
        dsCustomer: dsCustomer

      }
    }
  }
</script>

<style scoped>

</style>