<template>
  <div>
    <component v-bind:is="transformed" v-bind="$props" />
  </div>

</template>

<script>
  export default {
    name: "dynamic-link",
    props: {
      textToShow: {
        type: String|Number,
        default: ''
      },
      link: {
        type: String,
        default: null
      },
      item: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        text: `
      <div v-if="link">
        <router-link :to="prepareLink()">
            {{textToShow}}
        </router-link>
      </div>
      <div v-else>
        {{ textToShow }}
      </div>

      `
      }
    },
    methods: {
      prepareLink (){
        let key = this.link.split('%')[1]
        return this.link.replace('%'+key, this.item[key])
      },
    },
    computed: {
      transformed () {
        return { template: this.text,  props: this.$options.props, methods: this.$options.methods }
      }
    }

  }
</script>

<style scoped>

</style>