import connector from './connector'

const POPUP_URI = '/popup';

const popupConnector = {
  updateStatus(component, popupId, newStatus){
    return new Promise((resolve, reject) => {
      connector.send(component,
        {
          method: 'post',
          url: POPUP_URI+ "/"+ popupId + "/status",
          data: {status: newStatus}
        },function (component,response) {
          resolve(response.data.response)
        },false,
        null,
        function (component, error) {
          resolve(false)
        }
      )
    })
  },
  getBeeKeys (component) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'post',
            url: POPUP_URI + '/editor_keys',
            data: {}
          },function (component,response) {
            resolve(response.data.response);
          },
          false,
          null,
          function (component, error) {
            component.handleFail(error)
          }
        );
      }
    );
  },
  autoSave (component, data) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'post',
            url: POPUP_URI + '/auto_save',
            data: data
          },function (component,response) {
            resolve(response.data.response);
          },
          false,
          null,
          function (component, error) {
            component.handleFail(error)
          }
        );
      }
    );
  },
  createTemplate (component, popupBaseId) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'post',
            url: POPUP_URI + '/new',
            data: { popupBaseId: popupBaseId }
          },function (component,response) {
            resolve(response.data.response);
          },
          false,
          null,
          function (component, error) {
            component.handleFail(error)
            component.back()
          }
        );
      }
    );
  },
  editTemplate (component, popupId) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'get',
            url: POPUP_URI + '/'+ popupId
          },function (component,response) {
            resolve(response.data.response);
          },
          false,
          null,
          function (component, error) {
            component.handleFail(error)
            component.back()
          }
        );
      }
    );
  },
  archivePopup (component, popupId) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'delete',
            url: POPUP_URI + '/' + popupId
          },function (component,response) {
            resolve(response.data.response);
          },
          false,
          null,
          null
        );
      }
    );
  },
  getBaseTemplates (component) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'get',
            url: POPUP_URI + '/base_templates'
          },function (component,response) {
            resolve(response.data.response);
          },
          false,
          null,
          function (component, error) {
            component.handleFail(error)
          }
        );
      }
    );
  },
  saveDisplayOptions (component,data) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'post',
            url: POPUP_URI + '/display_options',
            data: data
          },function (component,response) {
            resolve(response.data.response);
          },
          false,
          null,
          null
        );
      }
    );
  },
  publishPopup (component,popup) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'post',
            url: POPUP_URI,
            data: popup
          },function (component,response) {
            resolve(response.data.response);
          },
          false,
          null,
          function (component, error) {
            component.handleFail(error)
          }
          );
      }
    );
  },

 };
export default popupConnector
