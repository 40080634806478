import connector from './connector'
import {EventBus} from "../packs/app";

const POPUP_URI = '/popup';
const POPUP_STATUS_URI = POPUP_URI+'/status';

const growthToolsConnector = {

  // this method must have this structure in order to works with the paginatedTable component
  findItems (component,query,callback) {
    connector.send(component,
    {
      method: 'post',
      url: POPUP_URI + '/list',
      data: query
    },function (component,response) {
      callback(response.data.response);
    });
  },

  //actions


 };
export default growthToolsConnector
