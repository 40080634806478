import Vue from 'vue'
import Router from 'vue-router'
import login from '../components/home/login.vue'
import loginWithToken from '../components/home/login-token.vue'
import dashboard from '../components/home/dashboard.vue'
import setupAccount from '../components/home/setup'
import infoPage from '../components/base/infoPage'
import contactList from '../components/contact/contact-list.vue'
import conversations from '../components/conversation/conversations'
import campaigns from '../components/campaign/campaigns.vue'
import createBroadcastCampaignViewer from '../components/campaign/create-broadcast-campaign-viewer.vue'
import createAutomatedCampaignViewer from '../components/campaign/create-automated-campaign-viewer.vue'
import campaignDetails from '../components/campaign/campaign-details'
import changePassword from '../components/user/change-password'
import popupEditor from '../components/popup/popup-editor'
import newPopup from '../components/popup/new-popup'
import onBoardNewCustomer from '../components/onBoarding/on-board-new-customer'

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      component: login,
      props: {type: 'password'}
    },
    {
      path: '/login/magic/:token',
      name: 'loginWithToken',
      component: login,
      props: {type: 'token'}
    },
    {
      path: '/login/shopify/:token',
      name: 'loginWithShopify',
      component: login,
      props: {type: 'shopify'}
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: dashboard
    },
    {
      path: '/setup',
      name: 'setupAccount',
      component: setupAccount,
      props: {page: null}
    },
    {
      path: '/setup/:page',
      name: 'setupAccountPage',
      component: setupAccount
    },
    {
      path: '/unknownCustomer',
      name: 'unknownCustomer',
      component: infoPage
    },
    {
      path: '/contact-list',
      name: 'contactList',
      component: contactList
    },
    {
      path: '/campaigns',
      name: 'campaigns',
      component: campaigns
    },
    {
      path: '/conversations',
      name: 'conversations',
      component: conversations
    },
    {
      path: '/create-campaign/broadcast',
      name: 'create-campaign-broadcast',
      component: createBroadcastCampaignViewer
    },
    {
      path: '/create-campaign/automation',
      name: 'create-campaign-automation',
      component: createAutomatedCampaignViewer
    },
    {
      path: '/edit-campaign/:campaignId',
      name: 'edit-campaign',
      component: createBroadcastCampaignViewer
    },
    {
      path: '/campaign/:campaignId',
      name: 'campaignDetails',
      component: campaignDetails,
      props: {id: 'id'}
    },
    {
      path: '/change-password',
      name: 'changePassword',
      component: changePassword
    },
    {
      path: '/popup-editor/:action/:popupBaseId',
      name: 'popupEditor',
      component: popupEditor
    },
    {
      path: '/new-popup',
      name: 'newPopup',
      component: newPopup
    },
    {
      path: '/on-board/new-costumer',
      name: 'onBoardNewCostumer',
      component: onBoardNewCustomer
    },
    {
      path: '/on-board/new-costumer/:companyId',
      name: 'onBoardNewCostumerWithCompany',
      component: onBoardNewCustomer
    }
  ]
})
