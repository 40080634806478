var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"vChange",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('div',{staticClass:"pl-16"},[(_vm.showWelcome)?_c('p',{staticClass:"text-subtitle-1 font-weight-bold pl-4 text--background text-start mb-0"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.profile.subtitle')))]):_vm._e()]),_vm._v(" "),_c('v-form',{staticClass:"pl-16 pr-16",attrs:{"type":"","autocomplete":"on"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.keyPress($event)}}},[_c('v-card-text',{staticClass:"text-subtitle-1 text--background"},[_c('p',{staticClass:"text-subtitle-1 text--background text-start mb-0"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.profile.fullname')))]),_vm._v(" "),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"name","outlined":"","autocomplete":"on","id":"name","type":"text","placeholder":"First","dense":"","error-messages":errors},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"last name","rules":"required|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"lastName","outlined":"","autocomplete":"on","id":"lastName","type":"text","placeholder":"Last","dense":"","error-messages":errors},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('p',{staticClass:"text-subtitle-1 text--background text-start mb-0"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.profile.phone')))]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"phone","vid":"phone","rules":"required|intPhone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{attrs:{"outlined":"","dense":"","autocomplete":"on","label":"","placeholder":"+1 201-555-0123","selectLabel":"country","inputId":"phone","name":"phone","mode":"international","defaultCountry":"US","error-messages":errors},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})]}}],null,true)})],1)],1),_vm._v(" "),(_vm.askForEmail)?_c('p',{staticClass:"text-subtitle-1 text--background text-start mb-0"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.profile.email')))]):_vm._e(),_vm._v(" "),(_vm.askForEmail)?_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"email","vid":"email","rules":"required|email|businessEmail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"email","outlined":"","autocomplete":"on","id":"email","error-messages":errors,"dense":"","placeholder":"billing@brand.com","type":"text"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1)],1):_vm._e(),_vm._v(" "),_c('p',{staticClass:"text-subtitle-1 font-weight-bold text--background text-start"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.profile.passwordSubtitle')))]),_vm._v(" "),_c('p',{staticClass:"text-subtitle-1 text--background text-start mb-0"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.profile.newPassword')))]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"new password","vid":"newPassword","rules":"required|min:8|upCase|number|specialChar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"newPassword","outlined":"","autocomplete":"on","id":"newPassword","error-messages":errors,"dense":"","append-icon":_vm.showNew ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showNew ? 'text' : 'password'},on:{"click:append":function($event){_vm.showNew = !_vm.showNew}},model:{value:(_vm.user.newPassword),callback:function ($$v) {_vm.$set(_vm.user, "newPassword", $$v)},expression:"user.newPassword"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('p',{staticClass:"text-subtitle-1 text--background text-start mb-0"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.profile.newPasswordConfirmation')))]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"new password confirmation","rules":"required|confirmed:newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"newPasswordConfirmation","outlined":"","autocomplete":"on","id":"newPasswordConfirmation","error-messages":errors,"data-vv-as":"password","dense":"","append-icon":_vm.showConfirmation ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showConfirmation ? 'text' : 'password'},on:{"click:append":function($event){_vm.showConfirmation = !_vm.showConfirmation}},model:{value:(_vm.user.newPasswordConfirmation),callback:function ($$v) {_vm.$set(_vm.user, "newPasswordConfirmation", $$v)},expression:"user.newPasswordConfirmation"}})]}}],null,true)})],1)],1),_vm._v(" "),(!_vm.termsAndPolicyAreAccepted())?_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"terms","rules":{ requiredTerms: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"px-6 caption",attrs:{"align":"center","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("\n                  By clicking “Create Account”, you agree to ShopSend’s\n                  "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({attrs:{"target":"_blank","href":"https://www.shopsend.io/terms"},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v("\n                        Terms of use\n                      ")])]}}],null,true)},[_vm._v("\n                    Opens in new window\n                  ")]),_vm._v("\n                  and\n                  "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({attrs:{"target":"_blank","href":"https://www.shopsend.io/privacy"},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v("\n                        Privacy Policy.")])]}}],null,true)},[_vm._v("\n                    Opens in new window\n                  ")]),_vm._v("\n                  By adding your phone number, you agree to receive recurring automated messages from Shopsend at the\n                  phone number and email address provided. Msg & data rates may apply.\n                ")],1)]},proxy:true}],null,true),model:{value:(_vm.user.terms_and_policy),callback:function ($$v) {_vm.$set(_vm.user, "terms_and_policy", $$v)},expression:"user.terms_and_policy"}})]}}],null,true)})],1)],1):_vm._e()],1),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"right":"","color":"primary","disabled":_vm.buttonDisable},on:{"click":function($event){return _vm.sendData()}}},[_c('div',{staticClass:"pa-16"},[_vm._v("\n          "+_vm._s(_vm.$vuetify.lang.t('$vuetify.profile.finishButton'))+"\n        ")])])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }