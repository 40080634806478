import connector from './connector'

const USER_URI = '/user';

const userConnector = {

  changePassword (component, data) {
    connector.send(component,
    {
      method: 'put',
      data: data,
      url: USER_URI +'/password'
    },function (component,response) {
      component.changePasswordDone(response.data.response);
    });
  },
  updateUser (component, user) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'put',
            data: user,
            url: USER_URI
          },function (component,response) {
            resolve(response.data.response);
          },
          false,
          null,
          function (component, error) {
            console.log('fail')
            component.handleFail(error)
          });
      }
    );
  },

  createUser(component,user,companyId) {
    return new Promise(
      (resolve, reject) => {
        user.company_id = companyId
        connector.sendToPublicApi(component,
          {
            method: 'post',
            url: USER_URI,
            data: {userData: user},
          },function (component,response) {
            resolve(response.data.response);
          },
          false,
          null,
          function (component, error) {
            component.handleFail(error)// TODO show possible errors in fields.
          });
      }
    );
  },

 };
export default userConnector
