<template>
  <secure-content :show-back="false">
    <v-row class="px-3 pb-3">
      <v-toolbar flat color="#6D7895" class="fixed-bar">
        <v-spacer></v-spacer>
        <v-btn-toggle
            group
            v-model="templateIndex"
        >
          <v-btn outlined class="accent--text"
                 @click="changeTemplate('email')">{{$vuetify.lang.t('$vuetify.popupEditor.emailCollector') }}</v-btn>
          <v-btn outlined class="accent--text" @click="changeTemplate('phone')"> {{$vuetify.lang.t('$vuetify.popupEditor.phoneCollector') }} </v-btn>
          <v-btn outlined class="accent--text" @click="changeTemplate('success')"> {{$vuetify.lang.t('$vuetify.popupEditor.success') }} </v-btn>
          <v-btn outlined class="accent--text" @click="showSettings = true; showWelcomeFlow = false">{{$vuetify.lang.t('$vuetify.popupEditor.displaySettings') }} </v-btn>
          <v-btn outlined class="accent--text" @click="showWelcomeFlow = true; showSettings = false">{{$vuetify.lang.t('$vuetify.popupEditor.welcomeFlow') }} </v-btn>
        </v-btn-toggle>
        <v-spacer></v-spacer>
        <v-btn text class="accent--text" @click="beePlugin.preview()"> Preview </v-btn>
        <v-btn rounded color="success" id="publishBtn" class="secondary--text px-8 mx-4" :disabled="!canPublish" @click="startPublish()"> {{$vuetify.lang.t('$vuetify.popupEditor.publish') }} </v-btn>
      </v-toolbar>
    </v-row>
    <div v-if="showSettings" class="pt-16">
      <popup-display-settings :popup-id="this.popupId" :base-options="this.display_options"/>
    </div>
    <div v-else-if="showWelcomeFlow" class="pl-16 pt-16">
      <create-automated-campaign-form preselected-trigger="Welcome-Campaign-automation" :popup-id="this.popupId" :campaign-id-to-edit="campaignId"/>
    </div>
    <div class="pt-16" v-show="!showSettings && !showWelcomeFlow" id="bee-plugin-container" style="height: 93vh"></div>
  </secure-content>

</template>

<script>

import popupConnector from "../../connectors/popupConnector"
import popupDisplaySettings from './popup-display-settings'
import Bee from '@mailupinc/bee-plugin'
import secureContent from '../secure-content.vue'
import connector from "../../connectors/connector"
import router from "../../router"
import {EventBus} from "../../packs/app";
import createAutomatedCampaignForm from "../campaign/create-automated-campaign-form";

export default {
  name: "popup-editor",
  components: {
    secureContent,
    popupDisplaySettings,
    createAutomatedCampaignForm,
  },
  data () {
    return {
      companyName: this.$store.getters.company.name.replace(/\s/g, ''),
      beePlugin: null,
      popupId: null,
      campaignId: null,
      templateIndex: 0,
      templateSelected: 'email',
      templateBuffer: '',
      templatesForGetHtml: [],
      publishing: false,
      showSettings: false,
      showWelcomeFlow: false,
      canPublish: false,
      newChanges: false,
      editMode: false,
      htmlTemplates:{
        email: null,
        phone: null,
        success: null
      },
      templates:{},
      template_ids: {
        email: null,
        phone: null,
        success: null
      },
      display_options: {},
      forms: {
        email: {
          structure: {
            title: 'Shopsend Email collector',
            attributes:{
              action: connector.apiUrl() +"/hook/shopsend/email/"+this.$store.getters.company.id,
              method: "POST"
            },
            fields: {
              email: {
                type: 'email',
                label: 'Email',
                canBeRemovedFromLayout: false,
                removeFromLayout: false,
                attributes: {
                  required: true,
                  id: "shopsendEmail"
                }
              },
              submit: {
                type: 'submit',
                label: '',
                canBeRemovedFromLayout: false,
                removeFromLayout: false,
                attributes: {
                  value: 'Continue',
                  id: 'shopsendEmailSubmit'
                }
              },
            },
            layout: [
              ['email'],
              ['submit']
            ]
          }
        },
        phone: {
          structure: {
            title: 'Shopsend Phone collector',
            attributes:{
              action: connector.apiUrl() +"/hook/shopsend/"+this.$store.getters.company.id,
              method: "POST"
            },
            fields: {
              phone: {
                type: 'tel', label: 'Phone number',
                canBeRemovedFromLayout: false,
                removeFromLayout: false,
                attributes: {
                  required: true,
                  id: 'shopsendPhone'
                }
              },
              submit: {
                type: 'submit',
                label: '',
                canBeRemovedFromLayout: false,
                removeFromLayout: false,
                attributes: {
                  value: 'Send',
                  id: 'shopsendPhoneSubmit'
                }
              },
            },
            layout: [
              [ 'phone'],
              ['submit']
            ]
          }
        }
      },
      beeBaseConfig: {
        container: 'bee-plugin-container',
        // autosave: 30,
        language: 'en-US',
        saveRows: true,
        trackChanges: true,
        sidebarPosition: 'left',
        workspace: {
          popup: {
            contentWidth: 600,
            backgroundImage: "https://shopsendhost.s3.us-east-2.amazonaws.com/DotCanvas.png",
            layout: 'classic-center', //https://docs.beefree.io/setting-layout-and-size-popup-builder/
            customStyles: {
              container: {
                padding: '0px'
              },
              header: {
                display: 'none'
              },
            }
          }
        },
      }
    }
  },
  async mounted() {
    let action = this.$route.params.action
    if(this.$route.params.popupBaseId) {
      let result
      if(action ==='new') {
        result = await popupConnector.createTemplate(this,this.$route.params.popupBaseId)
        this.editMode = false
      }else if(action ==='edit'){
        result = await popupConnector.editTemplate(this,this.$route.params.popupBaseId)
        this.editMode = true
      }else {
        this.back()
        return
      }
      this.beePlugin = new Bee()
      this.templates = result.templates
      this.template_ids = result.template_ids
      this.popupId = result.id
      this.campaignId = result.campaign_id
      if(this.campaignId){
        this.canPublish = true
      }

      this.display_options = result.display_options

      let keys = await popupConnector.getBeeKeys(this)
      this.beePlugin.getToken(keys.client_id, keys.client_secret)
          .then(() => this.beePlugin.start(this.prepareBeeConfig(result.popup_type), this.templates[this.templateSelected]))
    }else {
      this.back()
    }

    EventBus.$on('enable-publish', (data) => {
      this.canPublish = true
    });

  },
  async beforeRouteLeave (to, from , next) {
    if(this.newChanges){
      if(this.canPublish){
        const choice = await this.$confirm(this.$vuetify.lang.t('$vuetify.popupEditor.exitConfirm.message'),
            {
              subtitle: this.$vuetify.lang.t('$vuetify.popupEditor.exitConfirm.subtitle'),
              buttonFalseText: this.$vuetify.lang.t('$vuetify.popupEditor.exitConfirm.exit'),
              buttonTrueText: this.$vuetify.lang.t('$vuetify.popupEditor.exitConfirm.publish'),
            })
        if (choice){
          this.startPublish()
          next(false)
        } else {
          next()
        }
      }else{
        const choice = await this.$confirm(this.$vuetify.lang.t('$vuetify.popupEditor.exitConfirmWithoutWelcome.message'),
            {
              subtitle: this.$vuetify.lang.t('$vuetify.popupEditor.exitConfirmWithoutWelcome.subtitle'),
              buttonFalseText: this.$vuetify.lang.t('$vuetify.popupEditor.exitConfirmWithoutWelcome.exit'),
              buttonTrueText: this.$vuetify.lang.t('$vuetify.popupEditor.exitConfirmWithoutWelcome.back'),
            })
        if (choice){
          next(false)
        } else {
          next()
        }
      }

    }else{
      next()
    }
  },
  methods: {
    changeTemplate(templateName) {
      this.showSettings = false
      this.showWelcomeFlow = false
      this.templateBuffer = templateName
      this.beePlugin.save()
    },
    back(){
      router.push('/new-popup')
    },
    changePage(){
      if( this.templateSelected !== this.templateBuffer){
        this.templateSelected = this.templateBuffer
        this.beePlugin.loadConfig({ defaultForm: undefined })
        this.beePlugin.loadConfig({ defaultForm: this.forms[this.templateSelected] })
        this.beePlugin.load(this.templates[this.templateSelected])
      }
    },
    startPublish() {
      // console.log('0- start publish', this.templateSelected )
      this.templateBuffer = this.templateSelected
      this.templatesForGetHtml.push(this.templateSelected)
      this.publishing = true
      // console.log(this.htmlTemplates)

      let templateList = ['email','phone','success']
      for(let i = 0; i < templateList.length; i++){
        if(this.htmlTemplates[templateList[i]] === null && this.templateSelected !== templateList[i]){
          // console.log('adding ',templateList[i])
          this.templatesForGetHtml.push(templateList[i])
        }
      }

      // console.log('1-', this.templatesForGetHtml)
      this.captureTemplates()

    },
    captureTemplates(){
      // console.log('2---',this.templatesForGetHtml)
      if(this.templatesForGetHtml.length > 0){
        let templateToSave = this.templatesForGetHtml.shift()
        if(templateToSave !== this.templateSelected){
          this.templateSelected = templateToSave
          this.beePlugin.load(this.templates[this.templateSelected])
        }else{
          this.beePlugin.save()
        }
      }else{
        this.finishPublish()
      }
    },

    async finishPublish() {
      const popupConfiguration = {
        active:true,
        popup_id: this.popupId,
        email_template: this.templates.email,
        phone_template: this.templates.phone,
        success_template: this.templates.success,
        email_html_code: this.htmlTemplates.email,
        phone_html_code: this.htmlTemplates.phone,
        success_html_code: this.htmlTemplates.success
      }

      let result = await popupConnector.publishPopup(this, popupConfiguration)
      this.publishing = false
      this.newChanges = false
      if(result){
        router.push('/contact-list')
        EventBus.$emit('flash-message', {
          text: "The Popup was published and activated successfully.",
          color: 'info',
          textColor: 'white',
          outlined: false
        });
      }
    },
    handleFail(error){
      this.publishing = false
    },
    prepareBeeConfig(popup_type) {
      let self = this
      let beeConfig = { ...this.beeBaseConfig }
      beeConfig['workspace']['popup']['contentWidth'] = popup_type === 'desktop' ? 600: 300
      beeConfig['defaultForm'] = this.forms[this.templateSelected]
      beeConfig['uid'] = this.companyName

      beeConfig['onSave'] = (jsonFile, htmlFile) => {
        self.templates[self.templateSelected] = JSON.parse(jsonFile)
        self.htmlTemplates[self.templateSelected] = htmlFile

        if (self.publishing) {
          self.captureTemplates()
        } else {
          self.changePage()
        }
      }
      beeConfig['onSaveAsTemplate'] = (jsonFile) => {
        console.log('onSaveAsTemplate', jsonFile)
      }
      beeConfig['onError'] = (errorMessage) => {
        console.log('onError ', errorMessage)
      }
      beeConfig['onAutoSave'] = function(jsonFile) {
        popupConnector.autoSave(self, {
          json_template: JSON.parse(jsonFile),
          template_id: self.template_ids[self.templateSelected],
          popup_id: self.popupId
        })
        self.templates[self.templateSelected] = JSON.parse(jsonFile)
        self.newChanges = true
      }
      beeConfig['onLoad'] = function(jsonFile) {
        if(self.publishing){
          self.beePlugin.save()
        }
      }
      beeConfig['onChange'] = function (jsonFile, response) {
        popupConnector.autoSave(self, {
          json_template: JSON.parse(jsonFile),
          template_id: self.template_ids[self.templateSelected],
          popup_id: self.popupId
        })
        self.templates[self.templateSelected] = JSON.parse(jsonFile)
        self.newChanges = true
      },
      beeConfig['onWarning'] = function(alertMessage) { console.log('onWarning ', alertMessage) } // [optional]
      return beeConfig
    }
  }
}
</script>

<style scoped>
  .fixed-bar {
    position: fixed;
    z-index: 2;
    width: 98%
  }

  #publishBtn.v-btn--disabled {
    background-color: #CACADF !important;
  }
</style>