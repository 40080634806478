import { render, staticRenderFns } from "./ds-billing.vue?vue&type=template&id=49919bc8&scoped=true&"
import script from "./ds-billing.vue?vue&type=script&lang=js&"
export * from "./ds-billing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49919bc8",
  null
  
)

export default component.exports