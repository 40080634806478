var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-list-item',{on:{"click":function($event){_vm.visibleForm = true}}},[_c('v-list-item-title',{staticClass:"background--text"},[_vm._v("\n      "+_vm._s(_vm.$vuetify.lang.t('$vuetify.conversations.editModes.optionName'))+"\n    ")])],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"80vw","height":"80vh","persistent":""},model:{value:(_vm.visibleForm),callback:function ($$v) {_vm.visibleForm=$$v},expression:"visibleForm"}},[_c('v-card',[_c('v-toolbar',{staticStyle:{"height":"32px"},attrs:{"flat":""}},[_c('v-spacer'),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('v-card-subtitle',{staticClass:"text-none text-h5 text-center py-0 px-16 background--text"},[_c('b',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.conversations.editModes.title')))])]),_vm._v(" "),_c('v-card-subtitle',{staticClass:"text-none text-body-2 text-center pt-0 px-16 background--text"},[_vm._v("\n        "+_vm._s(_vm.$vuetify.lang.t('$vuetify.conversations.editModes.subtitle'))+"\n      ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('v-card-text',[_c('ValidationObserver',{ref:"vModes",attrs:{"disabled":!_vm.visibleForm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('v-container',[_c('v-row',{staticClass:"px-16 mx-16"},[_c('v-col',[_c('p',{staticClass:"text-caption text-left mb-2"},[_c('v-badge',{staticClass:"mr-2",attrs:{"inline":"","left":"","dot":"","color":"success"}}),_vm._v(" "),_c('span',{staticClass:"text-body-2 text--background"},[_vm._v("\n                      "+_vm._s(_vm.$vuetify.lang.t('$vuetify.conversations.editModes.status'))+" |\n                    ")]),_vm._v(" "),_c('span',{staticClass:"text-body-2 font-weight-bold text--background"},[_vm._v("\n                      "+_vm._s(_vm.$vuetify.lang.t('$vuetify.conversations.editModes.active'))+"\n                    ")])],1),_vm._v(" "),_c('p',{staticClass:"text-caption text-left mb-2"},[_c('span',{staticClass:"text-body-2 text--background"},[_vm._v("\n                      "+_vm._s(_vm.$vuetify.lang.t('$vuetify.conversations.editModes.activeSub'))+"\n                  ")])]),_vm._v(" "),_c('v-text-field',{staticClass:"background--text",attrs:{"outlined":"","value":"No autoreplay","disabled":""}})],1)],1),_vm._v(" "),_c('v-row',{staticClass:"px-16 mx-16"},[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"inactive message","rules":{ required: true, min: 5, max: 160, not_url: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"text-caption text-left mb-2"},[_c('v-badge',{staticClass:"mr-2",attrs:{"inline":"","left":"","dot":"","color":"#FFED8D"}}),_vm._v(" "),_c('span',{staticClass:"text-body-2 text--background"},[_vm._v("\n                      "+_vm._s(_vm.$vuetify.lang.t('$vuetify.conversations.editModes.status'))+" |\n                    ")]),_vm._v(" "),_c('span',{staticClass:"text-body-2 font-weight-bold text--background"},[_vm._v("\n                      "+_vm._s(_vm.$vuetify.lang.t('$vuetify.conversations.editModes.inactive'))+"\n                    ")])],1),_vm._v(" "),_c('p',{staticClass:"text-caption text-left mb-2"},[_c('span',{staticClass:"text-body-2 text--background"},[_vm._v("\n                      "+_vm._s(_vm.$vuetify.lang.t('$vuetify.conversations.editModes.inactiveSub'))+"\n                  ")])]),_vm._v(" "),_c('v-textarea',{staticClass:"background--text",attrs:{"required":"","outlined":"","counter":"160","background-color":"white","rows":"3","placeholder":_vm.$vuetify.lang.t('$vuetify.conversations.editModes.placeholderInactive'),"error-messages":errors},model:{value:(_vm.inactiveMessage),callback:function ($$v) {_vm.inactiveMessage=$$v},expression:"inactiveMessage"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{staticClass:"px-16 mx-16"},[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"away message","rules":{ required: true, min: 5, max: 160, not_url: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"text-caption text-left mb-2"},[_c('v-badge',{staticClass:"mr-2",attrs:{"inline":"","left":"","dot":"","color":"#FF8392"}}),_vm._v(" "),_c('span',{staticClass:"text-body-2 text--background"},[_vm._v("\n                      "+_vm._s(_vm.$vuetify.lang.t('$vuetify.conversations.editModes.status'))+" |\n                    ")]),_vm._v(" "),_c('span',{staticClass:"text-body-2 font-weight-bold text--background"},[_vm._v("\n                      "+_vm._s(_vm.$vuetify.lang.t('$vuetify.conversations.editModes.away'))+"\n                    ")])],1),_vm._v(" "),_c('p',{staticClass:"text-caption text-left mb-2"},[_c('span',{staticClass:"text-body-2 text--background"},[_vm._v("\n                      "+_vm._s(_vm.$vuetify.lang.t('$vuetify.conversations.editModes.awaySub'))+"\n                  ")])]),_vm._v(" "),_c('v-textarea',{staticClass:"background--text",attrs:{"required":"","outlined":"","counter":"160","background-color":"white","rows":"3","placeholder":_vm.$vuetify.lang.t('$vuetify.conversations.editModes.placeholderAway'),"error-messages":errors},model:{value:(_vm.awayMessage),callback:function ($$v) {_vm.awayMessage=$$v},expression:"awayMessage"}})]}}],null,true)})],1)],1)],1)]}}])})],1),_vm._v(" "),_c('v-card-actions',{staticClass:"justify-center pb-12"},[_c('v-row',{staticClass:"px-16 mx-16"},[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"block":""},on:{"click":function($event){return _vm.saveModes()}}},[_c('v-icon',[_vm._v("mdi-lock")]),_vm._v("\n              "+_vm._s(_vm.$vuetify.lang.t('$vuetify.conversations.editModes.save'))+"\n            ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }