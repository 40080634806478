
import {loadStripe} from '@stripe/stripe-js';

const StripeObject = {
  stripe: null,
  publishableKey: process.env.STRIPE_PUBLISHABLE_KEY || "pk_test_51Hm8NUCJXuvKeZW0z0QcabKIPWS7rVsTYqzyxqDq88TnW5WluHh07D8HR2qRSeqAs0rMI2L3eKJl9iXtKiLc7vI300idvAiQBS",

  async instance () {
    if(this.stripe === null){
      this.stripe = await loadStripe(this.publishableKey);
    }
    return this.stripe
  },

};
export default StripeObject;
