<template>
  <v-switch
      class="mb-1 mt-0"
      v-model="popup.active"
      color="success"
      hide-details
      @click="eventHandler($event)"
  >{{popup}}</v-switch>
</template>

<script>


  import {EventBus} from "../../packs/app";
  import popupConnector from "../../connectors/popupConnector";

  export default {
    name: "popup-status",
    props: {
      popup: {
        type: Object,
        default: {}
      }
    },
    components: {
    },
    data () {
      return {
      }
    },
    methods: {
      async eventHandler(event){
        let newStatus = this.popup.active
        let response = await popupConnector.updateStatus(this, this.popup.id, newStatus)
        if(response === true){
          EventBus.$emit('update-popup-list');
        }else {
          this.popup.active = !this.popup.active
        }
      },
    },
    mounted(){
    }
  }
</script>
<style scoped>

</style>