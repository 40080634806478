var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('secure-content',{attrs:{"remove-padding":true}},[_c('v-row',[_c('v-col',{staticClass:"pt-13 pl-10",attrs:{"cols":"7","sm":"7"}},[_c('p',{staticClass:"text-h6 text--background text-left mb-0"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.campaign.title')))]),_vm._v(" "),_c('p',{staticClass:"text-body-2 text--background text-left"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.campaign.subtitle')))]),_vm._v(" "),_c('ValidationObserver',{ref:"vCampaign",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('v-container',[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"11"}},[_c('ValidationProvider',{attrs:{"name":"Campaign name","rules":{ required: true, min: 5, regexChar: /^[\w\s]+$/g }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"text-caption text-left mb-2"},[_c('span',{staticClass:"text-body-2 font-weight-bold text--background"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.campaign.name')))])]),_vm._v(" "),_c('v-text-field',{staticClass:"background--text",attrs:{"required":"","outlined":"","counter":"60","background-color":"white","error-messages":errors},model:{value:(_vm.campaign.name),callback:function ($$v) {_vm.$set(_vm.campaign, "name", $$v)},expression:"campaign.name"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Send date","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"text-caption text--background text-left mb-2"},[_c('span',{staticClass:"text-body-2 font-weight-bold text--background"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.campaign.scheduleTime')))])]),_vm._v(" "),_c('custom-datetime',{staticClass:"background--text",attrs:{"type":"datetime","auto":"","min-datetime":_vm.luxonDateTime.local().plus({ minutes: 10 }).toISO(),"text-field-props":{
                                   outlined: true,
                                   backgroundColor: 'white',
                                   appendIcon: 'mdi-calendar-question',
                                   errorMessages: errors
                            }},model:{value:(_vm.campaign.scheduleTime),callback:function ($$v) {_vm.$set(_vm.campaign, "scheduleTime", $$v)},expression:"campaign.scheduleTime"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('p',{staticClass:"text-caption text--background text-left mb-0 py-11"},[_c('span',{staticClass:"text-body-2 text--background"},[_vm._v(" "+_vm._s(_vm.getTimezone()))])])])],1),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"11"}},[_c('p',{staticClass:"text-caption text-left mb-2"},[_c('span',{staticClass:"text-body-2 font-weight-bold text--background"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.campaign.media')))])]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"MMS Image","rules":typeof _vm.campaign.media === 'string' ? null: 'size:600|maxdimensions:610,810'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-file-input',{staticClass:"background--text",attrs:{"show-size":"","accept":"image/png, image/jpeg, image/gif","chips":"","outlined":"","background-color":"white","error-messages":errors},on:{"change":function($event){return _vm.updateRemoveFileFlag()}},model:{value:(_vm.campaign.media),callback:function ($$v) {_vm.$set(_vm.campaign, "media", $$v)},expression:"campaign.media"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"11"}},[_c('sms-editor',{attrs:{"title":_vm.$vuetify.lang.t('$vuetify.campaign.sms'),"message":_vm.campaign.sms,"discountCode":_vm.campaign.discountCode,"messageEstimatedSegment":_vm.campaign.messageEstimatedSegment,"tags":_vm.tags},on:{"update:message":function($event){return _vm.$set(_vm.campaign, "sms", $event)},"update:discountCode":function($event){return _vm.$set(_vm.campaign, "discountCode", $event)},"update:discount-code":function($event){return _vm.$set(_vm.campaign, "discountCode", $event)},"update:messageEstimatedSegment":function($event){return _vm.$set(_vm.campaign, "messageEstimatedSegment", $event)},"update:message-estimated-segment":function($event){return _vm.$set(_vm.campaign, "messageEstimatedSegment", $event)}}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-4",attrs:{"cols":"11"}},[_c('listSegmentSelector',{attrs:{"list":_vm.campaign.list},on:{"update:list":function($event){return _vm.$set(_vm.campaign, "list", $event)}}})],1)],1)],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"align-right"},[_c('v-item-group',{staticClass:"v-btn-toggle"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.buttonDisable},on:{"click":function($event){return _vm.scheduleCampaign(true)}}},[_vm._v("\n            "+_vm._s(this.$vuetify.lang.t('$vuetify.campaign.submit'))+"\n          ")]),_vm._v(" "),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","disabled":_vm.buttonDisable}},on),[_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}])},[_vm._v(" "),_c('v-list',[_c('v-list-item',{attrs:{"disabled":_vm.buttonDisable},on:{"click":function($event){return _vm.scheduleCampaign(false)}}},[_c('v-list-item-title',{staticClass:"background--text"},[_c('v-icon',[_vm._v("mdi-file-edit-outline")]),_vm._v("\n                  "+_vm._s(this.$vuetify.lang.t('$vuetify.campaign.draft'))+"\n                ")],1)],1),_vm._v(" "),_c('send-test')],1)],1)],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"5"}},[_c('messageViewer',{staticClass:"pt-16 mt-16 pt-sm-2",attrs:{"sms":this.campaign.sms,"media":this.campaign.media,"show-estimates":true,"discountCode":_vm.campaign.discountCode,"messageEstimatedSegment":_vm.campaign.messageEstimatedSegment,"type-id":_vm.campaign.list.type_id},on:{"update:sms":function($event){return _vm.$set(this.campaign, "sms", $event)},"update:media":function($event){return _vm.$set(this.campaign, "media", $event)},"update:discountCode":function($event){return _vm.$set(_vm.campaign, "discountCode", $event)},"update:discount-code":function($event){return _vm.$set(_vm.campaign, "discountCode", $event)},"update:messageEstimatedSegment":function($event){return _vm.$set(_vm.campaign, "messageEstimatedSegment", $event)},"update:message-estimated-segment":function($event){return _vm.$set(_vm.campaign, "messageEstimatedSegment", $event)},"update:typeId":function($event){return _vm.$set(_vm.campaign.list, "type_id", $event)},"update:type-id":function($event){return _vm.$set(_vm.campaign.list, "type_id", $event)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }