<template>
  <div>
    <v-menu bottom left offset-y :close-on-content-click="false" ref="optionsMenu">
      <template v-slot:activator="{ on }">
        <v-btn
            outlined
            v-on="on"
            class="background--text statusBtn"
            block
        >
          <v-badge class="mr-2"
                   inline
                   left
                   dot
                   :color="dotColors[statusSelectedIndex]"
          />
          {{statusOptions[statusSelectedIndex]}}
          <v-icon >mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="handleStatus($event, idx)"
                     v-for="(status,idx) in statusOptions" v-bind:key="idx">
          <v-list-item-title class="background--text">
            {{ status }}
          </v-list-item-title>
        </v-list-item>
        <conversations-edit-modes :away-message.sync="awayMessage" :inactive-message.sync="inactiveMessage"/>
      </v-list>
    </v-menu>
  </div>

</template>

<script>

import conversationsEditModes from './conversations-edit-modes'
import conversationConnector from "../../connectors/conversationConnector";

export default {
  name: "conversations-options",
  components: {
    conversationsEditModes
  },
  data() {
    return {
      statusOptions: [
        "Status | Active",
        "Status | Inactive",
        "Status | Away"
      ],
      statusSelectedIndex: 0,
      dotColors:['success','#FFED8D','#FF8392'],
      statusKeys: ['active','inactive','away'],
      inactiveMessage:null,
      awayMessage:null,
    }
  },
  async mounted() {
    let config = await conversationConnector.loadConfigurations(this)
    this.awayMessage = config.away_message
    this.inactiveMessage = config.inactive_message
    if(config.status === 'active'){
      this.statusSelectedIndex = 0
    }else if(config.status === 'inactive'){
      this.statusSelectedIndex = 1
    }else{
      this.statusSelectedIndex = 2
    }
  },
  methods: {
    async handleStatus(event,statusIdx){
      this.statusSelectedIndex = statusIdx
      await conversationConnector.updateConfiguration(this,{
        status: this.statusKeys[this.statusSelectedIndex]
      })
      if(this.$refs.optionsMenu) {
        this.$refs.optionsMenu.save();
      }
    },
    handleFail(error){
    },
  }
}
</script>

<style scoped>
.statusBtn {
  width:18vh;
  background: white;
  border-color: var(--v-border-base);
}
</style>