<template>
  <img :src="dsSettings">
</template>

<script>
  import dsSettings from '../../images/icons/ds-settings.svg'

  export default {
    name: "ds-settings",
    components: {
      dsSettings
    },
    data () {
      return {
        dsSettings: dsSettings,

      }
    }
  }
</script>

<style scoped>

</style>