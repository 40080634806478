import connector from '../connector'

const MARKETING_BLAST_REPORT_URI = '/campaign/list';

const marketingBlastConnector = {

  // this method must have this estructure in order to works with the paginatedTable component
  findItems (component,query,callback) {
    connector.send(component,
    {
      method: 'post',
      url: MARKETING_BLAST_REPORT_URI,
      data: query
    },function (component,response) {
      callback(response.data.response);
    });
  }
 };
export default marketingBlastConnector
