<template>
  <img :src="dsDashboard">
</template>

<script>
  import dsDashboard from '../../images/icons/ds-dashboard-selected.svg'

  export default {
    name: "ds-dashboard",
    components: {
      dsDashboard
    },
    data () {
      return {
        dsDashboard: dsDashboard,

      }
    }
  }
</script>

<style scoped>

</style>