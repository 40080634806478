export default {
  header: {
    dashboard: 'Home',
    campaigns: 'Campaigns',
    contact_lists: 'Contacts',
    conversations: 'Conversations',
    title: 'Shopsend',
    changePassword: 'Change password',
    logOut: 'Logout',
    logIn: 'Log In',
    billing: 'Contact Billing',
    plan: 'Current Plan:',
    active: 'Active Contacts:',
    messages: 'Messages Exchanged:',
    currentBalance: 'Current Balance:',
    nextInvoice: 'Next Invoice:',
    trialEnds: 'Trial Ends:',
    feedback: "Send feedback",
    feature: "Request a feature"
  },
  flash: {
    standardError: "Oops. Something went wrong. Please try again. If the problem persists please email support@shopsend.io {0}"
  },
  dashboard: {
    title: 'Dashboard',
    welcomeBack:'Welcome back,',
    sales: "Sales",
    revenue: "Shopsend revenue",
    campaigns: "Campaigns",
    automations: "Automations",
    totalRevenue: "Total store revenue",
    discounts: "Other Revenue",
    code: 'Website Popup',
    codeHelp: "Place this code at the end of your pages, just before the </body> tag.",
    links: {
      list: {
        title: "Contacts",
        description: "Find and manage your contacts."
      },
      campaigns: {
        title: "Campaigns",
        description: "Create and send beautiful SMS campaigns."
      },
      integrations: {
        title: "Integrations",
        description: "Description"
      }
    }
  },
  login: {
    login: 'Log in',
    loginButton: 'Login',
    validating: 'validating ...',
    email: 'Email Address',
    password: 'Password'
  },
  auth: {
    error: "You don't have permissions to see this, please Log In",
    googleError: 'We have problems with Google authentication'
  },
  contacts: {
    title: 'ALL CONTACTS',
    growthTools: 'List Growth Tools'
  },
  contactActions: {
    optedOut: "Opt-out",
    optedOutConfirm: {
      message: "Are you sure you want to opt-out this contact?",
      subtitle: "Opted-out contacts can not be opted back in.",
      cancel: "Cancel",
      ok: "Yes, opt-out"
    }
  },
  segment: {
    newSegment: "new segment",
    create: "Create a new segment",
    subtitle: "Segmentation allows you to group contacts by similar traits or behaviors so you can send them personalized content.",
    name: "Segment Name",
    definition: "Definition",
    estimation: "Estimated Segment Size",
    contact: "Contact",
    save: "Save Segment"
  },
  campaign: {
    create: 'Create Campaign',
    title: 'Create a one time campaign',
    subtitle: 'One-off campaigns allow you to schedule messaging for some or all of your contacts.',
    viewer: 'CAMPAIGN PREVIEW',
    contacts: 'Contacts:',
    cost: 'Estimated Cost:',
    revenue: 'Estimated Revenue:',
    name: 'Campaign Name',
    scheduleTime: 'Select when to send campaign',
    media: 'Add an image',
    sms: 'Write a message',
    lists: 'Select an audience',
    link: 'Link',
    submit: 'Schedule campaign',
    draft: 'Save as draft',
    test: 'Send a test',
    testInfo: {
      title: "Where do you want the test sent?",
      titleEmail: "Opps! We don't recognize that number.",
      subtitleEmail: "We need a bit more information.",
      phone: "Phone",
      phoneEx: "(ex. 7192662837)",
      email: "Email",
      send: "Send Test",
      cancel: "Cancel"
    },
    scheduled: 'Campaign was saved successful',
    notFound: 'Campaign not found',
    searching: 'Searching campaign',
    details: 'Campaign Details',
    carriers: 'Engagement by Carrier',
    standardType: 'One-off',
    automationType: 'Automation',
    list: 'List / Segment',
    sender: 'Sender',
    schedule: 'Scheduled',
    id: 'Campaign ID',
    delivered: 'Delivered',
    clicked: 'Clicked',
    visitors: 'Visitors',
    addCart: 'Add to Cart',
    checkout: 'Reached Checkout',
    conversions: 'Conversions',
    numberOfClicks: 'Total Clicks: ',
    numberOfOrders: 'Total Orders: '
  },
  automatedCampaign: {
    title: "Create an automated campaign",
    subtitle: "Automated campaigns allow you to trigger specific messaging for contacts who exhibit a certain behavior.",
    viewModeTitle: "Welcome Flow",
    viewModeSubtitle: "Here is the message your new contacts will receive when they subscribe to your popup.",
    name: 'Automation Name',
    trigger: "Trigger",
    subTrigger: "Which event should cause the campaign to send?",
    delay: "Delay",
    subDelay:"How long after the trigger occurs should we send the campaign?",
    mms: 'MMS Media',
    sms: 'SMS Copy',
    discount: "Discount Code",
    createDiscount: "Create new discount",
    start: "Start Automation",
    saveWelcome: "Save Welcome Flow",
    started: 'Trigger has been started successful',
    welcomeSaved: 'Welcome flow saved.',
    optedIn: 'Opt-in Compliance',
    subOptedIn: 'Which level of compliance would you prefer for your sign ups?',
    optedInHelp: '<b>Opt-In Compliance</b> <br><br> Single opt-in comes default. You may select double opt-in,<br> which requires the recipient to confirm their request. <br><br> *Double opt-in may slow your contact list growth.'
  },
  discountWarning: {
    title: "Are you using discount codes?",
    message: "Hey! It looks like you tried to add a discount code. Are any of the below discount codes? <br><br> {0} <br> ",
    ok: "Yes, one or more is a code",
    cancel: "No, none of those are codes"
  },
  discountUsed: {
    title: "Are you sure you want to use this code?",
    message: "{0} <br><br> It looks like it has been used before. All future revenue associated with this code (including from other services) will be attributed to this campaign.",
    ok: "Continue",
    cancel: "Change code"
  },
  invalidDiscount: {
    title: "Discount code is already in use",
    message: "It looks like you're trying to use a discount code that is associated with another campaign. <br> <br> {0}<br> <br> Please use a different code.",
    ok: "Continue"
  },
  smsEditor: {
    note: "Note: It’s important to make sure that any incentive offered here is consistent with what is offered in your popup.",
    discount: "Discount Code",
    createDiscount: "(Create new discount)",
    noteTwo: "Note: For best results, it’s important to not reuse discount codes across multiple campaigns and marketing channels.",
    send: "Send",
    label: "Compose your message..."
  },
  createCampaign: {
    title: "Which kind of campaign would you like to create?",
    scheduled: "Scheduled",
    subScheduled: "Schedule a campaign to be sent to a list or segment",
    automated: "Automated",
    subAutomated: "Automatically send campaign based on shopper behavior"
  },
  campaignActions: {
    viewReport: "View Report",
    edit: "Edit",
    draft: "Make a draft",
    schedule: "Schedule campaign",
    delete: "Delete campaign",
    update: {
      message: "Your campaign schedule needs to be updated",
      subtitle: "Your campaign is currently scheduled for a previous date and time and needs to be updated",
      cancel: "Cancel",
      ok: "Update"
    },
    deleteConfirm: {
      message: "Are you sure you want to delete this campaign?",
      subtitle: "Deleted campaigns cannot be restored",
      cancel: "Cancel",
      ok: "Delete"
    },
    toggleOff: {
      message: "Are you sure you want to turn this automation off?",
      subtitle: "If you turn this automation off, contacts will no longer receive messages associated with it. <br> <br>" +
        "<b>Note</b>: Contacts currently in queue for this automation will still receive messages from this automation until the flow is complete.",
      cancel: "Cancel",
      ok: "Turn off automation"
    },
    toggleOn: {
      message: "Are you sure you want to change this automation?",
      subtitle: "You have an active automation from this trigger running. Turning on a new automation will turn the current one off. <br> <br>" +
        "<b>Note</b>: Contacts currently in queue for this automation will still receive messages from this automation until the flow is complete.",
      cancel: "Cancel",
      ok: "Turn on automation"
    },
    draftConfirm: {
      message: 'Are you sure you want to set campaign as "draft"?',
      subtitle: "Campaign drafts are paused and will not send at the time they are scheduled",
      cancel: "Cancel",
      ok: "Set as Draft"
    }
  },
  popupEditor: {
    emailCollector: "Email Collector",
    phoneCollector: "Phone Collector",
    success: "Success",
    displaySettings: "Display Settings",
    welcomeFlow: "Welcome Flow",
    publish: "Publish",
    exitConfirm: {
      message: "Do you want to publish the changes made?",
      subtitle: "There are changes made to this popup that have not been published. These changes have been saved, but they must be published to reflect live online.",
      exit: "Save & Exit",
      publish: "Publish"
    },
    exitConfirmWithoutWelcome: {
      message: "Do you want to exit without saving a welcome flow?",
      subtitle: "This popup cannot be published without defining the welcome flow that will be sent to the contacts who sign up.",
      exit: "Save & Exit",
      back: "Go back to editor"
    }
  },
  popupActions: {
    archive: "Archive Popup",
    viewReport: "View Report",
    edit: "Edit",
    archiveConfirm: {
      message: "Are you sure you want to archive?",
      subtitle: "Archiving an audience generation tool does not delete it, but you may need to contact support to restore it in your list.",
      cancel: "Cancel",
      ok: "Yes, archive"
    },
  },
  newPopup: {
    title: "Select a popup template",
    subtitle: "Grow your subscriber list with proven high converting popup templates\n" +
      "and use as is or customize for your brand"
  },
  displaySettings: {
    title: 'Popup Display Settings',
    description: 'Changing the display settings of your popup will allow you to have more control over who your popup is shown to and how often.',
    when: 'When should your campaign popup be displayed?',
    landing: 'On Landing (when user loads any page) after',
    seconds: 'seconds on page',
    how: 'How often should the popup be displayed?',
    everyPage: "Every page load",
    everySession: "Every session",
    stop: "When should the popup stop showing?",
    never: "Never",
    stopShow: "Stop showing",
    afterSigned: "After visitor has signed up or clicked",
    afterSeen: "After visitor has seen the campaign",
    times: "times",
    save: "Save Display Settings"
  },
  reports: {
    marketingBlast: {
      title: 'BROADCASTS',
      automationsTitle: 'AUTOMATIONS'
    }
  },
  summary: {
    fields: {
      msg_delivered: 'Campaigns',
      avg_clicked: 'Avg CTR',
      avg_opted_out: 'Avg Opt Out',
      Active: 'Active',
      OptedOut: 'Opted Out',
      PreOptedIn: "Pre Opted In",
      total_contacts: "Contacts",
      grossSales: "Gross Sales",
      orders: "Orders",
      aov:"AOV",
      rpm: "RPM",
      optOut: "Opt-out",
      help: {
        grossSales: "Gross Sales<br><br> This is the amount of gross revenue associated <br> with clicks and discount codes from this campaign",
        orders: "Orders<br><br> This is the number of orders associated  with clicks<br> and discount codes from  this campaign",
        aov:"Average Order Value<br><br> This is the average value in gross revenue <br> associated with orders from this campaign",
        rpm: "Revenue Per Message<br><br>This is the average gross revenue earned <br>from each message delivered",
        optOut: "Opt-out Rate<br><br>This is the percentage of contacts who <br> opted-out of messaging after this campaign"
      }
    }
  },
  profile: {
    welcome: "Welcome",
    shopsend:" to Shopsend",
    subtitle: "Tell us a bit about yourself so we can custom tailor your dashboard.",
    fullname: "Whats your name?",
    passwordSubtitle: "Let's set up your password so you can login anytime you like.",
    changePassword: 'Change password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    phone: 'What is your cell phone number?',
    email: 'Which email should we use for invoices?',
    newPasswordConfirmation: 'Confirm the new password',
    finishButton: 'Create account',
    changePasswordButton: 'Change',
    changedPassword: "The password has been changed successfully",
    userUpdated: 'The user has been changed successfully'
  },
  compliance: {
    welcome: "Last step! Let’s make sure you’re in compliance.",
    subtext: "<b>Important:</b> Please note that while we cannot and will not provide legal guidance or recommendations, we can start you off with a base of language for which you can provide your legal team. Every company is different and therefore this language must be reviewed by your council.",
    terms: "Add the below copy to your <b>Terms of Service</b>. Make sure to edit the <b>[bracketed]</b> terms.",
    privacy: "Add the below copy to your <b>Privacy Policy</b>. Make sure to edit the <b>[bracketed]</b> terms.",
    checkout: "Add the below copy to your <b>Checkout Flow</b>.",
    finish: 'Finish Setup'
  },
  company: {
    welcome: "<b>Welcome to ShopSend!</b> Let's start by connecting your e-commerce store.",
    platform: "Which e-commerce platform do you use?",
    connect: "Let's connect your store.",
    shopifyTitle: "What is your store URL?",
    shopifyDomain: ".myshopify.com",
    finishButton: "Connect Store",
    done: "Your data has been updated successfully",
    tellUs: 'Tell us a bit about your company so we can make it official!',
    name: "What's your company name?",
    website: "What's your website?",
    continueButton: "Continue"
  },
  emailService: {
    welcome: "Now let's connect your email automation tool.",
    platform: "Which email service do you use?",
    key: "What is your API key?",
    lists: "Which list should we add new contacts to?",
    skip: 'Skip',
    continue: "Continue"
  },
  conversations: {
    editModes: {
      title: 'Update Conversation Auto-Replies',
      subtitle: 'Auto-Replies allow you to pre-define the message your contacts receive when ' +
        'they respond to campaigns and automations based on the status you have selected.',
      optionName: 'Edit Modes',
      status: 'Status',
      active: 'Active',
      inactive: 'Inactive',
      away: 'Away',
      activeSub: 'Use when you are planning on actively responding ASAP.',
      inactiveSub: 'Use when you plan on responding within the next hour or so.',
      placeholderInactive: 'Hey! Thanks for the text! I\'m just wrapping something up but I\'ll text you back shortly.',
      placeholderAway: 'Hey! Thanks for the text! I\'m offline at the moment but I\'ll text you back when I get a chance.',
      awaySub: 'Use this status when you aren’t likely to respond for a while.',
      save: "Save Auto-Replies"
    }
  },
  plans: {
    title: "Choose your plan",
    subtitle: "The trial period has ended. Choose a plan that grows with your business.",
    offer: "Save 50% or more with an annual plan",
    payAnnually: "PAY ANNUALLY",
    payMonthly: "PAY MONTHLY",
    asYouGoTitle: "Not convinced yet or need more time?",
    asYouGoSub: "Get started with ",
    asYouGoAction: "pay-as-you-go",
    checkout: "Checkout",
    payment: "Enter payment details",
    charge: "You’ll be charged {0} until you cancel your subscription. Previous charges won’t be refunded when you cancel unless it’s legally required. Your payment data is encrypted and secure. All amounts are shown in USD.",
    terms: "I agree to the ShopSend Terms and the Automatic Renewal Terms above. I understand that there are other charges that may accrue in the form of SMS, MMS, & other usage based fees as explained in the schedule of additional fees in the pricing summary to the right.",
    summary: "Pricing Summary",
    subType: "{0} subscription",
    subRecurrence: "x {0} month(s)",
    subtotal: 'Subtotal',
    billed: 'Billed now',
    additionalFees: "Additional fees (billed at the end of the month)",
    priceSms: "Price per SMS Segment*",
    priceMms: "Price per MMS**",
    smsDetails: "* An SMS Segment is defined as any message under 160 characters (GSM-7) or 70 characters (non GSM-7 or emoji’s)",
    mmsDetails: "** An MMS is defined as any message that includes additional forms of media (image, gif, video, audio, contact card, etc.)",
    paymentProcessing: 'Processing...',
    success:"Success!",
    waitCc: 'This should only take a moment',
    welcome: 'Welcome to ShopSend',
    ops: 'Oops!',
    wrong: 'Something went wrong',

  },
  info:{
    hi: "Hi there 👋🏼",
    text: "It looks like you found us even though we’re an unlisted app.",
    sub: "We’d love to work with you, but we need to get to know you first.",
    request: "Request Account",
    reach: "or reach out to "
  },
  accountDisable:{
    hi: "Hi there 👋🏼",
    info: "Your account has been temporarily locked",
    subtitle: "Please contact <a href=\"mailto: accounts@shopsend.io\">accounts@shopsend.io</a> for support"
  },
  dataIterator: {
    pageText: '{0}-{1} of {2}',
    noResultsText: 'No matching records found',
    loadingText: 'Loading items...',
  },
  dataTable: {
    itemsPerPageText: 'Rows per page:',
    ariaLabel: {
      sortDescending: ': Sorted descending. Activate to remove sorting.',
      sortAscending: ': Sorted ascending. Activate to sort descending.',
      sortNone: ': Not sorted. Activate to sort ascending.',
    },
  },
  dataFooter: {
    itemsPerPageText: 'Items per page:',
    itemsPerPageAll: 'All',
    nextPage: 'Next page',
    prevPage: 'Previous page',
    firstPage: 'First page',
    lastPage: 'Last page',
  },
  datePicker: {
    itemsSelected: '{0} selected',
  },
  noDataText: 'No data available',
  carousel: {
    prev: 'Previous visual',
    next: 'Next visual',
  },
  calendar: {
    moreEvents: '{0} more',
  },
  badge: 'Badge'
}