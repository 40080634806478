<template>
  <div id="app">
    <v-app id="inspire">
      <flash-message></flash-message>
      <v-main id="app-cont" :class="getBackgroundColor()" >
        <!-- Provides the application the proper gutter -->
        <v-container fluid>
          <router-view></router-view> <!-- inyecta los componentes deacuerdo al router-->
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>

  import auth from "../models/auth";

  export default {
    data: function () {
      return {
      }
    },
    methods: {
      getBackgroundColor() {
        if(auth.isLogged(this)){
          return 'clearbackground'
        }else {
          return 'white'
        }
      }
    }
  }
</script>

<style >
  p {
    font-size: 2em;
    text-align: center;
  }
  .v-btn {
    text-transform:none !important;
    letter-spacing: 0em !important;
  }
</style>