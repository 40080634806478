<template>
  <div class="align-right">
    <v-item-group class="v-btn-toggle">
    <div v-if=" campaign.status === 'draft' || campaign.status === 'scheduled'">
      <v-btn  small outlined @click="eventHandler($event, 'edit')" class="text-capitalize background--text ">
        {{ this.$vuetify.lang.t('$vuetify.campaignActions.edit') }}
      </v-btn>
    </div>
    <div v-else>
      <v-btn  small outlined @click="eventHandler($event, 'report')" class="text-capitalize background--text">
        {{ this.$vuetify.lang.t('$vuetify.campaignActions.viewReport') }}
      </v-btn>
    </div>
    <div v-if="campaign.status !== 'completed' && campaign.status !== 'processing'">
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
                  outlined
                  v-on="on"
                  small
                  class="background--text"
          >
            <v-icon >mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list>

          <div v-if=" campaign.status === 'draft'">
            <v-list-item  @click="eventHandler($event,'schedule')">
              <v-list-item-title class="background--text">
                <v-icon color="green">mdi-play-circle-outline</v-icon>
                {{ this.$vuetify.lang.t('$vuetify.campaignActions.schedule') }}
              </v-list-item-title>
            </v-list-item>
          </div>

          <div v-if=" campaign.status === 'scheduled'">
            <v-list-item  @click="eventHandler($event,'draft')" >
              <v-list-item-title class="background--text">
                <v-icon>mdi-arrow-left-thin-circle-outline</v-icon>
                {{ this.$vuetify.lang.t('$vuetify.campaignActions.draft') }}
              </v-list-item-title>
            </v-list-item>
          </div>
          <v-list-item  @click="eventHandler($event,'delete')">

            <v-list-item-title class="background--text">
              <v-icon color="red">mdi-close-circle-outline</v-icon>
              {{ this.$vuetify.lang.t('$vuetify.campaignActions.delete') }}
            </v-list-item-title>
          </v-list-item>


        </v-list>
      </v-menu>
    </div>
  </v-item-group>
  </div>
</template>

<script>
  import router from '../../router'
  import campaignConnector from "../../connectors/campaignConnector";
  import {EventBus} from "../../packs/app";

  export default {
    name: "campaign-actions",
    props: {
      campaign: {
        type: Object,
        default: {}
      }
    },
    components: {
    },
    data () {
      return {
      }
    },
    methods: {
      eventHandler(event, action){
        if(action === "report"){
          this.openReport()
        }else if(action === "schedule"){
          this.updateStatus("scheduled")
        } else if(action === "draft"){
          this.updateStatus("draft")
        } else if(action === "edit") {
          this.editCampaign()
        } else if(action === "delete") {
          this.deleteCampaign()
        }
      },
      async deleteCampaign() {
        const choice = await this.$confirm(this.$vuetify.lang.t('$vuetify.campaignActions.deleteConfirm.message'),
          {
            subtitle: this.$vuetify.lang.t('$vuetify.campaignActions.deleteConfirm.subtitle'),
            buttonFalseText: this.$vuetify.lang.t('$vuetify.campaignActions.deleteConfirm.cancel'),
            buttonTrueText: this.$vuetify.lang.t('$vuetify.campaignActions.deleteConfirm.ok')
          })
        if (choice){
          campaignConnector.deleteCampaign(this, this.campaign.id)
        }
      },
      editCampaign() {
        router.push('/edit-campaign/'+ this.campaign.id)
      },
      openReport() {
        router.push('/campaign/'+ this.campaign.id)
      },
      async updateStatus(newStatus) {
        if(newStatus === "scheduled") {
          this.handleScheduleStatus(newStatus)
        }else if(newStatus === "draft") {
          this.handleDraftStatus(newStatus)
        }
      },
      async handleDraftStatus(newStatus) {
        const choice = await this.$confirm(this.$vuetify.lang.t('$vuetify.campaignActions.draftConfirm.message'),
          {
            subtitle: this.$vuetify.lang.t('$vuetify.campaignActions.draftConfirm.subtitle'),
            buttonFalseText: this.$vuetify.lang.t('$vuetify.campaignActions.draftConfirm.cancel'),
            buttonTrueText: this.$vuetify.lang.t('$vuetify.campaignActions.draftConfirm.ok'),
          })
        if (choice){
          campaignConnector.updateStatus(this, this.campaign.id, newStatus)
        }
      },
      async handleScheduleStatus(newStatus) {
        let currentTime = new Date();
        let schedTime = new Date(this.campaign.schedule_time);
        if(schedTime < currentTime) {
          const choice = await this.$confirm(this.$vuetify.lang.t('$vuetify.campaignActions.update.message'),
            {
              subtitle: this.$vuetify.lang.t('$vuetify.campaignActions.update.subtitle'),
              buttonFalseText: this.$vuetify.lang.t('$vuetify.campaignActions.update.cancel'),
              buttonTrueText: this.$vuetify.lang.t('$vuetify.campaignActions.update.ok'),
            })
          if (choice){
            this.editCampaign()
          }
        } else {
          campaignConnector.updateStatus(this, this.campaign.id, newStatus)
        }
      },
      receiveUpdateStatusResponse(response) {
        if(response === true){
          EventBus.$emit('update-campaigns-list');
        }
      }
    },
    mounted(){
    }
  }
</script>
<style scoped>

</style>