const emojiRegex = require('emoji-regex/RGI_Emoji.js');

const discounts = {
  async validateDiscountHardcoded(sms, component, discountCode) {

    let discountsSelected = []

    let possibleDiscounts = this.getPossibleDiscountsFrom(sms)
    if(discountCode!== null){
      possibleDiscounts.push(discountCode)
    }

    if(possibleDiscounts.length <= 0) {
      return discountsSelected
    }

    //check if discount codes are restricted
    let internalTri = component.$store.getters.internalTrigger
    let restrictedDiscounts = component.$store.getters.restrictedDiscounts

    let restrictedMatched = possibleDiscounts.filter(function(possibleDiscount) {
      let founded  = restrictedDiscounts.filter(function(restrictedDiscount) {
        return restrictedDiscount.code === possibleDiscount && restrictedDiscount.campaign_kind !== internalTri
      })
      return founded.length !== 0
    })


    if(restrictedMatched.length > 0) {
      await this.showInvalidDiscount(restrictedMatched,component)
      return false
    }

    let result = await this.askIfContinueWithDiscountsUsed(possibleDiscounts, component)

    if(result.continueWithThem){
      discountsSelected.push(...result.discountsUsed)

      possibleDiscounts = possibleDiscounts.filter( function( discount ) {
        return !result.discountsUsed.includes( discount );
      } );
      if(possibleDiscounts.length > 0) {
        let areDiscounts = await this.confirmPossibleDiscount(possibleDiscounts, component)
        if (areDiscounts){
          discountsSelected.push(...possibleDiscounts)
        }
      }

      return discountsSelected

    }else{
      return false
    }


  },

  async askIfContinueWithDiscountsUsed(possibleDiscounts, component){
    let discountsUsed = this.findDiscountsUsed(possibleDiscounts, component)
    let continueWithThem = true
    if(discountsUsed.length > 0) {
      continueWithThem = await this.confirmDiscountUsed(discountsUsed, component)
    }
    return {continueWithThem, discountsUsed}
  },

  findDiscountsUsed(possibleDiscounts,component){
    let discounts = component.$store.getters.discounts
    let discountsUsed = discounts.filter(function(discount) { return possibleDiscounts.includes(discount.code) && discount.usage > 0 })
    return discountsUsed.map(function(discount) {
      return discount.code;
    })
  },

  async confirmPossibleDiscount(possibleDiscounts, component){
    if(possibleDiscounts.length > 0){
      let areDiscounts = await component.$confirm(component.$vuetify.lang.t('$vuetify.discountWarning.title'),
        {
          subtitle: component.$vuetify.lang.t('$vuetify.discountWarning.message',possibleDiscounts.join('<br>')),
          buttonFalseText: component.$vuetify.lang.t('$vuetify.discountWarning.cancel'),
          buttonTrueText: component.$vuetify.lang.t('$vuetify.discountWarning.ok'),
          width: 650
        })
      return areDiscounts
    }
    return false
  },

  async confirmDiscountUsed(discounts, component){
    if(discounts.length > 0){
      let continueWithThem = await component.$confirm(component.$vuetify.lang.t('$vuetify.discountUsed.title'),
        {
          subtitle: component.$vuetify.lang.t('$vuetify.discountUsed.message',discounts.join('<br>')),
          buttonFalseText: component.$vuetify.lang.t('$vuetify.discountUsed.cancel'),
          buttonTrueText: component.$vuetify.lang.t('$vuetify.discountUsed.ok'),
          width: 650
        })
      return continueWithThem
    }
    return false
  },

  async showInvalidDiscount(discounts, component){
    await component.$confirm(component.$vuetify.lang.t('$vuetify.invalidDiscount.title'),
      {
        subtitle: component.$vuetify.lang.t('$vuetify.invalidDiscount.message',discounts.join('<br>')),
        buttonTrueText: component.$vuetify.lang.t('$vuetify.invalidDiscount.ok'),
        width: 650
      })
  },

  getPossibleDiscountsFrom(sms){
    const regex = emojiRegex()
    let words = sms.replaceAll( "\n", " ").split(" ")
    let possibleDiscounts = []
    for(let i = 0; i < words.length; i ++) {
      if(words[i].length >= 3 && words[i] === words[i].toUpperCase()){ //&& isNaN(words[i])

        if(!regex.exec(words[i])){
          possibleDiscounts.push(words[i])
        }
      }
    }

    return possibleDiscounts
  }
}
export default discounts