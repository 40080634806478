import connector from '../connector'

const ENGAGEMENT_URI = '/api/v1/report/click_engagement';

const engagementReportConnector = {
  /*    const response = {
        show: true,
        labels: [
          '12:00 PM 7/29', '', '', '',
          '16:00 PM 7/29', '', '', '',
          '20:00 PM 7/29', '', '', '',
          '00:00 PM 7/30', '', '', '',
          '04:00 PM 7/30', '', '', '',
          '08:00 PM 7/30','', '', '',
          '12:00 PM 7/30'
        ],
        data: [0,150,200,140,60,10,5,0,0,20,30,0,
               0,0,0,0,30,0,0,0,0,0,10,0]
      }
  */
  findItems (component, callback, params=null) {
    connector.sendToShopsendClickerApp(component,
    {
      method: 'post',
      url: ENGAGEMENT_URI,
      data: params
    },function (component,response) {
      callback(response.data);
    });



  }
 };
export default engagementReportConnector
