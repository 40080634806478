import connector from './connector'

const CAMPAIGN_AUTOMATION_URI = '/automated_campaign';
const DISCOUNT_CODES_SUMMARY = CAMPAIGN_AUTOMATION_URI +'/discounts';

const campaignAutomationConnector = {

  start (component,automatedCampaign) {
    connector.sendWithFile(component,
    CAMPAIGN_AUTOMATION_URI,
      automatedCampaign,
      "POST",
    function (component,response) {
      component.showResultOfStartAutomation(response.data.response);
    });
  },

  updateStatus(component, campaignId, newStatus){
    connector.send(component,
      {
        method: 'post',
        url: CAMPAIGN_AUTOMATION_URI+ "/"+ campaignId + "/status",
        data: {status: newStatus}
      },function (component,response) {
        component.receiveUpdateStatusResponse(response.data.response);
      },false, null,function (component, error) {
        component.statusRollback(false)
      }
    );
  },

  update (component,automatedCampaign) {
    connector.sendWithFile(component,
      CAMPAIGN_AUTOMATION_URI,
      automatedCampaign,
      "PUT",
      function (component,response) {
        component.showResultOfAction(response.data.response);
      });
  },

  findDiscountCodesInAutomations(component,discounts){
    connector.send(component,
      {
        method: 'get',
        url: DISCOUNT_CODES_SUMMARY,
      },function (component,response) {
        component.receiveDiscountCodesInAutomations(response.data.response,discounts);
      },true
    );
  },

  findCampaignDetailsToEdit(component, campaignId){
    connector.send(component,
      {
        method: 'get',
        url: CAMPAIGN_AUTOMATION_URI+ "/"+ campaignId,
      },function (component,response) {
        component.receiveCampaignToEdit(response.data.response);
      },true
    );
  },
 };
export default campaignAutomationConnector
