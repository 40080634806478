import router from '../router'
import { EventBus } from '../packs/app';
import authConnector from '../connectors/authConnector'
import { BASE_URL } from '../connectors/connector'
import billingConnector from "../connectors/billingConnector";

const auth = {

  getAuthHeader (component) {
    return 'Bearer ' + component.$store.getters.apiKey;
  },
  isLogged (component) {
    return  component.$store.getters.apiKey;
  },
  getUserLogged (component) {
    if(this.isLogged(component)){
      return component.$store.getters.user;
    }else {
      return '';
    }
  },
  //TODO use it!
  userHasTheAbility (component,module,action) {
    return  component.$store.getters.abilities[module].includes(action);
  },
  userRol (component) {
    return component.$store.getters.abilities.rol;
  },

  login (context, creds) {
    authConnector.login(context,creds,this._successfulLogin);
  },

  loginWithLink (context, token) {
    authConnector.loginWithLink(context,token,this._successfulLogin);
  },

  loginWithShopify (context, token) {
    authConnector.loginWithShopify(context,token,this._check_if_is_new_company);
  },

  async _check_if_is_new_company(context, response){
    if(response.data.response.user){
      await this._successfulLogin(context,response)
    }
    else {
      //continue with the company on boarding
     router.push('/on-board/new-costumer/' + response.data.response.company.hashid)
    }
  },

  async _successfulLogin(context, response) {

    let user = response.data.response.user

    if (user.company_plan !== 'inactive') {

      context.$store.commit('setAbilities', response.data.response.abilities);
      context.$store.commit('setApiKey', response.data.response.token);
      context.$store.commit('setItemMenuSelected', 0);
      context.$store.commit('setIconsMenu', ["$dashboardSelected", "$contacts", "$campaigns",'$conversations']);
      context.$store.commit('setUser', user);
      context.$store.commit('setMustShowSuccessPayment', false);
      context.$store.commit('setNewMessages', response.data.response.user.new_messages);

      if (user.first_login || user.reset_pass) {
        router.push('/setup')
      } else {
        if (response.data.response.abilities.rol === 'staff') {
          window.open(BASE_URL + 'admin', '_self')
        } else {
          billingConnector.summary(context)
          router.push('/dashboard')
        }
      }

      this.$cable.unsubscribe("ConversationChannel");
      EventBus.$emit('logIn')
    } else {
      context.enableButton()
      const choice = await context.$confirm(context.$vuetify.lang.t('$vuetify.accountDisable.hi'),
        {
          persistent: false,
          noButtons: true,
          width: 600,
          subtitle: context.$vuetify.lang.t('$vuetify.accountDisable.subtitle'),
          info: context.$vuetify.lang.t('$vuetify.accountDisable.info')
        })
    }
  },

  logOut(context) {
    if (this.isLogged(context)){
      this._closeLocalSession(context);
      authConnector.logOut(context,function () {});
    }
  },

  // To log out, we just need to remove the token
  _closeLocalSession (context) {
    context.$store.commit('setApiKey', null);
    context.$store.commit('setUser', {});
    context.$store.commit('setAbilities', {});
    context.$store.commit('setItemMenuSelected', 0);
    context.$store.commit('setMustShowSuccessPayment', false);
    context.$store.commit('setIconsMenu', ["$dashboardSelected","$contacts", "$campaigns",'$conversations']);
    if(context.$router.currentRoute.path !== '/login') {
      router.push('/login');
    }
    EventBus.$emit('logOut');
  }
};
export default auth
