<template>
  <v-card max-height='27vh' min-height='27vh' class="shopsendBorder mb-4" elevation="0" style="overflow-y: auto;">
    <v-card-title class="justify-center text-center pb-0 pt-4">
      <v-list-item-avatar class="mr-1" color="background" size="80" style="border-style: solid;  border-color: var(--v-error-base) !important;">
        <span class="text-h4 accent--text">{{ getContactInitials()  }}</span>
      </v-list-item-avatar>
    </v-card-title>
    <v-card-title class="text-subtitle-1 justify-center text-center background--text font-weight-bold pt-0">
      {{ contact.name }} {{ contact.last_name }}
    </v-card-title>
    <v-card-subtitle class="text-subtitle-2  justify-center text-center background--text pb-2">
      {{formatPhoneNumber(contact.phone) }}
      <div v-if="contact.email">
        {{contact.email}}
      </div>
      <div v-if="contact.city">
        {{contact.city}} {{contact.state}}, {{contact.country}}
      </div>
    </v-card-subtitle>
  </v-card>
</template>

<script>

  import contactConnector from "../../connectors/contactConnector";
  import shopifyAppConnector from "../../connectors/shopifyAppConnector";

  export default {
    name: "conversations-contact",
    components: {
    },
    props: {
      phone: {
        type: String,
        default: null
      },
    },
    data () {
      return {
        loaded: false,
        contact: {
          name: 'Unknown',
          lastname: null,
          phone: null,
          email: null
        },
        contactsCache: {}
      }
    },
    methods: {
      async reloadContact(){
        // {
        //   "id": 197,
        //     "name": "Unknown",
        //     "phone": "+14243341814",
        //     "email": "yamit@shopsend.io",
        //     "phone_type": "voip",
        //     "carrier": "Google (Grand Central) BWI - Bandwidth.com - SVR",
        //     "from_number": "+15108228816",
        //     "from_provider": "Twilio",
        //     "status": "Active",
        //     "active_on": "2021-11-25T15:29:06.240Z",
        //     "opted_out_on": null,
                //"city",'state',country
        //     "popup_configuration_id": 35,
        //     "list_id": 1,
        //     "company_id": 1
        // }
        this.contact = {
          name: 'Unknown',
          lastname: null,
          phone: null,
          email: null
        }
        let result = this.contactsCache[this.phone]
        if(!result){
          result = await contactConnector.searchContact(this, this.phone)
          this.contactsCache[this.phone] = result
        }

        this.contact = result
      },
      getContactInitials(){
        if(this.contact.name){
          let value = this.contact.name[0].toUpperCase()
          if(this.contact.last_name){
           value += this.contact.last_name[0].toUpperCase()
          }
        return value
        }
        return "U"
      }
    },
    watch: {
      async phone(newValue) {
        if(newValue === null){
          this.contact= {
            name: 'Unknown',
            lastname: null,
            phone: null,
            email: null
          }
        }else{
          await this.reloadContact()
        }
      }
    },
    async mounted(){
      if(this.phone){
        await this.reloadContact()
      }
    }
  }
</script>
<style scoped>

</style>