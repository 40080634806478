import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import en from '../locale/en.ts'

import '../styles/variables.scss'

import dsCampaigns from '../components/icons/ds-campaings'
import dsMarket from '../components/icons/ds-market'
import dsCampaignsSelected from '../components/icons/ds-campaings-selected'
import dsBeta from '../components/icons/ds-beta'
import dsBilling from '../components/icons/ds-billing'
import dsContacts from '../components/icons/ds-contacts'
import dsInfo from '../components/icons/ds-info'
import dsInfoWhite from '../components/icons/ds-info-white'
import dsInfoWhiteTrans from '../components/icons/ds-info-white-trans'
import dsContactsSelected from '../components/icons/ds-contacts-selected'
import dsConversations from '../components/icons/ds-conversations'
import dsConversationsSelected from '../components/icons/ds-conversations-selected'
import dsDashboard from '../components/icons/ds-dashboard'
import dsDashboardSelected from '../components/icons/ds-dashboard-selected'
import dsDocuments from '../components/icons/ds-documents'
import dsNew from '../components/icons/ds-new'
import dsPopup from '../components/icons/ds-popup'
import dsSettings from '../components/icons/ds-settings'
import dsUpgrade from '../components/icons/ds-upgrade'
import dsCustomer from '../components/icons/ds-customer'
import dsLogout from '../components/icons/ds-logut'

Vue.use(Vuetify)

const DRIPSEND_ICONS = {
  campaigns: {component: dsCampaigns },
  market: {component: dsMarket },
  campaignsSelected: {component: dsCampaignsSelected},
  conversations: {component: dsConversations },
  conversationsSelected: {component: dsConversationsSelected },
  contacts: {component: dsContacts },
  info: {component: dsInfo },
  infoWhite: {component: dsInfoWhite },
  infoWhiteTrans: {component: dsInfoWhiteTrans},
  contactsSelected: {component: dsContactsSelected },
  dashboard: {component: dsDashboard },
  dashboardSelected: {component: dsDashboardSelected },
  documents: {component: dsDocuments },
  beta: {component: dsBeta },
  billing: {component: dsBilling },
  newFeature: {component: dsNew },
  popup: {component: dsPopup },
  settings: {component: dsSettings },
  upgrade: {component: dsUpgrade },
  customer: {component: dsCustomer },
  logout: {component: dsLogout },
}

const opts ={
  options: {
    customProperties: true
  },
  theme:   {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#6756FF",
        secondary: "#FFFFFF",
        accent: "#F9F5F1",
        error: "#FF8392",
        warning: "#fffa3b",
        info: "#5C7AFF",
        success: "#52ccb7",
        background: "#495165",
        clearbackground: "#FAFAFA",
        border: "#F3F5FA",
        feedback: "#ffC3BC",
        chip: "#FFAB74",
        shopgray:"#818E95"
      },
      dark: {
        primary: "#357ded",
        secondary: "#323548",
        accent: "#ecf0f1",
        error: "#f44305",
        warning: "#fffa3b",
        info: "#2196f3",
          success: "#52ccb7",
        background: "#8798AD",
        clearbackground: "#f5f8ff",
        feedback: "#ffCCBC",
        chip: "#FFAB74"
      },
    }
  },
  icons: {
    iconfont: 'mdi',
    values: DRIPSEND_ICONS,
  },
  lang: {
    locales: { en },
    current: 'en',
  }
};



export default new Vuetify(opts)