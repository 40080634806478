<template>
  <img :src="dsBeta">
</template>

<script>
  import dsBeta from '../../images/icons/ds-beta.svg'

  export default {
    name: "ds-beta",
    components: {
      dsBeta
    },
    data () {
      return {
        dsBeta: dsBeta,

      }
    }
  }
</script>

<style scoped>

</style>