<template>
  <img :src="dsBilling">
</template>

<script>
  import dsBilling from '../../images/icons/ds-billing.svg'

  export default {
    name: "ds-billing",
    components: {
      dsBilling
    },
    data () {
      return {
        dsBilling: dsBilling,
      }
    }
  }
</script>

<style scoped>

</style>