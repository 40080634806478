<template>
  <secure-content>
    <v-row class="mt-4">
      <v-col class="pl-16">
        <p class="text-h6 text--background text-left mb-0">{{$vuetify.lang.t('$vuetify.newPopup.title')}}</p>
        <p class="text-body-2 text--background text-left">{{$vuetify.lang.t('$vuetify.newPopup.subtitle')}}</p>
      </v-col>

    </v-row>
    <v-row class="d-flex flex-row justify-start ml-16">
      <v-hover v-slot="{ hover }" v-for="(template,idx) in desktopTemplates"  v-bind:key="idx">
        <v-card
            max-width="350"
            class="ma-3 rounded-xl"
            :elevation="hover ? 20 : 6"
            @click="open(template)"
        >
          <v-img
              :src="template.image_path"
              height="225px"
          ></v-img>
          <v-card-subtitle class="pb-0">
            <span class="background--text"><b>{{ template.title }}</b></span>
          </v-card-subtitle>
          <v-card-subtitle class="pt-0 d-flex flex-row text-caption">
            <div>
              {{ template.subtitle }}
            </div>
            <v-spacer></v-spacer>
            <v-icon class="ml-3">mdi-monitor</v-icon>
          </v-card-subtitle>
        </v-card>
      </v-hover>
    </v-row>

    <v-row class="d-flex flex-row justify-start ml-16">
        <v-hover v-slot="{ hover }" v-for="(template,idx) in mobileTemplates"  v-bind:key="idx">
          <v-card
              max-width="350"
              class="ma-3 rounded-xl"
              :elevation="hover ? 16 : 6"
              @click="open(template)"
          >
            <v-img
                :src="template.image_path"
                height="225px"
            ></v-img>
            <v-card-subtitle class="pb-0">
              <span class="background--text"><b>{{ template.title }}</b></span>
            </v-card-subtitle>
            <v-card-subtitle class="pt-0 d-flex flex-row text-caption">
              <div>
                {{ template.subtitle }}
              </div>
              <v-spacer></v-spacer>
              <v-icon class="ml-3">mdi-cellphone</v-icon>
            </v-card-subtitle>
          </v-card>
        </v-hover>
    </v-row>

  </secure-content>
</template>

<script>

import secureContent from '../secure-content.vue'
import popupConnector from "../../connectors/popupConnector"
import router from '../../router/index'

export default {
  name: "new-popup",
  components: {
    secureContent
  },
  data () {
    return {
      desktopTemplates: [],
      mobileTemplates: [],
    }
  },
  methods: {
    open(template) {
      router.push('/popup-editor/new/' + template.id)
    }
  },
  async mounted(){
    let response = await popupConnector.getBaseTemplates(this)
    this.desktopTemplates = response.desktop
    this.mobileTemplates = response.mobile
  }
}
</script>
<style scoped>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>