import connector from './connector'

const TRIGGER_URI = '/trigger';

const triggerConnector = {

  getTriggers (component, includeWelcomeFlow) {
    connector.send(component,
      {
        method: 'get',
        url: TRIGGER_URI+"?include_welcome_flow="+includeWelcomeFlow
      },function (component,response) {
        component.receiveTriggers(response.data.response);
      });
  },

 };
export default triggerConnector
