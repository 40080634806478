<template>
  <div>

    <v-list-item @click="visibleForm = true">
      <v-list-item-title class="background--text">
        {{ $vuetify.lang.t('$vuetify.conversations.editModes.optionName') }}
      </v-list-item-title>
    </v-list-item>

    <v-dialog v-model="visibleForm"
              width="80vw" height="80vh" persistent>
      <v-card>

        <v-toolbar flat style="height: 32px;">
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <br>
        <v-card-subtitle class="text-none text-h5 text-center py-0 px-16 background--text">
          <b>{{ $vuetify.lang.t('$vuetify.conversations.editModes.title') }}</b>
        </v-card-subtitle>

        <v-card-subtitle class="text-none text-body-2 text-center pt-0 px-16 background--text">
          {{ $vuetify.lang.t('$vuetify.conversations.editModes.subtitle') }}
        </v-card-subtitle>
        <br>
        <v-card-text>
          <ValidationObserver :disabled="!visibleForm" ref="vModes" v-slot="{ validate, reset }">
            <v-container >
              <v-row class="px-16 mx-16">
                <v-col>
                    <p class="text-caption text-left mb-2">
                      <v-badge class="mr-2"
                               inline
                               left
                               dot
                               color="success"
                      />
                      <span class="text-body-2 text--background">
                        {{$vuetify.lang.t('$vuetify.conversations.editModes.status')}} |
                      </span>
                      <span class="text-body-2 font-weight-bold text--background">
                        {{$vuetify.lang.t('$vuetify.conversations.editModes.active')}}
                      </span>
                    </p>
                  <p class="text-caption text-left mb-2">
                    <span class="text-body-2 text--background">
                        {{$vuetify.lang.t('$vuetify.conversations.editModes.activeSub')}}
                    </span>
                  </p>
                    <v-text-field
                        class="background--text"
                        outlined
                        value="No autoreplay"
                        disabled
                    />
                </v-col>
              </v-row>
              <v-row class="px-16 mx-16">
                <v-col>
                  <ValidationProvider v-slot="{ errors }" name="inactive message" :rules="{ required: true, min: 5, max: 160, not_url: true}">
                    <p class="text-caption text-left mb-2">
                      <v-badge class="mr-2"
                               inline
                               left
                               dot
                               color="#FFED8D"
                      />
                      <span class="text-body-2 text--background">
                        {{$vuetify.lang.t('$vuetify.conversations.editModes.status')}} |
                      </span>
                      <span class="text-body-2 font-weight-bold text--background">
                        {{$vuetify.lang.t('$vuetify.conversations.editModes.inactive')}}
                      </span>
                    </p>
                    <p class="text-caption text-left mb-2">
                    <span class="text-body-2 text--background">
                        {{$vuetify.lang.t('$vuetify.conversations.editModes.inactiveSub')}}
                    </span>
                    </p>
                    <v-textarea
                        class="background--text"
                        required
                        outlined
                        counter="160"
                        background-color="white"
                        rows="3"
                        v-model="inactiveMessage"
                        :placeholder="$vuetify.lang.t('$vuetify.conversations.editModes.placeholderInactive')"
                        :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row class="px-16 mx-16">
                <v-col>
                  <ValidationProvider v-slot="{ errors }" name="away message" :rules="{ required: true, min: 5, max: 160, not_url: true }">
                    <p class="text-caption text-left mb-2">
                      <v-badge class="mr-2"
                               inline
                               left
                               dot
                               color="#FF8392"
                      />
                      <span class="text-body-2 text--background">
                        {{$vuetify.lang.t('$vuetify.conversations.editModes.status')}} |
                      </span>
                      <span class="text-body-2 font-weight-bold text--background">
                        {{$vuetify.lang.t('$vuetify.conversations.editModes.away')}}
                      </span>
                    </p>
                    <p class="text-caption text-left mb-2">
                    <span class="text-body-2 text--background">
                        {{$vuetify.lang.t('$vuetify.conversations.editModes.awaySub')}}
                    </span>
                    </p>
                    <v-textarea
                        class="background--text"
                        required
                        outlined
                        counter="160"
                        background-color="white"
                        rows="3"
                        v-model="awayMessage"
                        :placeholder="$vuetify.lang.t('$vuetify.conversations.editModes.placeholderAway')"
                        :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </ValidationObserver>
        </v-card-text>


        <v-card-actions class="justify-center pb-12">
          <v-row class="px-16 mx-16">
            <v-col>
              <v-btn color="primary" @click="saveModes()" :loading="loading" block>
                <v-icon>mdi-lock</v-icon>
                {{$vuetify.lang.t('$vuetify.conversations.editModes.save')}}
              </v-btn>
            </v-col>
          </v-row>


        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>

  import customDatetime from "../base/customDatetime";
  import conversationConnector from "../../connectors/conversationConnector";
  import {EventBus} from "../../packs/app";

  export default {
    name: "conversations-edit-mode",
    components: {
      customDatetime,
    },
    props: {
      awayMessage: {
        type: String,
        default: null//archived
      },
      inactiveMessage: {
        type: String,
        default: null//archived
      },
    },
    watch: {

    },
    data () {
      return {
        visibleForm: false,
        loading: false,
      }
    },
    mounted () {

    },
    destroyed () {
    },
    methods: {
      close() {
        this.visibleForm = false
      },
      async saveModes(){
        let result = await this.$refs.vModes.validate()

        if (result) {
          this.loading = true
          result = await conversationConnector.updateConfiguration(this,{
            inactive_message: this.inactiveMessage,
            away_message: this.awayMessage
          })

          EventBus.$emit('flash-message', {
            text: "Message configurations has been updated",
            color: 'info',
            textColor: 'white',
            outlined: false
          });

          this.loading = false
          this.close()
        }
      },

      handleFail(error){
        this.loading = false
      },

    }
  }
</script>

<style scoped>
  .v-card__text, .v-card__title {
    word-break: normal; /* maybe !important  */
  }
</style>