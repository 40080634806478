<template>
  <ValidationObserver ref="vChange" v-slot="{ validate, reset }">
    <v-form  @keyup.native.enter="keyPress($event)" type="" class="pl-16 pr-16" autocomplete="on">
      <div class="pt-8" >
        <p class="text-subtitle-1 pl-4 text--background text-start" v-html="$vuetify.lang.t('$vuetify.emailService.welcome')"></p>
      </div>
      <v-card-text class="text-subtitle-1 text--background">

        <p class="text-subtitle-1 text--background text-start mb-0">{{$vuetify.lang.t('$vuetify.emailService.platform')}}</p>
        <v-item-group mandatory class="pb-12">
          <v-row>
            <v-col
                v-for="service in services"
                :key="service.name"
                cols="12"
                md="3"
            >
              <v-item v-slot="{ active, toggle }">
                <v-hover v-slot="{ hover }">
                  <v-card
                      :class="defineClass(active,hover)"
                      height="150"
                      @click="setActive(service.name,toggle)"
                  >
                    <div class='flex-grow-1 text-center'>
                      <img :src="service.image" :alt="service.name" width="180px" height="60px">
                    </div>
                  </v-card>
                </v-hover>
              </v-item>

            </v-col>
          </v-row>
        </v-item-group>

        <div v-if="active === 'Klaviyo'">
            <p class="text-subtitle-1 text--background text-start mb-0">{{$vuetify.lang.t('$vuetify.emailService.key')}}</p>
            <v-row>
              <v-col cols="7">
                <ValidationProvider v-slot="{ errors }" name="Api key" vid="api" rules="required">
                  <v-text-field name="api" outlined dense autocomplete="on"
                                id="api" v-model="apiKey" placeholder="pk_xxxxxxxxxxxxxxxxxxx"
                                :error-messages="errors"
                                type="text"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

            <p class="text-subtitle-1 text--background text-start mb-0">{{$vuetify.lang.t('$vuetify.emailService.lists')}}</p>
            <v-row>
              <v-col cols="7">
                <ValidationProvider v-slot="{ errors }" name="list" vid="list" rules="required">
                  <v-text-field name="list" outlined dense autocomplete="on"
                                id="list" v-model="lists" placeholder="New Subscribers"
                                :error-messages="errors"
                                type="text"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
        </div>

        <div v-if="active === 'Omnisend'">
            <p class="text-subtitle-1 text--background text-start mb-0">{{$vuetify.lang.t('$vuetify.emailService.key')}}</p>
            <v-row>
              <v-col cols="7">
                <ValidationProvider v-slot="{ errors }" name="Api key" vid="api" rules="required">
                  <v-text-field name="api" outlined dense autocomplete="on"
                                id="api" v-model="apiKey" placeholder="pk_xxxxxxxxxxxxxxxxxxx"
                                :error-messages="errors"
                                type="text"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
        </div>


      </v-card-text>
      <v-card-actions>
        <v-btn right color="primary" @click="sendData()"  :disabled='buttonDisable'>
          <div class="pa-16">
            {{$vuetify.lang.t('$vuetify.emailService.continue')}}
          </div>
        </v-btn>

        <v-btn right plain @click="skip()">
          <div class="pa-16">
            {{$vuetify.lang.t('$vuetify.emailService.skip')}}
          </div>
        </v-btn>

      </v-card-actions>

    </v-form>
  </ValidationObserver>


</template>

<script>


  import imgOmni from '../../images/omnisend-logo.png'
  import imgKlaviyo from '../../images/klaviyo-logo.png'
  import CompanyConnector from "../../connectors/companyConnector";

  export default {
    name: "email-service",
    components: {
    },
    props: {
      companyHashId: {
        type: String,
        default: null
      },
    },
    data () {
      return {
        apiKey: '',
        lists: '',
        onBoardingStep: 4,
        active: 'Klaviyo',
        services: [
          {
            image: imgKlaviyo,
            name: 'Klaviyo'
          },
         {
            image: imgOmni,
            name: 'Omnisend'
         },
        ],
        buttonDisable: false
      }
    },
    methods: {
      setActive(serviceName,toggle){
        this.active = serviceName
        toggle()
      },
      async sendData(){
        if(this.$refs.vChange){
          let result= await this.$refs.vChange.validate()
          if (result) {
            this.buttonDisable = true
            if(this.$route.path.includes('/on-board/new-costumer')){
              await CompanyConnector.updateCompany(this, {
                apiKey: this.apiKey,
                lists: this.lists,
                emailService: this.active,
                onBoardingStep: this.onBoardingStep
              }) //returns company
              this.$router.go()
              this.cleanData()
            }else {
              // put here code for edit company inside app
            }


          }
        }
      },
      cleanData(){
        this.apiKey = ''
        this.lists = ''
        this.$refs.vChange.reset()
        this.buttonDisable = false
      },
      async skip(){
        await CompanyConnector.updateCompany(this, {
          onBoardingStep: this.onBoardingStep
        }) //returns company
        this.$router.go()
        this.cleanData()
      },
      defineClass(active, hover) {
        let finalClasses = 'd-flex align-center'
        if (active) {
          finalClasses += ' selected'
        }else {
          if(hover) {
            finalClasses += ' hoverEffect'
          }
        }

        return finalClasses
      },
      keyPress (event) {
        event.preventDefault()
        event.stopPropagation()
        this.updateUser()
      }
    },
    mounted() {
    }
  }
</script>

<style scoped>
  .selected {
    border: 4px solid var(--v-primary-base) !important
  }

  .hoverEffect {
    border: 4px solid var(--v-primary-lighten4) !important
  }
</style>