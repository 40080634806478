<template>
    <v-row cols="12" class="pl-10 pr-10">
      <v-col justify="center" align="center" class="pa-0 mx-2 background--text"
             v-for="(key,idx) in Object.keys(items)"
             :key="idx"
             cols="2"
      >
        <v-card class="shopsendElevationTable"
          outlined
          tile
        >
          <v-list-item :class="colorStyle+' pa-2'">
            <v-list-item-content>
              <v-list-item-title :class="fontSizeClass[fontSize].title+ ' ' + fontColorClass[colorStyle]">
                {{processText(key,items[key])}}
              </v-list-item-title>
              <v-list-item-subtitle :class="fontSizeClass[fontSize].subtitle + ' ' + fontColorClass[colorStyle]">
                {{ $vuetify.lang.t("$vuetify.summary.fields." + key) }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-btn v-if="showHelp"
                icon
                absolute
                top
                right
                :ripple="false"
                class="pl-16 pb-8"
            >
              <v-tooltip top color="background">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small
                          v-bind="attrs"
                          v-on="on">

                    $vuetify.icons.infoWhiteTrans
                  </v-icon>
                </template>
                <div class="text-caption accent--text" v-html="$vuetify.lang.t('$vuetify.summary.fields.help.' + key)"/>
              </v-tooltip>
            </v-btn>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
</template>

<script>

  export default {
    name: "summary-view",
    props: {
      dataSource: {
        type: Object,
        default: null
      },
      parameters: {
        type: Object,
        default: null
      },
      data: {
        type: Object,
        default: null
      },
      showHelp: {
        type: Boolean,
        default: false
      },
      fontSize: {
        type: String,
        default: "regular"
      },
      colorStyle: {
        type: String,
        default: "whiteStyle"
      }
    },
    components: {
    },
    data () {
      return {
        items: {},
        fontSizeClass: {
          regular: {
            title: "headline",
            subtitle: ""
          },
          small: {
            title: "text-subtitle-1 pt-3 pb-2",
            subtitle: "text-caption pb-2"
          }
        },
        fontColorClass: {
          whiteStyle: "background--text",
          primaryStyle: "secondary--text"
        },
        kindOfField:{
          msg_delivered: 'number',
          avg_clicked: 'percentage',
          avg_opted_out: 'percentage',
          Active: 'number',
          OptedOut: 'number',
          PreOptedIn: "number",
          total_contacts: "number",
          grossSales: "money",
          orders: "text",
          aov:"money",
          rpm: "money"
        }
      }
    },
    methods: {
      processText(key, text){

        let type = this.kindOfField[key]

        if(type ==='percentage'){
          return text +'%'
        }else if(type ==='money'){
         return this.formatMoney(text)
        }else if(type === 'number') {
          return this.numberWithCommas(text)
        }else{
          return text
        }
      },
      numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      findItems() {
        if(this.dataSource) {
          this.dataSource.findItems(this,this.receiveItems, this.parameters)
        }else if(this.data){
          this.receiveItems(this.data)
        }
      },
      receiveItems(response) {
        this.items = response
      }
    },
    mounted(){
      this.findItems()
    }
  }
</script>
<style scoped>
.primaryStyle {
  margin: 5px;
  border-radius: 4px;
  background: var(--v-primary-base);
  color: white;
}
.primaryStyle:hover {
  background: var(--v-primary-base);
}
.primaryStyle:active {
  background: var(--v-primary-base);
}

.whiteStyle {
  margin: 5px;
  border-radius: 4px;
  background: white;
}
.whiteStyle:hover {
  background: white;
}
.whiteStyle:active {
  background: white;
}

.v-btn::before {
  background-color: transparent;
}


</style>