<template>
  <div>
    <v-btn color="accent" class="background--text" @click="visibleForm = true">{{ $vuetify.lang.t('$vuetify.campaign.create') }}</v-btn>

    <v-dialog v-model="visibleForm"
              width="600" height="376" persistent @keydown.esc="choose(null)">

      <v-card>

        <v-toolbar flat style="height: 32px;">
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn icon @click="visibleForm = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-title class="text-none text-h5 text-center pt-0 px-16 background--text">
          {{ $vuetify.lang.t('$vuetify.createCampaign.title') }}
        </v-card-title>

        <v-card-actions class="justify-center pb-12">
          <v-hover
              v-slot="{ hover }"
          >
            <v-sheet
                color="white"
                :elevation="hover ? 12 : 2"
                outlined
                width="240"
                height="208"
                rounded
                class="mr-8 pa-2"
                @click="choose('broadcast')"
            >

              <v-card-title class="justify-center text-center">
                <v-img :src="dsOneOff" width="64" height="64" contain/>
              </v-card-title>
              <v-card-title class="text-h6 justify-center text-center background--text">{{ $vuetify.lang.t('$vuetify.createCampaign.scheduled') }}</v-card-title>
              <v-card-subtitle class="text-caption text-center background--text">{{ $vuetify.lang.t('$vuetify.createCampaign.subScheduled') }}</v-card-subtitle>
            </v-sheet>
          </v-hover>

          <v-hover
              v-slot="{ hover }"
          >
            <v-sheet
                color="white"
                :elevation="hover ? 12 : 2"
                outlined
                width="240"
                height="208"
                rounded
                class="mr-4 pa-2"
                @click="choose('automation')"
            >

              <v-card-title class="justify-center text-center">
                <v-img :src="dsAutomated" width="64" height="64" contain/>
              </v-card-title>
              <v-card-title class="text-h6 justify-center text-center background--text">{{ $vuetify.lang.t('$vuetify.createCampaign.automated') }}</v-card-title>
              <v-card-subtitle class="text-caption text-center background--text">{{ $vuetify.lang.t('$vuetify.createCampaign.subAutomated') }}</v-card-subtitle>
            </v-sheet>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>

  import dsAutomated from '../../images/automated.svg'
  import dsOneOff from '../../images/one-off.svg'
  import router from "../../router";

  export default {
    name: "create-campaign-selector",
    components: {
      dsAutomated,
      dsOneOff
    },
    props: {

    },
    data () {
      return {
        visibleForm: false,
        dsAutomated: dsAutomated,
        dsOneOff: dsOneOff
      }
    },
    mounted () {
      document.addEventListener('keyup', this.onEnterPressed)
    },
    destroyed () {
      document.removeEventListener('keyup', this.onEnterPressed)
    },
    methods: {
      onEnterPressed (e) {
        if (e.keyCode === 13) {
          e.stopPropagation()
          this.choose(null)
        }
      },
      async choose (value) {
        if(value!== null){
          if(this.$route.name === 'edit-campaign'){
            await router.push('/campaigns');
          }
          router.push('/create-campaign/'+value);
        }
        this.visibleForm = false
      },
    }
  }
</script>

<style scoped>
  .v-card__text, .v-card__title {
    word-break: normal; /* maybe !important  */
  }
</style>