<template>
  <v-row>
    <v-col cols="8" class="py-0 mt-4">
      <ValidationProvider v-slot="{ errors }" name="list" rules="required">
        <p class="text-caption text--background text-left mb-2">
          <span class="text-body-2 font-weight-bold text--background"> {{$vuetify.lang.t('$vuetify.campaign.lists')}}</span>
        </p>
        <v-select
            :items="lists"
            class="background--text"
            item-text="name"
            item-value="type_id"
            required
            outlined
            background-color="white"
            return-object
            v-model="internList"
            ref="selector"
            @change="emitChanges"
            :error-messages="errors"
        >
<!--          v-if="getUserLogged().email.includes('@shopsend.io')"-->
          <template v-slot:prepend-item>
            <v-list-item
                ripple
            >
              <v-list-item-content>
                <create-segment/>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
      </ValidationProvider>

    </v-col>

    <v-col cols="4" class=" mt-4 mb-0 py-10">
      <v-chip color="success">
        Estimated {{ internList.number_of_contacts }} Contacts
      </v-chip>

    </v-col>
  </v-row>

</template>

<script>

  import listConnector from "../../connectors/ListConnector";

  import createSegment from "./create-segment"
  import auth from "../../models/auth";
  import {EventBus} from "../../packs/app";

  export default {
    name: "list-segment-selector",
    components: {
      createSegment
    },
    props: {
      list: {
        type: Object,
        default:{}
      }
    },
    data () {
      return {
        lists: [],
        internList: this.list
      }
    },
    mounted () {
      listConnector.findCompanyListsAndSegments(this)

      EventBus.$on('createdNewSegment', (newSegment) => {
        this.lists.push(newSegment)
        this.internList  = newSegment
        if(this.$refs.selector) {
          this.$refs.selector.blur();
        }
        this.emitChanges()
      });

      EventBus.$on('update-list-or-segment', (data) => {
        this.internList  = data.message
        this.emitChanges()
      });
    },
    destroyed () {
    },
    methods: {
      receiveCompanyList(response) {

        response.splice(0,0,{ header: 'LISTS' })
        let i=0
        for (; i<response.length; i++) {
          if (response[i].type_id && response[i].type_id.includes('segment')) break;
        }
        response.splice(i,0,{ divider: true },{ header: 'DYNAMIC SEGMENTS' })
        this.lists = response

        this.internList  = response[1]
        this.emitChanges()
      },
      emitChanges() {
        this.$emit('update:list', this.internList)
      },
      getUserLogged () {
        return auth.getUserLogged(this);
      },
    }
  }
</script>

<style scoped>

</style>