import connector from './connector'

const DOCUMENT_URI = '/document';

const documentConnector = {

  findCompliance(component) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'get',
            url: DOCUMENT_URI + '/compliance'
          },function (component,response) {
            resolve(response.data.response);
          },false,
          null,
          function (component, error) {
            console.log(error.data.message)
          });
      }
    );
  },
 };
export default documentConnector
