<template>
  <line-chart
    v-if="loaded && show"
    :labels="labels"
    :data="data"
    title="24-Hours Engagement"
  />

</template>


<script>

  import lineChart from '../base/lineChart'
  import engagementReportConnector from '../../connectors/reports/engagementReportConnector'

  export default {
    name: "engagement-chart",
    props: {
      links: {
        type: Array,
        default: []
      },
      scheduledTime: {
        type: String,
        default: ''
      },
    },
    components: {
      lineChart
    },
    data () {
      return {
        loaded:false,
        show: false,
        data: [],
        labels: []
      }
    },
    mounted () {
      engagementReportConnector.findItems(this,this.receiveReport,{links: this.links, scheduled_time: this.scheduledTime})

    },
    methods: {
      receiveReport(result) {
        this.data = result.data
        this.labels = result.labels
        this.show = result.show
        this.loaded = true
      }
    }
  }
</script>

<style scoped>

</style>