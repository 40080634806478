<template>
  <div id="secure-content">
    <app-header v-if="showHeader"></app-header>
    <v-main :class="removePadding ? 'clearbackground pt-0 pl-0': 'clearbackground pt-5 pl-0'" >
      <v-container fluid >
        <v-layout>
          <v-flex>
            <div v-if="!isLogged()">
              <v-alert
                      :value="true"
                      type="error"
              >
                {{ $vuetify.lang.t('$vuetify.auth.error') }}
                {{  redirectToLogin() }}
              </v-alert>
            </div>
            <div v-else>
              <v-row>
                <v-col v-if='showBack' cols="auto" :class='removePadding ? "pr-0 pl-12 pt-5" : "pr-0 pl-12 pt-0"' >
                  <v-btn class="mt-4" icon elevation='4' @click="back()">
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col :class='showBack ? (removePadding ? "pl-0 pr-0 pt-0 pb-0": "pl-0 pr-15 pt-0 pb-0"): "pr-0 pt-0 pb-0" '>
                  <slot></slot>
                </v-col>
              </v-row>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <!-- <app-footer></app-footer> -->
  </div>
</template>

<script>
  import auth from '../models/auth'
  import appHeader from './app-header.vue'
  import appFooter from "./app-footer";
  import router from "../router";
  import {EventBus} from "../packs/app";
  import billingConnector from "../connectors/billingConnector";

  export default {
    props: {
      showHeader: {
        type: Boolean,
        default: true
      },
      showBack: {
        type: Boolean,
        default: true
      },
      removePadding: {
        type: Boolean,
        default: false
      },
      checkForUserLogged: {
        type: Boolean,
        default: true
      },
    },
    components: {
      appFooter,
      appHeader
    },
    name: "secure-content",
    data () {
      return {
      }
    },
    channels: {
      ConversationChannel: {
        connected() {
          console.log('con');
        },
        rejected() {
          console.log('rej');
        },
        disconnected() {
          console.log('dis');
        },
        received(data) {
          if(data.event.company_name === this.$store.getters.company.name){
            if(data.event.type === 'message'){
              EventBus.$emit('new-message-in-conversations',data.event.content)
            }else if (data.event.type === 'remove_conversation'){
              console.log("ws remove conversation", data.event.content)
            }else if (data.event.type === 'payment_completed'){
              EventBus.$emit('payment_was_completed',{
                content: data.event.content
              })
            }else if (data.event.type === 'plan_changed'){
              let user = this.$store.getters.user
              user.company_plan = data.event.content.new_plan
              this.$store.commit('setUser', user);
              billingConnector.summary(this)
            }else {//conversation
              EventBus.$emit('new-conversation',{
                contentType: "Active",
                conversation: data.event.content
              })
            }
          }
        }
      }
    },
    async mounted() {

      console.log(this.$cable._cable.subscriptions.subscriptions)
      if(this.$cable._cable.subscriptions.subscriptions.length === 0){ //prevent multiple subscriptions
        this.$cable.subscribe({
          channel: 'ConversationChannel'
        });
      }
      if (this.$store.getters.mustShowSuccessPayment) {
        const clientSecret = new URLSearchParams(window.location.search).get(
            'setup_intent_client_secret'
        );
        if(clientSecret){
          await this.$plans(this, {showSuccess: true})
        }

      }
    },
    methods: {

      redirectToLogin(){
        setTimeout(async () => {
          router.push('/login')
        }, 2000)

      },
      isLogged () {
        if(this.checkForUserLogged){
          return auth.isLogged(this)
        }else{
          return true
        }

      },
      back() {
        router.back()
      },
    }
  }
</script>

<style scoped>

</style>