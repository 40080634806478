<template>
  <img :src="dsUpgrade">
</template>

<script>
  import dsUpgrade from '../../images/icons/ds-upgrade.svg'

  export default {
    name: "ds-upgrade",
    components: {
      dsUpgrade
    },
    data () {
      return {
        dsUpgrade: dsUpgrade,

      }
    }
  }
</script>

<style scoped>

</style>