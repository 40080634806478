<template>
    <v-row >
      <v-col cols="12" sm="7" :class="preselectedTrigger ? 'pt-6 pl-10': 'pt-13 pl-10'">

        <p class="text-h6 text--background text-left mb-0" v-if="!preselectedTrigger">{{$vuetify.lang.t('$vuetify.automatedCampaign.title')}}</p>
        <p class="text-body-2 text--background text-left" v-if="!preselectedTrigger">{{$vuetify.lang.t('$vuetify.automatedCampaign.subtitle')}}</p>

        <div v-if="viewMode">
          <p class="text-h6 text--background text-left mb-0">{{$vuetify.lang.t('$vuetify.automatedCampaign.viewModeTitle')}}</p>
          <p class="text-body-2 text--background text-left">{{$vuetify.lang.t('$vuetify.automatedCampaign.viewModeSubtitle')}}</p>
        </div>

        <ValidationObserver ref="vCampaign" v-slot="{ validate, reset }">
          <v-container>
            <v-row class="mt-4">
              <v-col cols="11" class="py-0" v-if="!preselectedTrigger">
                <ValidationProvider v-slot="{ errors }" name="trigger" rules="required">
                  <p class="text-caption text-left mb-2">
                    <span class="text-body-2 font-weight-bold text--background"> {{$vuetify.lang.t('$vuetify.automatedCampaign.trigger')}} |</span>
                    {{$vuetify.lang.t('$vuetify.automatedCampaign.subTrigger')}}
                  </p>
                  <v-select
                      :items="triggers"
                      item-text="name"
                      return-object
                      item-disabled="disabled"
                      background-color="white"
                      required
                      outlined
                      v-model="automatedCampaign.trigger"
                      :error-messages="errors"
                      @change = "emitTriggerChange()"
                  >
                    <template v-slot:selection="{ item, index }">
                      <img :src="item.icon"> <span class="background--text"> {{ item.name }}</span>
                    </template>
                    <template v-slot:item="{ item }">
                      <img :src="item.icon"> <span> {{ item.name }}</span>
                    </template>

                  </v-select>
                </ValidationProvider>
              </v-col>

              <v-col cols="11" class="py-0" v-if="automatedCampaign.trigger.options && !viewMode">
                  <ValidationProvider v-slot="{ errors }" name="Automation name" :rules="{ required: true, min: 5, regexChar: /^[\w\s]+$/g }">
                    <p class="text-caption text-left mb-2">
                      <span class="text-body-2 font-weight-bold text--background"> {{$vuetify.lang.t('$vuetify.automatedCampaign.name')}}</span>
                    </p>
                    <v-text-field
                        class="background--text"
                        required
                        outlined
                        counter="60"
                        background-color="white"
                        v-model="automatedCampaign.name"
                        :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

              <v-col cols="11" class="py-0" v-if="automatedCampaign.trigger.options && !viewMode">
                <p class="text-caption text--background text-left mb-2">
                  <span class="text-body-2 font-weight-bold text--background"> {{ getOptionsMessage()}} |</span>
                  {{getSubOptionsMessage()}}
                  <v-tooltip top color="background" v-if="automatedCampaign.trigger.id === 'Welcome-Campaign-automation'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          color="background"
                          dark
                          v-bind="attrs"
                          v-on="on"
                      >
                        $vuetify.icons.info
                      </v-icon>
                    </template>
                    <div class="text-caption accent--text" v-html="$vuetify.lang.t('$vuetify.automatedCampaign.optedInHelp')"/>
                  </v-tooltip>
                </p>
                <ValidationProvider v-slot="{ errors }" name="option" rules="requiredNotEmpty">
                  <v-select
                      :items="automatedCampaign.trigger.options"
                      item-text="name"
                      return-object
                      required
                      outlined
                      background-color="white"
                      v-model="automatedCampaign.option"
                      :error-messages="errors"
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>

              <v-col cols="11" class="py-0" v-if="!automatedCampaign.trigger.empty && !viewMode">
                <ValidationProvider v-slot="{ errors }" name="MMS Image" :rules="typeof automatedCampaign.media === 'string' ? null: 'size:600|maxdimensions:610,810'">
                  <p class="text-caption text--background text-left mb-2">
                    <span class="text-body-2 font-weight-bold text--background"> {{$vuetify.lang.t('$vuetify.automatedCampaign.mms')}}</span>
                  </p>
                  <v-file-input
                          show-size
                          accept="image/png, image/jpeg, image/gif"
                          class="background--text"
                          v-model="automatedCampaign.media"
                          chips
                          outlined
                          background-color="white"
                          :error-messages="errors"
                          @change="updateRemoveFileFlag()"
                  ></v-file-input>
                </ValidationProvider>
              </v-col>

              <v-col cols="11" class="py-0" v-if="automatedCampaign.trigger.tags && !viewMode">

                  <sms-editor
                      :title="$vuetify.lang.t('$vuetify.automatedCampaign.sms')"
                      :trigger.sync="automatedCampaign.trigger.id"
                      :message.sync="automatedCampaign.sms"
                      :tags.sync="automatedCampaign.trigger.tags"
                      :discountCode.sync="automatedCampaign.discountCode"
                      :messageEstimatedSegment.sync="automatedCampaign.messageEstimatedSegment"
                      :show-note="automatedCampaign.trigger.id === 'Welcome-Campaign-automation'"
                  />

              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>

        <div class="align-right" v-if="!automatedCampaign.trigger.empty && !viewMode">
          <v-item-group class="v-btn-toggle">
            <v-btn  color="primary"
                    @click="startAutomation()"
                    :disabled='buttonDisable'>
              <div v-if="preselectedTrigger">
<!--                this must change if we have other preselected automation-->
                {{this.$vuetify.lang.t('$vuetify.automatedCampaign.saveWelcome')}}
              </div>
              <div v-else>
                {{this.$vuetify.lang.t('$vuetify.automatedCampaign.start')}}
              </div>

            </v-btn>
            <v-menu bottom left offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                    color="primary"
                    v-on="on"
                    :disabled='buttonDisable'
                >
                  <v-icon >mdi-menu-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <send-test/>
              </v-list>
            </v-menu>
          </v-item-group>
        </div>
       
      </v-col>
      <v-col cols="5">
      <messageViewer class="pt-16"
                     :sms.sync="automatedCampaign.sms"
                     :media.sync="automatedCampaign.media"
                     :previousMessages.sync="automatedCampaign.option.previousMessages"
                     :discountCode.sync="automatedCampaign.discountCode"
                     :messageEstimatedSegment.sync="automatedCampaign.messageEstimatedSegment"
                     :show-estimates="false"/>

      </v-col>

    </v-row>
</template>

<script>

  import {EventBus} from "../../packs/app";
  import campaignAutomationConnector from "../../connectors/campaignAutomationConnector";
  import triggerConnector from "../../connectors/triggerConnector";

  import shopifyLogo from "../../images/shopify-logo.svg"
  import shopsendLogo from "../../images/shopsend-logo.svg"
  import secureContent from '../secure-content.vue'
  import messageViewer from './message-viewer'
  import smsEditor from "../base/smsEditor"
  import router from "../../router"
  import sendTest from "./send-test"
  import discounts from "../../models/discounts"
  import campaignConnector from "../../connectors/campaignConnector";

  export default {
    name: "create-automated-campaign-form",
    components: {
      secureContent,
      smsEditor,
      messageViewer,
      shopifyLogo,
      shopsendLogo,
      sendTest
    },
    props: {
      show: true,
      preselectedTrigger: {//Welcome-Campaign-automation
        type: String,
        default: null
      },
      popupId: {
        type: Number,
        default: null
      },
      campaignIdToEdit: {
        type: Number|String,
        default: null
      },
    },
    data() {
      return {
        buttonDisable: false,
        automatedCampaign: {
          trigger: {empty:true},
          name: '',
          option: {},
          media: [],
          sms: '',
          removeMedia: false,
          discountCode: null,
          messageEstimatedSegment: 1
        },
        viewMode: false,
        smsProcessed: '',
        windowHeight: 10,
        triggers: []
      }
    },
    mounted(){
       if(this.campaignIdToEdit) {
         campaignConnector.findCampaignDetailsToEdit(this, this.campaignIdToEdit)
         this.viewMode = true
       }
       if(!this.viewMode) {
         const includeWelcomeFlow = this.preselectedTrigger !== null
         triggerConnector.getTriggers(this, includeWelcomeFlow)
       }

      EventBus.$on('send-test', async (data) => {
        let campaign = await this.prepareCampaignToSave(true)
        if(campaign) {
          campaign.newContact = data.newContact
          campaign.phone = data.phone
          campaign.email = data.email
          campaignConnector.sendTestCampaign(this, campaign)
        }else {
          EventBus.$emit('flash-message', {
            text: "Please review the campaign details before sent a test message.",
            color: 'info',
            textColor: 'white',
            outlined: false
          });
        }
      });
    },
    methods: {
      emitTriggerChange(){
        this.automatedCampaign.option = {}
        EventBus.$emit('trigger-changed', {trigger: this.automatedCampaign.trigger.id});
      },
      receiveTriggers(triggers) {
        for(let i=0; i<triggers.length ;i++){
          if(triggers[i].icon === "shopify") {
            triggers[i].icon = shopifyLogo
          }else if(triggers[i].icon === "shopsend"){
            triggers[i].icon = shopsendLogo
          }
          //preload the trigger preselected
          if(this.preselectedTrigger && this.preselectedTrigger === triggers[i].id){
            this.automatedCampaign.trigger = triggers[i]
            EventBus.$emit('trigger-changed', {trigger: this.automatedCampaign.trigger.id});
          }
        }
        this.triggers = triggers
      },

      async prepareCampaignToSave(isTest){

        let result = await this.$refs.vCampaign.validate()

        if (result) {
          let cloneAutomatedCampaign = { ...this.automatedCampaign };

          if (typeof cloneAutomatedCampaign.media === 'string'  && !isTest){
            cloneAutomatedCampaign.media = null
          }

          cloneAutomatedCampaign.trigger = cloneAutomatedCampaign.trigger.id
          cloneAutomatedCampaign.option = cloneAutomatedCampaign.option.id

          if(cloneAutomatedCampaign.discountCode === null){
            delete cloneAutomatedCampaign.discountCode;
          }

          cloneAutomatedCampaign.automation = true
          return cloneAutomatedCampaign

        }else {
          return null
        }
      },

      async startAutomation() {

        let result= await this.$refs.vCampaign.validate()
        if (result) {

          let discountsFounded = await discounts.validateDiscountHardcoded(this.automatedCampaign.sms,this, this.automatedCampaign.discountCode)
          if(discountsFounded === false){
            return
          }

          let choice
          if(this.automatedCampaign.trigger.disabled) {
            choice = await this.$confirm(this.$vuetify.lang.t('$vuetify.campaignActions.toggleOn.message'),
                {
                  subtitle: this.$vuetify.lang.t('$vuetify.campaignActions.toggleOn.subtitle'),
                  buttonFalseText: this.$vuetify.lang.t('$vuetify.campaignActions.toggleOn.cancel'),
                  buttonTrueText: this.$vuetify.lang.t('$vuetify.campaignActions.toggleOn.ok'),
                  width: 650
                })
          }else{
            choice  = true
          }

          if (choice){

            this.buttonDisable = true
            let cloneAutomatedCampaign = { ...this.automatedCampaign };

            if (typeof cloneAutomatedCampaign.media === 'string'){
              cloneAutomatedCampaign.media = null
            }

            // if(this.$route.params.campaignId) {
            //   campaignConnector.update(this, cloneCampaign)
            // }else {
            cloneAutomatedCampaign.trigger = cloneAutomatedCampaign.trigger.id
            cloneAutomatedCampaign.option = cloneAutomatedCampaign.option.id

            if(cloneAutomatedCampaign.discountCode === null){
              delete cloneAutomatedCampaign.discountCode;
            }

            cloneAutomatedCampaign.discountCodesFounded = discountsFounded

            cloneAutomatedCampaign.popupId = this.popupId

            campaignAutomationConnector.start(this, cloneAutomatedCampaign)
            // }
          }
        }
      },

      showResultOfStartAutomation(campaign){
        if(campaign) {

          const text = this.preselectedTrigger ?  this.$vuetify.lang.t('$vuetify.automatedCampaign.welcomeSaved') : this.$vuetify.lang.t('$vuetify.automatedCampaign.started')
          EventBus.$emit('flash-message', {
            text: text,
            color: 'info',
            textColor: 'white',
            outlined: false
          });

          if(!this.preselectedTrigger){
            this.automatedCampaign = {
              trigger: {},
              option: {},
              media: [],
              // sms: '',
              removeMedia: false
            }
            this.$refs.vCampaign.reset()
            router.push('/campaigns')
          }else{
            this.viewMode = true
            EventBus.$emit('enable-publish')
          }

        }
        this.buttonDisable = false
      },
      updateRemoveFileFlag() {
        if(this.automatedCampaign.media === undefined || this.automatedCampaign.media === null){
          this.automatedCampaign.removeMedia = true
          this.automatedCampaign.media = null
          EventBus.$emit('file-attached', {isMms: false})
        }else {
          this.automatedCampaign.removeMedia = false
          EventBus.$emit('file-attached', {isMms: true})
        }
      },
      receiveCampaignToEdit(response){
        if(response.media === ""){
          response.media = null
        }
        response.removeMedia = false
        response.id = this.campaignIdToEdit

        response.trigger = {}

        this.automatedCampaign = response;

      },
      getOptionsMessage(){
        if(this.automatedCampaign.trigger.id === "Abandoned-Checkout-automation"){
         return this.$vuetify.lang.t('$vuetify.automatedCampaign.delay')
        }else if(this.automatedCampaign.trigger.id === "Abandoned-Cart-automation"){
          return this.$vuetify.lang.t('$vuetify.automatedCampaign.delay')
        }else if(this.automatedCampaign.trigger.id === "Abandoned-Product-automation"){
          return this.$vuetify.lang.t('$vuetify.automatedCampaign.delay')
        } else {
          return this.$vuetify.lang.t('$vuetify.automatedCampaign.optedIn')
        }
      },
      getSubOptionsMessage(){
        if(this.automatedCampaign.trigger.id === "Abandoned-Checkout-automation"){
          return this.$vuetify.lang.t('$vuetify.automatedCampaign.subDelay')
        }else if(this.automatedCampaign.trigger.id === "Abandoned-Cart-automation"){
          return this.$vuetify.lang.t('$vuetify.automatedCampaign.subDelay')
        }else if(this.automatedCampaign.trigger.id === "Abandoned-Product-automation"){
          return this.$vuetify.lang.t('$vuetify.automatedCampaign.subDelay')
        }else {
          return this.$vuetify.lang.t('$vuetify.automatedCampaign.subOptedIn')
        }
      }
    }
  }
</script>

<style>

</style>