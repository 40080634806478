<template>
  <div>
    <v-row>

        <v-col cols="7" class="planHeader mt-n13 pt-16">

          <p class="text-h5 font-weight-bold background--text text-left px-16 mx-8">{{$vuetify.lang.t('$vuetify.plans.checkout')}}</p>
          <p class="text-body-2 font-weight-bold background--text text-left pt-8 px-16 mx-8" >{{$vuetify.lang.t('$vuetify.plans.payment')}}</p>

          <div v-if="loading">
            <v-row class="d-flex justify-center">
              <v-col cols="auto" class="py-10">
                <v-progress-circular
                    size="100"
                    width="7"
                    color="primary"
                    indeterminate
                />
              </v-col>
            </v-row>
          </div>
          <div>
            <form id="payment-form" class="px-16 mx-8">
              <div id="payment-element">
                <!-- Elements will create form elements here -->
              </div>

              <div id="error-message" class="text-caption error--text text-left">
                <!-- Display error message to your customers here -->
              </div>
            </form>
          </div>


          <p class="text-caption background--text text-left pt-2 px-16 mx-8" >{{$vuetify.lang.t('$vuetify.plans.charge',getFixedPriceText())}}</p>

          <v-checkbox
              class="px-16 mx-8"
              v-model="agree"
              :label="$vuetify.lang.t('$vuetify.plans.terms')"
          >
            <template v-slot:label>
              <span class="text-caption background--text text-left">{{$vuetify.lang.t('$vuetify.plans.terms')}}</span>
            </template>
          </v-checkbox>
          <div class="px-16 mx-8">
            <v-btn @click="confirmSetup" color="primary" :disabled="!agree || disabledButton" >
              <v-icon left>
                mdi-lock-outline
              </v-icon>
              Purchase
            </v-btn>
          </div>
        </v-col>


      <v-col cols="5" class="planContent">

        <p class="text-h5 font-weight-bold primary--text text-left mx-8"> Shopsend </p>
        <p class="text-subtitle-2 secondary--text text-left pt-8 mx-8" >{{$vuetify.lang.t('$vuetify.plans.summary')}}</p>


        <v-row class="mx-8 d-flex">
          <v-col class="pl-0 pb-0"><span class="text-body-2 text-left secondary--text">ShopSend {{ this.plan.name }}</span></v-col>
          <v-col class="pb-0 ml-auto text-right"><span class="text-body-2  secondary--text">{{this.getMonthlyPrice()}}/month</span></v-col>
        </v-row>

        <v-row class="mx-8 d-flex pb-4">
          <v-col class="pl-0 pb-0 pt-0"><span class="text-caption text-left secondary--text">{{$vuetify.lang.t('$vuetify.plans.subType',this.isMonthly ? 'Monthly':'Yearly')}}</span></v-col>
          <v-col class="pb-0 pt-0 ml-auto text-right "><span class="text-caption secondary--text">{{$vuetify.lang.t('$vuetify.plans.subRecurrence',this.isMonthly ? '1':'12')}}</span></v-col>
        </v-row>

        <v-divider class="my-4 ml-4 mr-8 whiteDivider"/>

        <v-row class="mx-8 d-flex py-4">
          <v-col class="pl-0 pb-0 pt-0"><span class="text-caption text-left secondary--text">{{$vuetify.lang.t('$vuetify.plans.subtotal')}}</span></v-col>
          <v-col class="pb-0 pt-0 ml-auto text-right "><span class="text-caption secondary--text">{{getFixedPriceText(false)}}</span></v-col>
        </v-row>

        <v-divider class="my-4 ml-4 mr-8 whiteDivider"/>

        <v-row class="mx-8 d-flex py-4">
          <v-col class="pl-0 pb-0 pt-0"><span class="text-caption text-left secondary--text">{{$vuetify.lang.t('$vuetify.plans.billed')}}</span></v-col>
          <v-col class="pb-0 pt-0 ml-auto text-right "><span class="text-body-2 secondary--text">{{getFixedPriceText(false)}}</span></v-col>
        </v-row>

        <br><br>

        <v-row class="mx-8 py-4">
          <v-col cols=12 class="pb-0 pt-0">
            <v-card color="primary lighten-1" style="border: 1px solid white !important;">
              <v-card-title class="justify-center">
                <span class="text-caption secondary--text">{{$vuetify.lang.t('$vuetify.plans.additionalFees')}}</span>
              </v-card-title>
              <v-card-text class="d-flex pb-0">
                <v-col class="pl-0 pb-0 pt-0"><span class="text-caption text-left secondary--text">{{$vuetify.lang.t('$vuetify.plans.priceSms')}}</span></v-col>
                <v-col class="pb-0 pt-0 ml-auto text-right "><span class="text-caption secondary--text">{{formatMoney(this.plan.smsPrice,3)}}</span></v-col>
              </v-card-text>
              <v-card-text class="d-flex pt-0">
                <v-col class="pl-0 pb-0 pt-0"><span class="text-caption text-left secondary--text">{{$vuetify.lang.t('$vuetify.plans.priceMms')}}</span></v-col>
                <v-col class="pb-0 pt-0 ml-auto text-right "><span class="text-caption secondary--text">{{formatMoney(this.plan.mmsPrice,3)}}</span></v-col>
              </v-card-text>

              <v-card-text>
                <v-col cols=12 class="pl-0 pb-0 pt-0">
                  <p class="text-caption font-weight-thin text-left secondary--text mb-0">{{$vuetify.lang.t('$vuetify.plans.smsDetails')}}</p>
                  <p class="text-caption font-weight-thin text-left secondary--text mb-0">{{$vuetify.lang.t('$vuetify.plans.mmsDetails')}}</p>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import stripeConnector from "../../connectors/stripeConnector";
import utils from "../../models/utils";
import stripeObject from "../../models/stripeObject";

export default {
  name: "plan-payment-summary",
  components: {
  },
  props: {
    plan: {
      type: Object,
      default: () => {}
    },
    isMonthly: {
      type: Boolean,
      default: false
    },
  },
  async mounted(){
    await this.subscribe()
  },

  data() {
    return {
      loading: false,
      agree: false,
      disabledButton: false,
      successURL: window.location.href,
      elements: null,
      options: {
        clientSecret: null,
        appearance: {
          theme: 'stripe',
          variables: {
            colorPrimary: this.$vuetify.theme.themes['light'].primary,
            colorBackground: this.$vuetify.theme.themes['light'].clearbackground,
            colorText: this.$vuetify.theme.themes['light'].background,
            colorDanger: this.$vuetify.theme.themes['light'].error
          }
        },
      }
    };
  },
  methods: {
    getFixedPriceText(withText= true){
      let fixedPrice = this.plan.yearPrice
      let text = "yearly"
      if(this.isMonthly){
        fixedPrice = this.plan.price
        text = "monthly"
      }
      if(withText){
        return this.formatMoney(fixedPrice) + " " + text
      }else {
        return this.formatMoney(fixedPrice)
      }
    },
    getMonthlyPrice(){
      let fixedPrice = this.plan.yearMonthlyPrice
      if(this.isMonthly){
        fixedPrice = this.plan.price
      }
      return this.formatMoney(fixedPrice)
    },
    async setupCustomer(){
      const clientSecret = await stripeConnector.setupCustomer(this)
      return clientSecret.client_secret
    },
    async subscribe() {
      this.loading = true
      const stripe = await stripeObject.instance();

      this.options.clientSecret = await this.setupCustomer()
      this.elements = stripe.elements(this.options)

      // Create and mount the Payment Element
      const paymentElement = this.elements.create('payment');
      paymentElement.mount('#payment-element');
      this.loading = false
    },
    showErrorMessage(message){
      const messageContainer = document.querySelector('#error-message');
      messageContainer.textContent = message;
    },
    async confirmSetup(){
      this.disabledButton = true
      this.showErrorMessage('')
      this.$store.commit('setPlanSelected', {
        plan_id: this.plan.id,
        is_monthly: this.isMonthly
      });
      this.$store.commit('setMustShowSuccessPayment', true);
      const stripe = await stripeObject.instance();
      const {error} = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements: this.elements,
        confirmParams: {
          return_url: this.successURL,
        }
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        this.showErrorMessage(error.message)
        this.disabledButton = false
        this.$store.commit('setMustShowSuccessPayment', false);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    },
    handleFail(error){
      //console.log(error.data.message)
      this.loading = false
    },
  },
}

</script>

<style scoped>
  .planHeader {
    background-color: var(--v-secondary-base);
    height: 85vh;
  }
  .planContent {
    background-color: var(--v-primary-base);
  }
  .whiteDivider {
    border-color: var(--v-secondary-base) !important;
  }
</style>