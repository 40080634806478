<template>
  <div class="align-right">
    <div v-if="contact.status === 'Active'">
      <v-btn  small outlined @click="eventHandler($event, 'optedOut')"
              class="text-capitalize"
              color="primary">
        {{ this.$vuetify.lang.t('$vuetify.contactActions.optedOut') }}
      </v-btn>
    </div>
   </div>
</template>

<script>
  import contactConnector from "../../connectors/contactConnector";
  import {EventBus} from "../../packs/app";

  export default {
    name: "contact-actions",
    props: {
      contact: {
        type: Object,
        default: {}
      }
    },
    components: {
    },
    data () {
      return {
      }
    },
    methods: {
      eventHandler(event, action){
        if(action === "optedOut") {
          this.optedOutContact()
        }
      },
      async optedOutContact() {
        await contactConnector.optedOutContact(this, this.contact.id, "Opted Out by front view")
      },

    },
    mounted(){
    }
  }
</script>
<style scoped>

</style>