import connector from './connector'

const LOGIN_URI =  '/login';
const LOGIN_WITH_LINK = LOGIN_URI + '/link/validate'
const LOGIN_WITH_SHOPIFY = LOGIN_URI + '/link/shopify'
const LOG_OUT_URI =  '/logout';

const authConnector = {

  login(context, creds, callback) {

    connector.send(context,
      {
        method: 'post',
        url: LOGIN_URI,
        data: creds
      },function (context, response) {
        callback(context,response);
      });
  },
  loginWithLink(context, token, callback) {

    connector.send(context,
      {
        method: 'post',
        url: LOGIN_WITH_LINK,
        data: {token :token}
      },function (context, response) {
        callback(context,response);
      });
  },
  loginWithShopify(context, token, callback) {

    connector.send(context,
      {
        method: 'post',
        url: LOGIN_WITH_SHOPIFY,
        data: {token :token}
      },function (context, response) {
        callback(context,response);
      },true,"/unknownCustomer");
  },
  logOut(context, callback) {

    connector.send(context,
      {
        method: 'post',
        url: LOG_OUT_URI
      },function (context, response) {
        callback(context,response);
      });
  },
};
export default authConnector
