import connector from './connector'

const BILLING_URI = '/billing/summary';

const billingConnector = {

  summary(component){
    connector.send(component,
      {
        method: 'get',
        url: BILLING_URI,
      },function (component,response) {
        component.$store.commit('setBillingSummary', response.data.response);
        component.$store.commit('setActiveContacts', response.data.response.contacts);
      },false
    );
  }
 };
export default billingConnector
