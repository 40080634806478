<template>
  <v-row class="pl-10 pr-10">
    <v-col cols="12">
      <v-card>
        <div class="container pa-10">
          <canvas id="canvas" ref="canvas" height="300"></canvas>
        </div>
      </v-card>
    </v-col>
  </v-row>

</template>


<script>

  import { Line } from 'vue-chartjs'

  export default {
    name: "line-chart",
    extends: Line,
    props: {
      title: {
        type: String,
        default: ''
      },
      labels: {
        type: Array,
        default: []
      },
      data: {
        type: Array,
        default: []
      }
    },
    data () {
      return {
        chartData: {
          labels: this.labels,
          datasets: [{
            label: this.title,
            data: this.data,
            backgroundColor: null,
            borderWidth: 1,
          }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            titleFontSize: 16,
            bodyFontSize: 12,
            fontFamily: "'Sf pro text', sans-serif"
          },
          legend: {
            position: "top",
            align: "end",
            labels: {
              usePointStyle: true,
              boxWidth: 6,
              fontSize: 12,
              fontFamily: "'Sf pro text', sans-serif"
            }
          },
          elements: {
            line: {
              tension: 0.2
            },
            point:{
              radius: 1
            }
          },
          scales: {
            yAxes: [{
              gridLines: {
                drawBorder: false,
                borderDash: [2, 4],
                color: "#BBCADD"
              },
              ticks: {
                fontSize: 12,
                fontFamily: "'Sf pro text', sans-serif",
                fontColor: this.$vuetify.theme.themes['light'].background,
                fontStyle: '500',
                precision:0,
                maxTicksLimit: 5,
                beginAtZero: true,
                padding: 15,
              }
            }],
            xAxes: [{
              gridLines: {
                display: false,
                lineWidth: 0,
                color: "rgba(255,255,255,0)"
              },
              ticks: {
                fontSize: 12,
                fontFamily: "Sf pro text', sans-serif",
                fontColor: this.$vuetify.theme.themes['light'].background,
                fontStyle: '500',
                callback: function(value, index, values) {
                  let days = 2

                  if(index % days === 0 ){
                    return value
                  } else {
                    return ""
                  }

                }
              }
            }]
          }
        }
      }
    },
    methods: {
      gradientBackground() {

      }
    },
    mounted () {

      var ctx = document.getElementById('canvas').getContext("2d");
      var gradientStroke = ctx.createLinearGradient(0, 200, 0, 0);

      gradientStroke.addColorStop(0, 'rgb(92,122,255)');
      gradientStroke.addColorStop(1, 'rgb(173,188,255)');
      this.chartData.datasets[0].backgroundColor = gradientStroke
      this.renderChart(this.chartData, this.options)
    }
  }
</script>

<style scoped>

</style>