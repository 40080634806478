<template>
  <img :src="dsMarket">
</template>

<script>
  import dsMarket from '../../images/icons/ds-market.svg'

  export default {
    name: "ds-market",
    components: {
      dsMarket
    },
    data () {
      return {
        dsMarket: dsMarket,
      }
    }
  }
</script>

<style scoped>

</style>