import connector from '../connector'

const CONTACT_GROWTH_URI = '/report/contact/growth';

const contactGrowthReportConnector = {

  findItems (component, callback, params=null) {
    let queryString = ""
    if(params && Object.keys(params).length !== 0) {
      queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }

    connector.send(component,
    {
      method: 'get',
      url: CONTACT_GROWTH_URI + queryString,
    },function (component,response) {
      callback(response.data.response);
    });
  }
 };
export default contactGrowthReportConnector
