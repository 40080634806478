var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{attrs:{"color":"primary","block":"","rounded":""},on:{"click":function($event){_vm.visibleForm = true}}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.segment.newSegment')))]),_vm._v(" "),_c('v-dialog',{attrs:{"width":"80vw","height":"80vh","persistent":""},model:{value:(_vm.visibleForm),callback:function ($$v) {_vm.visibleForm=$$v},expression:"visibleForm"}},[_c('v-card',[_c('v-toolbar',{staticStyle:{"height":"32px"},attrs:{"flat":""}},[_c('v-spacer'),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c('v-card-subtitle',{staticClass:"text-none text-h5 text-center py-0 px-16 background--text"},[_vm._v("\n          "+_vm._s(_vm.$vuetify.lang.t('$vuetify.segment.create'))+"\n        ")]),_vm._v(" "),_c('v-card-subtitle',{staticClass:"text-none text-body-2 text-center pt-0 px-16 background--text"},[_vm._v("\n          "+_vm._s(_vm.$vuetify.lang.t('$vuetify.segment.subtitle'))+"\n        ")]),_vm._v(" "),_c('v-card-text',[_c('ValidationObserver',{ref:"vCriteria",attrs:{"disabled":!_vm.visibleForm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('v-container',[_c('v-row',{staticClass:"px-6"},[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Segment name","rules":{ required: true, min: 5, regexChar: /^[\w\s]+$/g }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"text-caption text-left mb-2"},[_c('span',{staticClass:"text-body-2 font-weight-bold text--background"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.segment.name')))])]),_vm._v(" "),_c('v-text-field',{staticClass:"background--text",attrs:{"required":"","outlined":"","counter":"60","background-color":"white","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('p',{staticClass:"text-left mb-2 px-6"},[_c('span',{staticClass:"text-body-2 font-weight-bold text--background"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.segment.definition')))])]),_vm._v(" "),_vm._l((_vm.rules),function(criteria,idx){return _c('div',{key:idx},[_c('v-row',{staticClass:"px-6"},[_c('v-col',{staticClass:"px-1 py-0",attrs:{"cols":"1"}},[_c('p',{staticClass:"text-center pt-3"},[_c('span',{staticClass:"text-caption text--background"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.segment.contact')))])])]),_vm._v(" "),_c('v-col',{staticClass:"px-1 py-0",attrs:{"cols":"2"}},[_c('ValidationProvider',{attrs:{"vid":'rule' + idx,"name":"rule","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{key:criteria.id,staticClass:"background--text",attrs:{"items":_vm.ruleNames,"item-text":"name","item-value":"id","required":"","outlined":"","error-messages":errors},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('span',{staticClass:"text-caption text--background"},[_vm._v("\n                            "+_vm._s(data.item.name)+"\n                          ")])]}},{key:"item",fn:function(data){return [_c('span',{staticClass:"text-caption text--background"},[_vm._v("\n                        "+_vm._s(data.item.name)+"\n                      ")])]}}],null,true),model:{value:(criteria.id),callback:function ($$v) {_vm.$set(criteria, "id", $$v)},expression:"criteria.id"}})]}}],null,true)})],1),_vm._v(" "),(criteria.id !== null && criteria.id !== '' && _vm.operators[criteria.id])?_c('v-col',{staticClass:"px-1 py-0",attrs:{"cols":"2"}},[_c('v-row',[_c('v-col',{staticClass:"pt-3 pl-2 pr-2 py-0"},[_c('ValidationProvider',{attrs:{"vid":'operator' + idx,"name":"operator","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{key:criteria.operator,staticClass:"background--text",attrs:{"items":_vm.operators[criteria.id],"item-text":"name","item-value":"id","required":"","outlined":"","error-messages":errors},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('span',{staticClass:"text-caption text--background"},[_vm._v("\n                        "+_vm._s(data.item.name)+"\n                      ")])]}},{key:"item",fn:function(data){return [_c('span',{staticClass:"text-caption text--background"},[_vm._v("\n                        "+_vm._s(data.item.name)+"\n                      ")])]}}],null,true),model:{value:(criteria.operator),callback:function ($$v) {_vm.$set(criteria, "operator", $$v)},expression:"criteria.operator"}})]}}],null,true)})],1),_vm._v(" "),(criteria.id.includes('Spent'))?_c('v-col',{staticClass:"pt-3 pl-0 py-0 pr-2"},[_c('ValidationProvider',{attrs:{"vid":'keyValue' + idx,"name":"key value","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"background--text",attrs:{"required":"","outlined":"","background-color":"white","error-messages":errors},model:{value:(criteria.key),callback:function ($$v) {_vm.$set(criteria, "key", $$v)},expression:"criteria.key"}})]}}],null,true)})],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(criteria.id !== null && criteria.id !== '' && _vm.timeOperators[criteria.id])?_c('v-col',{staticClass:"px-1 py-0",attrs:{"cols":"1"}},[_c('ValidationProvider',{attrs:{"vid":'timeOption' + idx,"name":"time option","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{key:criteria.timeOperator,staticClass:"background--text",attrs:{"items":_vm.timeOperators[criteria.id],"item-text":"name","item-value":"id","required":"","outlined":"","error-messages":errors},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('span',{staticClass:"text-caption text--background"},[_vm._v("\n                        "+_vm._s(data.item.name)+"\n                      ")])]}},{key:"item",fn:function(data){return [_c('span',{staticClass:"text-caption text--background"},[_vm._v("\n                        "+_vm._s(data.item.name)+"\n                      ")])]}}],null,true),model:{value:(criteria.timeOperator),callback:function ($$v) {_vm.$set(criteria, "timeOperator", $$v)},expression:"criteria.timeOperator"}})]}}],null,true)})],1):_vm._e(),_vm._v(" "),(criteria.timeOperator != null && criteria.timeOperator != '' && criteria.timeOperator != 'ever')?_c('v-col',{staticClass:"px-1 py-0",attrs:{"cols":"4"}},[_c('v-row',[_c('v-col',{staticClass:"pt-3 px-2 py-0",attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":"start date","rules":"required","mode":"eager","vid":'startDate' + idx},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-datetime',{staticClass:"background--text",attrs:{"type":"date","auto":"","text-field-props":{
                                     outlined: true,
                                     backgroundColor: 'white',
                                     appendIcon: 'mdi-calendar-question',
                                     errorMessages: errors
                              }},model:{value:(criteria.startDate),callback:function ($$v) {_vm.$set(criteria, "startDate", $$v)},expression:"criteria.startDate"}})]}}],null,true)})],1),_vm._v(" "),(criteria.timeOperator == 'between')?_c('v-col',{staticClass:"px-2 py-0",attrs:{"cols":"1"}},[_c('p',{staticClass:"text-center pt-6"},[_c('span',{staticClass:"text-caption text--background"},[_vm._v(" and ")])])]):_vm._e(),_vm._v(" "),(criteria.timeOperator == 'between')?_c('v-col',{staticClass:"pt-3 px-2 py-0",attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":"end date","rules":"required","mode":"eager","vid":'endDate' + idx},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('custom-datetime',{staticClass:"background--text",attrs:{"type":"date","auto":"","text-field-props":{
                                     outlined: true,
                                     backgroundColor: 'white',
                                     appendIcon: 'mdi-calendar-question',
                                     errorMessages: errors
                              }},model:{value:(criteria.endDate),callback:function ($$v) {_vm.$set(criteria, "endDate", $$v)},expression:"criteria.endDate"}})]}}],null,true)})],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-col',{staticClass:"pt-3 px-1 py-0 my-2 mr-auto",attrs:{"cols":"2"}},[_c('v-row',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.addOtherRule(idx,'and')}}},[_vm._v("\n                        + AND\n                      ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.addOtherRule(idx,'or')}}},[_vm._v("\n                        + OR\n                      ")]),_vm._v(" "),(_vm.rules.length > 1)?_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.removeRule(idx)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e()],1)],1)],1),_vm._v(" "),(criteria.nextOperation==='and')?_c('v-divider',{staticClass:"my-4"}):_c('div',[_c('p',{staticClass:"text-left pa-0 ma-0 mt-n6 pb-2 pl-12"},[_c('span',{staticClass:"text-caption text--background"},[_vm._v(" OR ")])])])],1)}),_vm._v(" "),_c('p',{staticClass:"text-left mb-2 px-6"},[_c('span',{staticClass:"text-body-2 font-weight-bold text--background"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.segment.estimation')))]),_vm._v(" "),_c('span',{staticClass:"text-body-2 text--background"},[_vm._v("   "+_vm._s(_vm.estimation)+" Contacts ")]),_vm._v(" "),_c('v-btn',{attrs:{"small":"","loading":_vm.loading},on:{"click":function($event){return _vm.runRules()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],2)]}}])})],1),_vm._v(" "),_c('v-card-actions',{staticClass:"justify-center pb-12"},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.saveRules()}}},[_c('v-icon',[_vm._v("mdi-lock")]),_vm._v("\n            "+_vm._s(_vm.$vuetify.lang.t('$vuetify.segment.save'))+"\n          ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }