<template>
  <v-dialog eager
          @input="change" value="true"
          :max-width="width" :persistent="persistent" @keydown.esc="choose(false)"
  >
    <v-card>
      <div v-if="noButtons" class="pt-16"/>
      <v-card-title class="headline justify-center text-center pt-8 background--text" v-html="message" />

      <v-card-text class="h6 text-h6 py-6 px-6 justify-center text-center background--text"  v-if="info" v-html="info"/>
      <v-card-text class="body-1 text-body-1 py-6 px-6 justify-center text-center background--text" v-html="subtitle"/>

      <v-card-actions class="justify-center pb-8" v-if="!noButtons">
          <v-btn
                v-if="Boolean(buttonTrueText)"
                class="pa-2"
                :color="buttonTrueColor"
                :text="buttonTrueFlat"
                :outlined="buttonTrueOutlined"
                @click="choose(true)"
                style="width:25vh;"
            >
              {{ buttonTrueText }}
            </v-btn>
           <v-btn
                v-if="Boolean(buttonFalseText)"
                class="ml-4 pa-2"
                :color="buttonFalseColor"
                :text="buttonFalseFlat"
                :outlined="buttonFalseOutlined"
                @click="choose(false)"
                style="width:25vh;"
            >
              {{ buttonFalseText }}
            </v-btn>

      </v-card-actions>
      <div v-else class="pb-16"/>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    name: "confirmation-dialog",
    props: {
      buttonTrueText: {
        type: String,
        default: 'Yes'
      },
      buttonFalseText: {
        type: String,
        default: null
      },
      buttonTrueColor: {
        type: String,
        default: 'primary'
      },
      buttonFalseColor: {
        type: String,
        default: 'primary'
      },
      buttonFalseFlat: {
        type: Boolean,
        default: false
      },
      buttonTrueFlat: {
        type: Boolean,
        default: false
      },
      buttonFalseOutlined: {
        type: Boolean,
        default: true
      },
      buttonTrueOutlined: {
        type: Boolean,
        default: false
      },
      color: {
        type: String,
        default: 'warning'
      },
      icon: {
        type: String,
        default: ''
      },
      message: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        default: ''
      },
      info: {
        type: String,
        default: null
      },
      persistent: {
        type: Boolean,
        default: true
      },
      width: {
        type: Number,
        default: 550
      },
      noButtons: {
        type: Boolean,
        default: false
      },
    },
    data () {
      return {
        value: false
      }
    },
    mounted () {
      document.addEventListener('keyup', this.onEnterPressed)
    },
    destroyed () {
      document.removeEventListener('keyup', this.onEnterPressed)
    },
    methods: {
      onEnterPressed (e) {
        if (e.keyCode === 13) {
          e.stopPropagation()
          this.choose(true)
        }
      },
      choose (value) {
        this.$emit('result', value)
        this.value = value
        this.$destroy()
      },
      change (res) {
        this.$destroy()
      }
    }
  }
</script>

<style scoped>
  .v-card__text, .v-card__title {
    word-break: normal; /* maybe !important  */
  }
</style>