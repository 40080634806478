<template>
  <secure-content>
    <v-row max-height='100vh'
           min-height='100vh'>
      <v-col cols="7" class="pt-8 ml-4 white">

        <v-row class="pa-4 mt-4">
          <v-col v-if="campaignDetails">
            <div class="text-h5 font-weight-bold background--text"> {{campaignDetails.name}}
              <v-chip v-if="campaignDetails.campaign_type !== 'Standard'"
                  class="ma-1 font-weight-regular"
                  color="#FF6482"
                  text-color="white"
              >
                {{this.$vuetify.lang.t('$vuetify.campaign.automationType')}}
              </v-chip>
              <v-chip v-else
                      class="ma-1 font-weight-regular"
                      color="chip"
                      text-color="white"
              >
                {{this.$vuetify.lang.t('$vuetify.campaign.standardType')}}
              </v-chip>
            </div>

            <v-row>
              <v-col cols="2" class="pb-0"><div class="text-caption font-weight-bold background--text">{{this.$vuetify.lang.t('$vuetify.campaign.id')}}:</div></v-col>
              <v-col class="pb-0"><div class="text-caption background--text">{{campaignDetails.id}}</div></v-col>
            </v-row>

            <v-row>
              <v-col cols="2" class="pb-0 pt-1"><div class="text-caption font-weight-bold background--text">{{this.$vuetify.lang.t('$vuetify.campaign.schedule')}}:</div></v-col>
              <v-col class="pb-0 pt-1"><div class="text-caption background--text">{{formatDatetime(campaignDetails.schedule_time) }}</div></v-col>
            </v-row>

            <v-row>
              <v-col cols="2" class="pb-0 pt-1"><div class="text-caption font-weight-bold background--text">{{this.$vuetify.lang.t('$vuetify.campaign.list')}}:</div></v-col>
              <v-col class="pb-0 pt-1"><div class="text-caption background--text">{{campaignDetails.list_name}}</div></v-col>
            </v-row>

          </v-col>

          <div v-else-if="campaignNotPresent">
            <h3 class="background--text">{{ this.$vuetify.lang.t('$vuetify.campaign.notFound') }}</h3>
          </div>
          <div v-else>
            <h3 class="background--text">{{ this.$vuetify.lang.t('$vuetify.campaign.searching') }}</h3>
          </div>
        </v-row>

        <summary-view v-if="campaignDetails"
                      :data = "{'grossSales': campaignDetails.earnings, 'orders': campaignDetails.total_orders,
                      'aov': campaignDetails.aov,'rpm': campaignDetails.rpm, 'optOut': campaignDetails.opted_out }"
                      font-size="small"
                      color-style="primaryStyle"
                      show-help
                      class="pb-8"
        />


        <div v-if="campaignDetails">
          <v-divider class="pt-2 pb-2"></v-divider>
        <v-row class="">
          <v-col>
            <div class="text-center background--text text-caption"><b>{{this.$vuetify.lang.t('$vuetify.campaign.delivered')}}</b> </div>
            <div class="text-center background--text text-caption"> {{campaignDetails.delivered}}</div>
          </v-col>
          <v-icon color="primary">mdi-chevron-right</v-icon>


          <v-tooltip bottom color="background">
            <template v-slot:activator="{ on, attrs }">
              <v-col v-bind="attrs"
                     v-on="on">
                <div class="text-center background--text text-caption"><b>{{$vuetify.lang.t('$vuetify.campaign.clicked')}}</b> </div>
                <div class="text-center background--text text-caption"> {{campaignDetails.clicked}}</div>
              </v-col>
            </template>
            <div class="text-caption accent--text">{{$vuetify.lang.t('$vuetify.campaign.numberOfClicks')}} {{campaignDetails.total_clicks}}</div>
          </v-tooltip>

          <v-icon color="primary">mdi-chevron-right</v-icon>

          <v-col>
            <div class="text-center background--text text-caption"><b>{{this.$vuetify.lang.t('$vuetify.campaign.visitors')}}</b> </div>
            <div class="text-center background--text text-caption"> {{campaignDetails.visitors}}</div>
          </v-col>
          <v-icon color="primary">mdi-chevron-right</v-icon>

          <v-col>
            <div class="text-center background--text text-caption"><b>{{this.$vuetify.lang.t('$vuetify.campaign.addCart')}}</b> </div>
            <div class="text-center background--text text-caption"> - </div>
          </v-col>
          <v-icon color="primary">mdi-chevron-right</v-icon>

          <v-col>
            <div class="text-center background--text text-caption"><b>{{this.$vuetify.lang.t('$vuetify.campaign.checkout')}}</b> </div>
            <div class="text-center background--text text-caption">  - </div>
          </v-col>
          <v-icon color="primary">mdi-chevron-right</v-icon>

          <v-tooltip bottom color="background">
            <template v-slot:activator="{ on, attrs }">
              <v-col v-bind="attrs"
                     v-on="on">

                <div class="text-center background--text text-caption"><b>{{$vuetify.lang.t('$vuetify.campaign.conversions')}}</b> </div>
                <div class="text-center background--text text-caption"> <b>{{campaignDetails.conversions}}</b></div>
              </v-col>
            </template>
            <div class="text-caption accent--text">{{$vuetify.lang.t('$vuetify.campaign.numberOfOrders')}} {{campaignDetails.total_orders}}</div>
          </v-tooltip>

        </v-row>
        <v-divider class="mt-2 pb-2 mt-4"></v-divider>

          <v-row v-if="campaignDetails.campaign_type === 'Standard' && campaignDetails.links.length > 0">
            <engagement-chart :links="campaignDetails.links" :scheduled-time="campaignDetails.schedule_time"/>
          </v-row>

          <v-row class="pa-4">

            <v-col cols="7">
              <div class="background--text text-subtitle-2">{{this.$vuetify.lang.t('$vuetify.campaign.carriers')}}</div>
              <v-row>
                <v-col v-for="(carrier, idx) in campaignDetails.carriers" v-bind:key="idx" class="text-center pl-0 pr-0 pt-6">
                  <img :src="carrier.logo" :alt="carrier.name" width="65px" height="65px">
                  <div class="">{{carrier.value.toFixed(2)}}%</div>
                </v-col>
              </v-row>
              <v-divider class="pa-2 mt-4"></v-divider>
            </v-col>
          </v-row>

        </div>
      </v-col>

      <v-col cols="4">
        <messageViewer
            class="pt-16"
            v-if="campaignDetails"
            :sms.sync="campaignDetails.messages.sms[0]"
            :media.sync="campaignDetails.messages.media[0]"
            :previousMessages.sync="campaignDetails.previousMessages"
            :discountCode.sync="campaignDetails.discount_code"
            :show-estimates="false"/>
      </v-col>



    </v-row>
  </secure-content>
</template>

<script>

  import campaignConnector from "../../connectors/campaignConnector";
  import secureContent from '../secure-content.vue'
  import messageViewer from './message-viewer'
  import summaryView from "../base/summaryView";
  import engagementChart from "./engagement-chart"

  import cAtt from '../../images/at_t.png'
  import cOther from '../../images/other.png'
  import cSprint from '../../images/sprint.png'
  import cTMobile from '../../images/tmobile.png'
  import cVerizon from '../../images/verizon.png'

  export default {
    name: "campaign-details",

    components: {
      secureContent,
      messageViewer,
      summaryView,
      engagementChart
    },
    data() {
      return {
        campaignDetails: null,
        campaignNotPresent: false,
        cAtt: cAtt,
        cOther: cOther,
        cSprint: cSprint,
        cTMobile: cTMobile,
        cVerizon: cVerizon,
        carrierGroups: [
          {names: ['AT&T - PSTN','AT&T Wireless'],  alias: 'AT&T'},
          {names: ['Sprint Spectrum, L.P.'],  alias: 'Sprint'},
          {names: ['T-Mobile', "T-Mobile USA, Inc.",'T-Mobile USA, Inc. (form. Metro PCS, Inc.)'], alias: 'T-Mobile'},
          {names: ['Verizon', 'Verizon Wireless'], logo: 'cVerizon', alias: 'Verizon'},
          {names: [], alias: 'Others'},
          /*other names
          * 'Ameritech - PSTN': 'other',
          'Bandwidth/RingCentral Messaging - Sybase365': '',
          'Cablevision Lightpath, Inc.': '',
          'Cellular South, Inc.': '',
          'Comcast': '',
          'Cricket Wireless - ATT - SVR': '',
          'Google (Grand Central) BWI - Bandwidth.com - SVR': '',
          'Oklatel Communications, Inc.': '',
          'Qwest Corporation': '',
          "Republic Wireless - Bandwidth.com - Sybase365"
          "RSA 1 Limited Partnership dba Chat Mobility"
          'Skype Communications SARL - Level3 - SVR'
          'TextNow - Neutral Tandem - SVR'
          'Time Warner Communications'
         'US Cellular Corp.'
         'Ymax Communications Corp.'
          * */
        ],
      }
    },
    created() {
      this.fetchData()
    },
    destroyed() {
    },
    watch: {
      // call again the method if the route changes
      '$route': 'fetchData'
    },
    methods: {
      receiveCampaignDetails(campaignDetails) {
        let carriers = {
          'AT&T': {name: 'AT&T', value: 0,  logo: this.cAtt},
          'Sprint': {name: 'Sprint', value: 0, logo: this.cSprint},
          'T-Mobile': {name: 'T-Mobile', value: 0, logo: this.cTMobile},
          'Verizon': {name: 'Verizon', value: 0, logo: this.cVerizon},
          'Others': {name: 'Others', value: 0, logo: this.cOther},
        }

        for(let carrier of campaignDetails.carriers) {
          for(let group of this.carrierGroups){
            if(group.alias === 'Others'){
              carriers[group.alias].value += carrier.value
            }else {
              if(group.names.includes(carrier.name)) {
                carriers[group.alias].value += carrier.value
                break
              }
            }
          }
        }
        campaignDetails.carriers = Object.values(carriers)
        this.campaignDetails = campaignDetails
      },

      fetchData(){
        campaignConnector.findCampaignDetails(this, this.$route.params.campaignId)
      },
      campaignNotFound(){
        this.campaignNotPresent = true
      },
      formatDatetime(date) {
        if (date === 'Ongoing'){
          return  date
        }else {
          const dateFormat = require('dateformat')
          const createdAt = new Date(date)
          return dateFormat(createdAt, "ddd, mmm dd, yyyy hh:MM tt Z")
        }
      }
    }
  }
</script>

<style scoped>

</style>