<template>
  <v-switch
      class="mb-1 mt-0"
      v-model="campaign.status"
      color="success"
      hide-details
      @click="eventHandler($event)"
  ></v-switch>
</template>

<script>


  import {EventBus} from "../../packs/app";
  import campaignAutomationConnector from "../../connectors/campaignAutomationConnector";

  export default {
    name: "automated-campaign-status",
    props: {
      campaign: {
        type: Object,
        default: {}
      }
    },
    components: {
    },
    data () {
      return {
      }
    },
    methods: {
      async eventHandler(event){

        let newStatus = this.campaign.status
        let texts= newStatus ? 'toggleOn' : 'toggleOff'

        let choice

        if (this.campaign.has_other_active || texts === 'toggleOff'){
          choice = await this.$confirm(this.$vuetify.lang.t('$vuetify.campaignActions.'+texts+'.message'),
              {
                subtitle: this.$vuetify.lang.t('$vuetify.campaignActions.'+texts+'.subtitle'),
                buttonFalseText: this.$vuetify.lang.t('$vuetify.campaignActions.'+texts+'.cancel'),
                buttonTrueText: this.$vuetify.lang.t('$vuetify.campaignActions.'+texts+'.ok'),
                width: 650
              })
        }else {
          choice = true
        }

        if (choice){
          campaignAutomationConnector.updateStatus(this,this.campaign.id, newStatus)
        }else{
          this.campaign.status = !newStatus
        }
      },
      receiveUpdateStatusResponse(response) {
        if(response === true){
          EventBus.$emit('update-automated-campaigns-list');
        }else {
          this.statusRollback()
        }
      },
      statusRollback(){
        this.campaign.status = !this.campaign.status
      }
    },
    mounted(){
    }
  }
</script>
<style scoped>

</style>