<template>
  <div>
    <v-row class="planContent">
      <v-col cols="12" >
        <p class="text-h6 secondary--text text-center pt-0 pb-0 my-0">{{title}}</p>
        <p v-if="showTryAgain" class="text-subtitle-1 error--text text-center mb-0">{{errorMessage}}</p>
      </v-col>
    </v-row>

    <v-row class="planContent d-flex justify-center mt-0">
      <v-col cols="auto" class="pa-0" >
        <v-img :src="paymentImg" width="200" height="200" contain/>
      </v-col>
    </v-row>

    <v-row class="planContent d-flex justify-center mt-0">
      <v-col cols="12" class="px-12">
        <p v-if="!showTryAgain" class="text-subtitle-1 secondary--text text-center mb-0">{{message}}</p>
        <v-btn v-else class="mt-0"
               color="secondary"
               block
               @click="restartPlanSelection()">
          <span class="text-subtitle-1 error--text"> Try again </span>
        </v-btn>
      </v-col>
    </v-row>

  </div>

</template>

<script>

import stripeObject from "../../models/stripeObject";
import stripeConnector from "../../connectors/stripeConnector";
import {EventBus} from "../../packs/app";
import failPaymentImg from '../../images/fail-payment.gif'
import successPaymentImg from '../../images/sucess-payment.gif'
import loadingPaymentImg from '../../images/loading-payment.gif'

export default {
  name: "success-onboard",
  components: {
    successPaymentImg,
    failPaymentImg,
    loadingPaymentImg
  },
  data() {
    return {
      title: this.$vuetify.lang.t('$vuetify.plans.paymentProcessing'),
      message: this.$vuetify.lang.t('$vuetify.plans.waitCc'),
      errorMessage: this.$vuetify.lang.t('$vuetify.plans.wrong'),
      showTryAgain: false,
      paymentImg: loadingPaymentImg,

    }
  },
  async mounted(){

    EventBus.$on('payment_was_completed', (content) => {
      console.log('hook report: ', content)
      this.paymentImg = successPaymentImg
      this.message = this.$vuetify.lang.t('$vuetify.plans.welcome')
      this.title = this.$vuetify.lang.t('$vuetify.plans.success')
    })

    // Retrieve the "setup_intent_client_secret" query parameter appended to
// your return_url by Stripe.js
   const clientSecret = new URLSearchParams(window.location.search).get(
        'setup_intent_client_secret'
    );

    const stripe = await stripeObject.instance()
    // Retrieve the SetupIntent
    if(clientSecret){
      stripe.retrieveSetupIntent(clientSecret).then(async ({setupIntent}) => {
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (setupIntent.status) {
          case 'succeeded': {
            //trigger subscriptions
            let plan = this.$store.getters.planSelected
            if(plan){
              let result = await stripeConnector.subscribe(this, plan)
              if(result.completed){
                this.message = this.$vuetify.lang.t('$vuetify.plans.welcome')
                this.title = this.$vuetify.lang.t('$vuetify.plans.success')
                this.paymentImg = successPaymentImg
                this.$store.commit('setPlanSelected', null);
              }
            }else{
              this.errorMessage = "you must have a plan selected"
              this.title = this.$vuetify.lang.t('$vuetify.plans.ops')
              this.paymentImg = failPaymentImg
            }
            break;
          }
          case 'processing': {
            break;
          }

          case 'requires_payment_method': {
            this.errorMessage =  this.$vuetify.lang.t('$vuetify.plans.wrong');
            this.showTryAgain = true
            this.paymentImg = failPaymentImg
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            break;
          }
        }
      });
    }else {
      //this.$store.commit('setMustShowSuccessPayment', false);
      EventBus.$emit('close-plans');
    }
  },
  methods: {
    handleFail(error){
      console.log(error)
      this.loading = false
    },
    restartPlanSelection(){
      this.errorMessage = null
      this.showTryAgain = false
      this.paymentImg = loadingPaymentImg
    }
  },
}

</script>

<style scoped>
.planHeader {
  background-color: var(--v-secondary-base);
}
.planContent {
  background-color: var(--v-primary-base);
}
</style>