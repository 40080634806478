<template>
  <div>
    <v-row justify="start" align="start">
      <v-col cols="12">
        <v-card
          outlined
          class="codebox-card mt-0 pa-0"
          tile
        >
          <div v-if="title">
            <p class="text-subtitle-1 pl-4 mb-0 text--background text-start" v-html="title"></p>
          </div>

          <div v-if="help">
            <p class="text-subtitle-2 pl-4 mb-0 text--background text-start" v-html="help"></p>
          </div>

          <v-btn color="primary" class="codebox-action" @click="copyCode">
            <v-icon left>mdi-content-copy</v-icon>
            Copy to clipboard
          </v-btn>

          <v-card-text class="codebox-code background--text pa-0">
            <v-textarea class="pt-2 mt-0 terms-size"
              ref="textToCopy"
              :rows="rows"
              readonly
              id="terms"
              rounded
              :value="code"
            >
            </v-textarea>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

  export default {
    name: "code-box",
    props: {
      title: {
        type: String,
        default: ''
      },
      code: {
        type: String,
        default: null
      },
      help: {
        type: String,
        default: null
      },
      rows: {
        type: Number,
        default: 6
      }
    },
    components: {
    },
    data () {
      return {
        disabled: true
      }
    },
    methods: {
      copyCode () {
        let textToCopy = this.$refs.textToCopy.$el.querySelector('textarea')
        textToCopy.select()
        document.execCommand("copy");
        this.deselectAll(textToCopy)
      },
      deselectAll (element) {


        if (element && /INPUT|TEXTAREA/i.test(element.tagName)) {
          if ('selectionStart' in element) {
            element.selectionEnd = element.selectionStart;
          }
          element.blur();
        }

        if (window.getSelection) { // All browsers, except IE <=8
          window.getSelection().removeAllRanges();
        } else if (document.selection) { // IE <=8
          document.selection.empty();
        }
      }
    },
  }
</script>
<style>

  .codebox-card:hover .codebox-action {
    opacity: 1;
  }

  .codebox-action {
    position: absolute;
    top: -10px;
    right: 2px;
    outline: none !important;
    opacity: 0;
  }

  .codebox-action i {
    color: #FFF;
    margin-top: -1px;
    margin-right: 4px;
  }

  .codebox-code {
    border-radius: 5px;
    background: rgb(249, 250, 255);
  }

   .terms-size {
     font-size: 12px;
   }
  .v-textarea textarea {
    line-height: 1rem !important;
    resize: none;
   }

  .v-text-field--rounded>.v-input__control>.v-input__slot {
    padding: 0 0 0 12px;
  }

</style>
