<template>
  <secure-content>

    <summary-view v-if="listIdDone" class="mt-4 pb-4"
                  :data-source="getContactListSummaryReportDataSource()"
                  :parameters="{list_id: this.listId}"
    ></summary-view>

    <paginated-table :title="$vuetify.lang.t('$vuetify.contacts.growthTools')"
                     :headers="growthToolsHeaders"
                     :data-source="getGrowthToolsTableDataSource()"
                     order="DESC"
                     enable-new
                     csv-report-type="popup"
                     kind = "Tool"
                     update-event = "update-popup-list"
    ></paginated-table>

    <paginated-table v-if="listIdDone"
                     :title="title"
                     :headers="contactHeaders"
                     :data-source="getContactTableDataSource()"
                     :base-query="getContactBaseQuery()"
                     order="DESC"
                     enable-search
                     kind = "Contact"
                     update-event = "update-contact-list"
    ></paginated-table>
  </secure-content>
</template>

<script>

import secureContent from '../secure-content.vue'
import summaryView from "../base/summaryView"
import paginatedTable from "../base/paginatedTable"
import contactChart from "./contact-chart"
import auth from '../../models/auth'
import listConnector from "../../connectors/ListConnector"
import contactConnector from "../../connectors/contactConnector"
import growthToolsConnector from "../../connectors/growthToolsConnector"
import contactReportSummaryConnector from "../../connectors/reports/contactReportSummaryConnector"


export default {
  name: "contact-list",
  components: {
    secureContent,
    paginatedTable,
    summaryView,
    contactChart
  },
  data () {
    return {
      title: this.$vuetify.lang.t('$vuetify.contacts.title'),
      listIdDone: false,
      contactHeaders:  [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'full_name',
        },
        {
          text: 'Phone',
          sortable: false,
          value: 'phone',
        },
        {
          text: 'Email',
          sortable: false,
          value: 'email',
        },
        {
          text: 'Added',
          align: 'start',
          sortable: false,
          value: 'active_on',
          type: 'date'
        },
        {
          text: 'Status',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        {
          text: 'ACTION',
          align: 'start',
          sortable: false,
          value: 'contact',
          type: 'actions'
        }
      ],
      growthToolsHeaders:  [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Type',
          sortable: false,
          value: 'type',
        },
        {
          text: 'Devices',
          sortable: false,
          value: 'device',
        },
        {
          text: 'Start Date',
          align: 'start',
          sortable: false,
          value: 'created_at',
          type: 'date'
        },
        {
          text: 'Displays',
          align: 'start',
          sortable: false,
          value: 'display',
        },
        {
          text: 'Clicks',
          align: 'start',
          sortable: false,
          value: 'clicks',
        },
        {
          text: 'CTR',
          align: 'start',
          sortable: false,
          value: 'ctr',
        },
        {
          text: 'STATUS',
          align: 'start',
          sortable: false,
          value: 'active',
          model: 'popup',
          type: 'switch'
        },
        {
          text: 'ACTION',
          align: 'start',
          sortable: false,
          value: 'popup',
          type: 'actions'
        }
      ]
    }
  },
  methods: {
    getRol () {
      return auth.userRol(this);
    },
    getContactTableDataSource() {
      return contactConnector
    },
    getGrowthToolsTableDataSource() {
      return growthToolsConnector
    },
    getContactBaseQuery() {
      return { list_id: this.listId }
    },
    receiveCompanyList(response) {
      const masterList = response.find(list => list.name === 'All Contacts')
      this.listId = masterList.id
      this.listIdDone = true
    },
    getContactListSummaryReportDataSource(){
      return contactReportSummaryConnector
    }
  },
  mounted(){
    listConnector.findCompanyListsAndSegments(this)
  }
}
</script>
<style scoped>

</style>