<template>
  <secure-content :remove-padding="true">
    <v-row>
      <v-col cols="7" sm="7" class="pt-13 pl-10">

        <p class="text-h6 text--background text-left mb-0">{{$vuetify.lang.t('$vuetify.campaign.title')}}</p>
        <p class="text-body-2 text--background text-left">{{$vuetify.lang.t('$vuetify.campaign.subtitle')}}</p>

        <ValidationObserver ref="vCampaign" v-slot="{ validate, reset }">
          <v-container >
            <v-row class="mt-4">
              <v-col cols="11" class="py-0">
                <ValidationProvider v-slot="{ errors }" name="Campaign name" :rules="{ required: true, min: 5, regexChar: /^[\w\s]+$/g }">
                  <p class="text-caption text-left mb-2">
                    <span class="text-body-2 font-weight-bold text--background"> {{$vuetify.lang.t('$vuetify.campaign.name')}}</span>
                  </p>
                  <v-text-field
                                class="background--text"
                                required
                                outlined
                                counter="60"
                                background-color="white"
                                v-model="campaign.name"
                                :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-0">
          
                <ValidationProvider v-slot="{ errors }" name="Send date" rules="required" mode="eager">

                  <p class="text-caption text--background text-left mb-2">
                    <span class="text-body-2 font-weight-bold text--background"> {{$vuetify.lang.t('$vuetify.campaign.scheduleTime')}}</span>
                  </p>
                  <custom-datetime type="datetime"
                                   class="background--text"
                                   v-model="campaign.scheduleTime"
                                   auto
                                   :min-datetime="luxonDateTime.local().plus({ minutes: 10 }).toISO()"
                                   :text-field-props="{
                                     outlined: true,
                                     backgroundColor: 'white',
                                     appendIcon: 'mdi-calendar-question',
                                     errorMessages: errors
                              }"
                  ></custom-datetime>
                </ValidationProvider>
              </v-col>
              <v-col cols="6" class="py-0">
                <p class="text-caption text--background text-left mb-0 py-11">
                  <span class="text-body-2 text--background"> {{getTimezone()}}</span>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="11" class="py-0">
                <p class="text-caption text-left mb-2">
                  <span class="text-body-2 font-weight-bold text--background"> {{$vuetify.lang.t('$vuetify.campaign.media')}}</span>
                </p>
                <ValidationProvider v-slot="{ errors }" name="MMS Image" :rules="typeof campaign.media === 'string' ? null: 'size:600|maxdimensions:610,810'">
                  <v-file-input
                          show-size
                          accept="image/png, image/jpeg, image/gif"
                          class="background--text"
                          v-model="campaign.media"
                          chips
                          outlined
                          background-color="white"
                          :error-messages="errors"
                          @change="updateRemoveFileFlag()"
                  ></v-file-input>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="11" class="py-0">

                <sms-editor
                    :title="$vuetify.lang.t('$vuetify.campaign.sms')"
                    :message.sync="campaign.sms"
                    :discountCode.sync="campaign.discountCode"
                    :messageEstimatedSegment.sync="campaign.messageEstimatedSegment"
                    :tags ="tags"
                />

              </v-col>
            </v-row>
            <v-row>
              <v-col cols="11" class="pt-0 pb-4">
                <listSegmentSelector :list.sync="campaign.list"/>
              </v-col>
            </v-row>

          </v-container>
        </ValidationObserver>

        <div class="align-right">
          <v-item-group class="v-btn-toggle">
            <v-btn  color="primary"
                    @click="scheduleCampaign(true)"
                    :disabled='buttonDisable'>
              {{ this.$vuetify.lang.t('$vuetify.campaign.submit') }}
            </v-btn>
            <v-menu bottom left offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                    color="primary"
                    v-on="on"
                    :disabled='buttonDisable'
                >
                  <v-icon >mdi-menu-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item  @click="scheduleCampaign(false)" :disabled='buttonDisable'>
                  <v-list-item-title class="background--text">
                    <v-icon>mdi-file-edit-outline</v-icon>
                    {{ this.$vuetify.lang.t('$vuetify.campaign.draft') }}
                  </v-list-item-title>
                </v-list-item>

                <send-test/>
              </v-list>
            </v-menu>
          </v-item-group>
        </div>
       
      </v-col>

      <v-col cols="5">
        <messageViewer class="pt-16 mt-16 pt-sm-2"
                       :sms.sync="this.campaign.sms"
                       :media.sync="this.campaign.media"
                       :show-estimates="true"
                       :discountCode.sync="campaign.discountCode"
                       :messageEstimatedSegment.sync="campaign.messageEstimatedSegment"
                       :type-id.sync="campaign.list.type_id"></messageViewer>

      </v-col>

    </v-row>
  </secure-content>
</template>

<script>

  import { DateTime as LuxonDateTime } from 'luxon'
  import {EventBus} from "../../packs/app";
  import customDatetime from "../base/customDatetime";

  import campaignConnector from "../../connectors/campaignConnector";

  import secureContent from '../secure-content.vue'
  import messageViewer from './message-viewer'
  import router from "../../router";

  import smsEditor from "../base/smsEditor";
  import discounts from "../../models/discounts";
  import sendTest from "./send-test"
  import listSegmentSelector from "../segment/list-segment-selector"

  export default {
    name: "create-broadcast-campaign-viewer",
    components: {
      secureContent,
      customDatetime,
      messageViewer,
      smsEditor,
      sendTest,
      listSegmentSelector
    },
    props: {

    },
    data() {
      return {
        buttonDisable: false,
        luxonDateTime: LuxonDateTime,
        campaign: {
          name: '',
          scheduleTime: '',
          media: [],
          sms: '',
          removeMedia: false,
          list: {},
          discountCode: null,
          messageEstimatedSegment: 1
        },
        smsProcessed: '',
        windowHeight: 10,
        lists: [],
        tags: [
          {
            name: "brand_name",
            required: false,
            help:'<b>{{brand_name}}</b><br><br> Dynamically places your brand name to the beginning <br> of your message so your customers know who is texting them.'
          },
          {
            name: "discount_code",
            required: false,
            help:"<b>{{discount_code}}</b><br><br> Dynamically places the discount code you select from <br> your Shopify store so that you can track ShopSend sales that aren't made via mobile."
          }
        ]
      }
    },
    mounted(){
      if(this.$route.params.campaignId) {
        campaignConnector.findCampaignDetailsToEdit(this, this.$route.params.campaignId)
      }

      EventBus.$on('send-test', async (data) => {
        let campaign = await this.prepareCampaignToSave(false, true)
        if(campaign) {
          campaign.newContact = data.newContact
          campaign.phone = data.phone
          campaign.email = data.email
          campaignConnector.sendTestCampaign(this, campaign)
        }else {
          EventBus.$emit('flash-message', {
            text: "Please review the campaign details before sent a test message.",
            color: 'info',
            textColor: 'white',
            outlined: false
          });
        }
      });

    },
    methods: {
      getTimezone() {
        let date = new Date()
        var sign = (date.getTimezoneOffset() > 0) ? "-" : "+";
        var offset = Math.abs(date.getTimezoneOffset());
        var hours = this.pad(Math.floor(offset / 60));
        var minutes = this.pad(offset % 60);
        return Intl.DateTimeFormat().resolvedOptions().timeZone +" (UTC" +sign + hours + ":" + minutes+")";
      },
      pad(value) {
        return value < 10 ? '0' + value : value;
      },
      updateRemoveFileFlag() {
        if(this.campaign.media === undefined || this.campaign.media === null){
          this.campaign.removeMedia = true
          this.campaign.media = null
          EventBus.$emit('file-attached', {isMms: false})
        }else {
          this.campaign.removeMedia = false
          EventBus.$emit('file-attached', {isMms: true})
        }
      },
      receiveCampaignToEdit(response){
        if(response.media === ""){
          response.media = null
        }
        response.removeMedia = false
        response.id = this.$route.params.campaignId
        this.campaign = response;
        EventBus.$emit('update-sms',{message: this.campaign.sms})
        EventBus.$emit('update-list-or-segment',{message: response.list})
      },

      async prepareCampaignToSave(schedule, isTest){
        let cloneCampaign = { ...this.campaign }
        if (typeof cloneCampaign.media === 'string' && !isTest){
          cloneCampaign.media = null
        }
        let result = await this.$refs.vCampaign.validate()

        if (result) {

          let discountsFounded = await discounts.validateDiscountHardcoded(cloneCampaign.sms, this, cloneCampaign.discountCode)
          if (discountsFounded === false) {
            return null
          }

          cloneCampaign.discountCodesFounded = discountsFounded

          if (schedule) {
            cloneCampaign.status = 'scheduled'
          } else {
            cloneCampaign.status = 'draft'
          }
          cloneCampaign.list_or_segment = cloneCampaign.list.type_id

          if (cloneCampaign.discountCode === null) {
            delete cloneCampaign.discountCode;
          }
          return cloneCampaign
        }else {
          return null
        }
      },
      async scheduleCampaign(schedule) {
        this.buttonDisable = true
        let cloneCampaign = await this.prepareCampaignToSave(schedule,false)

        if(cloneCampaign){
          if(this.$route.params.campaignId) {
            campaignConnector.update(this, cloneCampaign)
          }else {
            campaignConnector.schedule(this, cloneCampaign)
          }
        }else {
          this.buttonDisable = false
        }
      },
      showResultOfAction(response){
        if(response.result) {
          this.campaign = {
            name: '',
            scheduleTime: '',
            media: [],
            sms: '',
            removeMedia: false,
            list: {},
            discountCode: null
          }
          this.$refs.vCampaign.reset()
          EventBus.$emit('flash-message', {
            text: this.$vuetify.lang.t('$vuetify.campaign.scheduled'),
            color: 'info',
            textColor: 'white',
            outlined: false
          });
          router.push('/campaigns')
        }
        this.buttonDisable = false
      }
    }
  }
</script>

<style>

</style>