<template>

  <v-main class="clearbackground pt-0 pl-0">
    <v-container fluid>
      <v-layout>
        <v-flex>
          <v-row>
            <v-col class="pl-0 pr-0 pt-0 pb-0">
              <v-row class="pa-0 mr-0">
                <v-col cols="4" class="pa-0 mr-0">
                  <v-card tile
                          color="background"
                          max-height='100vh'
                          min-height='100vh'
                          class="disable-events pa-0 mr-0 square"
                  >
                    <br>
                    <v-card-title class="pt-16 pb-16">
                      <p class="text-h3 pt-16 pl-16 secondary--text">{{$vuetify.lang.t('$vuetify.profile.welcome')}}</p>
                      <p class="text-h3 pl-16 ml-16 secondary--text">{{$vuetify.lang.t('$vuetify.profile.shopsend')}}</p>
                    </v-card-title>

                    <v-row class="pl-0 ml-n16">
                      <img :src="squareLogo" alt="logo" width="330px" height="512px">
                    </v-row>
                  </v-card>
                </v-col >
                <v-col cols="8" class="pa-0 mr-0">
                  <v-card tile
                          class="pa-15 mr-0 square"
                          height='100vh'>

                    <p class="text-h4 text--background text-center mb-0 mt-15 pt-15">{{$vuetify.lang.t('$vuetify.info.hi')}}</p>

                    <p class="text-h5 text--background text-center mb-0 pt-10">{{$vuetify.lang.t('$vuetify.info.text')}}</p>

                    <p class="text-subtitle-1 text--background text-center mb-0 pa-10">{{$vuetify.lang.t('$vuetify.info.sub')}}</p>

                    <v-card-actions class="justify-center pt-15">
                      <v-btn color="primary" class='text-center' @click="clickRequest()">
                        <div class="pa-12">
                          {{$vuetify.lang.t('$vuetify.info.request')}}
                        </div>
                      </v-btn>
                    </v-card-actions>

                    <p class="text-subtitle-2 text--background text-center mb-0 pa-10">
                      {{$vuetify.lang.t('$vuetify.info.reach')}}
                      <a href="mailto: hello@shopsend.io">hello@shopsend.io</a>
                    </p>

                  </v-card>
                </v-col>


              </v-row>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>


</template>

<script>

  import logo from "../../images/shopsendiconbeige.svg"

  export default {
    name: "unknownCustomer",
    components: {
    },
    data () {
      return {
        squareLogo: logo
      }
    },
    methods: {
      clickRequest(){
        window.location.href = "https://www.shopsend.io/signup";
      }
    },
    created() {

    },
  }
</script>

<style scoped>
  .disable-events {
    pointer-events: none;
  }
  .square {
    border-radius: 0px !important;
  }

  .container {
    padding: 0px !important;
  }
</style>