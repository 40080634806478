import connector from './connector'

const LIST_PLANS_URI = '/billing/plans';
const SETUP_URI = '/billing/setup';
const SUBSCRIBE_URI = '/billing/subscribe';

const stripeConnector = {

  listPlans (component) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'get',
            url: LIST_PLANS_URI,
          },function (component,response) {
            console.log('success')
            resolve(response.data.response);
          },
          false,
          null,
          function (component, error) {
            console.log('fail')
            component.handleFail(error)
          });
      }
    );
  },
  setupCustomer (component) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'get',
            url: SETUP_URI,
          },function (component,response) {
            resolve(response.data.response);
          },
          false,
          null,
          function (component, error) {
            component.handleFail(error)
          });
      }
    );
  },
  subscribe (component,plan) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'post',
            url: SUBSCRIBE_URI,
            data: plan
          },function (component,response) {
            resolve(response.data.response);
          },
          false,
          null,
          function (component, error) {
            component.handleFail(error)
          });
      }
    );
  },

 };
export default stripeConnector
