import { render, staticRenderFns } from "./on-board-new-customer.vue?vue&type=template&id=38b4bb02&scoped=true&"
import script from "./on-board-new-customer.vue?vue&type=script&lang=js&"
export * from "./on-board-new-customer.vue?vue&type=script&lang=js&"
import style0 from "./on-board-new-customer.vue?vue&type=style&index=0&id=38b4bb02&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38b4bb02",
  null
  
)

export default component.exports