<template>
  <div>
    <ValidationObserver ref="vDisplayOptions" v-slot="{ validate, reset }">
      <v-row class="ml-0 pt-16 pl-16">
        <div class="text-h6 font-weight-bold background--text">
          {{$vuetify.lang.t('$vuetify.displaySettings.title')}}
        </div>
      </v-row>
      <v-row class="ml-0 pl-16">
        <div class="text-body-1 background--text">
          {{$vuetify.lang.t('$vuetify.displaySettings.description')}}
        </div>
      </v-row>
      <v-row class="ml-0 pt-12 pl-16 pb-2">
        <div class="text-body-1 background--text">
          {{$vuetify.lang.t('$vuetify.displaySettings.when')}}
        </div>
      </v-row>

      <v-row class="ml-0 pl-16 d-flex justify-start">
        <div class="pl-4 pt-2 pr-4">
          <span class="text-body-2 background--text">{{$vuetify.lang.t('$vuetify.displaySettings.landing')}}</span>
        </div>
        <div>
          <ValidationProvider v-slot="{ errors }" name="wait" :rules="{ required: true, min_value: 0, max_value: 10}">
            <v-text-field v-model="options.show.wait" outlined dense type="number" min="0" max="10"
                          :error-messages="errors"/>
          </ValidationProvider>

        </div>
        <div class="pl-4 pt-2 pr-4">
          <span class="text-body-2 background--text">{{$vuetify.lang.t('$vuetify.displaySettings.seconds')}}</span>
        </div>
      </v-row>

      <v-row class="ml-0 pl-16">
        <div class="text-body-1 background--text">
          {{$vuetify.lang.t('$vuetify.displaySettings.how')}}
        </div>
      </v-row>

      <v-row class="ml-0 pl-16">
        <v-radio-group
            v-model="options.frequency"
            mandatory
            class="pl-4"
        >
          <v-radio
              :label="$vuetify.lang.t('$vuetify.displaySettings.everyPage')"
              value="every_page"
          >
            <template v-slot:label>
              <span class="text-body-2 background--text">{{ $vuetify.lang.t('$vuetify.displaySettings.everyPage') }} </span>
            </template>
          </v-radio>
          <v-radio
              value="every_session"
          >
            <template v-slot:label>
              <span class="text-body-2 background--text">{{ $vuetify.lang.t('$vuetify.displaySettings.everySession') }} </span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-row>

      <v-row class="ml-0 pl-16">
        <div class="text-body-1 background--text">
          {{$vuetify.lang.t('$vuetify.displaySettings.stop')}}
        </div>
      </v-row>

      <v-row class="ml-0 pl-16">
        <v-radio-group
            v-model="options.stop_on.option"
            mandatory
            class="pl-4"
        >
          <v-radio
              value="never"
          >
            <template v-slot:label>
              <span class="text-body-2 background--text">{{ $vuetify.lang.t('$vuetify.displaySettings.never') }} </span>
            </template>
          </v-radio>
          <v-radio
              value="stop_showing"
          >
            <template v-slot:label>
              <span class="text-body-2 background--text">{{ $vuetify.lang.t('$vuetify.displaySettings.stopShow') }} </span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-row>
      <v-row class="ml-0 mt-0 pl-16">
        <v-radio-group
            v-model="options.stop_on.details.option"
            mandatory
            :disabled="options.stop_on.option === 'never'"
            class="pl-8 mt-0"
        >
          <v-radio
              value="after_signed"
              class="mb-0"
          >
            <template v-slot:label>
              <span class="text-body-2 background--text">{{ $vuetify.lang.t('$vuetify.displaySettings.afterSigned') }} </span>
            </template>
          </v-radio>
          <v-radio
              value="after_seen"
              class="mt-n6"
          >
            <template v-slot:label>

              <div class="d-flex justify-start">
                <div class="pt-9 pr-4">
                  <span class="text-body-2 background--text">{{$vuetify.lang.t('$vuetify.displaySettings.afterSeen')}}</span>
                </div>
                <div class="pt-6">
                  <ValidationProvider v-slot="{ errors }" name="times" :rules="{min_value: 1, max_value: 10}">
                    <v-text-field v-model="options.stop_on.details.times" outlined dense
                                  type="number" min="1" max="10" :error-messages="errors"/>
                  </ValidationProvider>
                </div>
                <div class="pl-4 pt-9 pr-4">
                  <span class="text-body-2 background--text">{{$vuetify.lang.t('$vuetify.displaySettings.times')}}</span>
                </div>
              </div>

            </template>
          </v-radio>
        </v-radio-group>
      </v-row>

      <v-row class="ml-0 pl-16">
        <v-btn class="pl-6 pr-10" color="primary" @click="saveOptions()"  :disabled='buttonDisable'>
          <v-icon
              small
              class="px-4"
          >
            mdi-lock
          </v-icon>
          {{$vuetify.lang.t('$vuetify.displaySettings.save')}}
        </v-btn>
      </v-row>
    </ValidationObserver>

  </div>
</template>

<script>
import popupConnector from "../../connectors/popupConnector";
import {EventBus} from "../../packs/app";

export default {
  name: "popup-display-settings",
  props: {
    popupId: {
      type: Number,
      default: null
    },
    baseOptions: {
      type: Object,
      default: () => {
        return {
          show: {
            wait: 3,
            option: "any_page"
          },
          frequency: "every_session", //every_session
          stop_on: {
            option: "stop_showing", //never
            details: {
              option: "after_seen", //after_signed
              times: 5
            }
          }
        }
      }
    },
  },
  data () {
    return {
      buttonDisable: false,
      options: this.baseOptions
    }
  },
  mounted() {

  },
  methods: {
    async saveOptions() {
      this.buttonDisable = true
      let validationResult = await this.$refs.vDisplayOptions.validate()
      if(validationResult){
        let result = await popupConnector.saveDisplayOptions(this,{display_options: this.options, popup_id: this.popupId})
        EventBus.$emit('flash-message', {
          text: "Configuration Saved.",
          color: 'info',
          textColor: 'white',
          outlined: false
        });
      }

      this.buttonDisable = false
    }
  }
}
</script>

<style scoped>

</style>