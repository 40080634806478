<template>
  <v-row class="pl-10 pr-10">
    <v-col cols="12">
        <div class="container">
          <canvas ref="canvas" height="300"></canvas>
        </div>
    </v-col>
  </v-row>

</template>

<script>

  import { Bar, mixins } from 'vue-chartjs'
  const { reactiveProp } = mixins

  export default {
    name: "bar-chart",
    extends: Bar,
    mixins: [reactiveProp],
    data () {
      return {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            titleFontSize: 16,
            bodyFontSize: 12,
            fontFamily: "'Sf pro text', sans-serif",
            callbacks: {
              label: function(t, d) {
                let xLabel = d.datasets[t.datasetIndex].label;
                let yLabel = d.datasets[t.datasetIndex].data[t.index];

                let formatter = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  // These options are needed to round to whole numbers if that's what you want.
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });

                return xLabel + ': ' + formatter.format(yLabel);
              }
            }
          },
          legend: {
            position: "top",
            align: "center",
            labels: {
              //usePointStyle: true,
              boxWidth: 12,
              fontSize: 12,
              fontFamily: "'Sf pro text', sans-serif"
            }
          },
          scales: {
            yAxes: [{
              stacked: true,
              gridLines: {
                drawBorder: false,
                borderDash: [2, 4],
                color: "#BBCADD"
              },
              ticks: {
                fontSize: 12,
                fontFamily: "'Sf pro text', sans-serif",
                fontColor: this.$vuetify.theme.themes['light'].background,
                fontStyle: '500',
                precision:0,
                maxTicksLimit: 10,
                padding: 15,
                beginAtZero: true,
                userCallback: function(value, index, values) {
                  var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    // These options are needed to round to whole numbers if that's what you want.
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                  return formatter.format(value);
                }
              }
            }],
            xAxes: [{
              stacked: true,
              gridLines: {
                display: false
              },
              ticks: {
                fontSize: 12,
                fontFamily: "Sf pro text', sans-serif",
                fontColor: this.$vuetify.theme.themes['light'].background,
                fontStyle: '500',
                callback: function(value, index, values) {
                  let days = 1
                  if (values.length === 90){
                    days = 7
                  }else if (values.length === 30){
                    days = 2
                  }

                  if(index % days === 0 ){
                    return value
                  } else {
                    return ""
                  }
                }
              }
            }]
          }
        }
      }
    },
    mounted () {
      this.renderChart(this.chartData, this.options)
    }
  }
</script>

<style scoped>

</style>