import Vue from 'vue';
import ActionCableVue from 'actioncable-vue';

let server = window.location.hostname;
if (window.location.port !== '') {
  server = server + ':' + window.location.port;
} else {
  server = server;
}
let protocol = 'ws'
if(window.location.protocol==='https:'){
  protocol = 'wss'
}

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'debug',
  connectionUrl: protocol+'://'+server+'/api/v1/cable', // or function which returns a string with your JWT appended to your server URL as a query parameter
  connectImmediately: true,
});