<template>
    <v-main class="gradient" style="height: 100vh;">
        <v-row>
          <v-col  cols="3" />
          <v-col  cols="6">
            <v-row
                    align="end"
                    justify="center"
                    :style='"height: " + (windowHeight/4) + "px;"'
            >
              <a href="https://www.shopsend.io/">
                <img :src="logoWhite" alt="logo" width="200px" height="40px" class="margins">
              </a>
            </v-row>
            <v-row
                    align="center"
                    justify="center"
                    :style='"height: " + (windowHeight/4)*2 + "px;"'
            >

              <v-card outlined
                      class="mx-auto"
                      min-width="500"
                      min-height="400">

                <v-card-title class="headline justify-center margins">
                  <br>
                  <span class="text-h5 font-weight-bold">{{$vuetify.lang.t('$vuetify.login.login')}}</span>
                </v-card-title>
                <div v-if="type === 'password'">
                  <login-form/>
                </div>
                <div v-else>
                  <login-with-token :source="type"/>
                </div>

              </v-card>
            </v-row>

          </v-col>

          <v-col cols="3"/>
        </v-row>
      <v-row
              align="end"
              justify="center"
              :style='"height: " + (windowHeight/4.45) + "px;"'
      >
      </v-row>
    </v-main>
</template>

<script>
  import logoWhite from '../../images/shopsend-logo.svg'
  import loginForm from './login-form'
  import loginWithToken from './login-token'

  export default {
    name: "login",
    props: {
      type: {
        type: String,
        default: 'password'
      },
    },
    components: {
      loginForm,
      loginWithToken
    },
    data () {
      return {
        logoWhite: logoWhite,
        windowHeight: 10
      }
    },
    created() {
      window.addEventListener('resize', this.onResize)
      this.onResize();
    },
    destroyed() {
      window.removeEventListener('resize', this.onResize)
    },
    methods: {
      onResize() {
        this.windowHeight = window.innerHeight
      },

    }
  }
</script>

<style>
  .margins {
    margin: 20px 70px;
  }
  .gradient {
    /*background-image: linear-gradient(180deg, #998efc, var(--v-primary-base));*/
    background-color: var(--v-background-base);
  }
  .container {
    padding: 0px !important;
  }
</style>