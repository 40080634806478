<template>
    <v-footer style="z-index: 10" app color="#1D2E44" dark>
        <span>&copy; {{ (new Date).getFullYear() }}</span>
    </v-footer>
</template>

<script>
  export default {
    name: "app-footer"
  }
</script>

<style scoped>

</style>