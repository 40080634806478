<template>
  <secure-content :show-back="false">
    <div v-if="loaded">
      <div v-if="!!company.shopify_domain">
        <dashboard-new :company="company"/>
      </div>
      <div v-else>
        <dashboard-old :company="company"/>
      </div>
    </div>
    </secure-content>

</template>

<script>

  import dashboardOld from "./dashboard-old"
  import dashboardNew from './dashboard-new'

  import secureContent from '../secure-content.vue'
  import companyConnector from "../../connectors/companyConnector"

  export default {
    name: "dashboard",
    components: {
      dashboardOld,
      dashboardNew,
      secureContent
    },
    data () {
      return {
        loaded: false,
        company: {}
      }
    },
    methods: {
      receiveCompany(company){
        this.company = company
        this.$store.commit('setCompany', company);
        this.loaded = true
      },
    },
    mounted() {
      companyConnector.findCompany(this);
    }
  }
</script>

<style>

</style>