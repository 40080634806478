<template>
  <secure-content :show-back="false">
    <v-row>
      <v-col offset='1' class="pb-2 pt-4 justify-center text-center">
        <span class="text-body-1 background--text">{{$store.getters.company.name}} Conversations</span>
        <v-chip
            class="ma-1 mt-0 text-capitalize"
            color="primary"
            text-color="white"
        >
          BETA
        </v-chip>
      </v-col>
      <v-col cols="2">
        <conversations-options/>
      </v-col>
    </v-row>
    <v-row class="pt-0">
      <v-col cols="3" class="pt-0 pl-4">

        <div class="shopsendBorder">
          <v-tabs
              background-color="primary"
              dark
              fixed-tabs
              v-model="tabIndex"
              style="border-top-left-radius: 10px; border-top-right-radius: 10px"
              @change="changeTab($event)"
          >
            <v-tab
                v-for="tab in tabsContent"
                :key="tab"
            >
              {{ tab }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabIndex">
            <v-tab-item
                v-for="tab in tabsContent"
                :key="tab"
            >
              <conversation-list :content-type="tab" :enable="true"/>

            </v-tab-item>
          </v-tabs-items>
        </div>

      </v-col>
      <v-col cols="6" class="pt-3">

        <conversation-viewer v-if="conversation"
            :messages.sync="conversation.messages"
            :from-phone.sync="conversation.company_phone"
            :to-phone.sync="conversation.contact_phone"
             :enabled ="true"
             :contentType ="this.tabsContent[this.tabIndex]"

        />

      </v-col>
      <v-col cols="3" class="pt-0">
        <div v-if="conversation">
          <conversations-contact :phone.sync="conversation.contact_phone" />
        </div>
        <div v-else>
          <conversations-contact />
        </div>
        <div v-if="conversation">
          <conversations-orders :email.sync="conversation.contact_email"/>
        </div>
        <div v-else>
          <conversations-orders />
        </div>



        <v-row class="pt-6 pl-4" align="center"
               justify="center">
          <v-btn color="background" small class="secondary--text mr-4" rounded @click="closeConversation()" :disabled="!true || tabIndex === 1">Mark as resolved</v-btn>
          <v-btn color="error" small outlined rounded @click="optContactOut()" :disabled="!true">Opt Contact Out</v-btn>
        </v-row>

      </v-col>
    </v-row>

  </secure-content>
</template>

<script>

  import secureContent from '../secure-content.vue'
  import conversationViewer from './conversation-viewer'
  import conversationList from './conversations-list'
  import conversationsOptions from './conversations-options'
  import conversationsContact from './conversations-contact'
  import conversationsOrders from './conversations-orders'
  import contactConnector from "../../connectors/contactConnector"
  import conversationConnector from "../../connectors/conversationConnector"
  import {EventBus} from "../../packs/app"

  export default {
    name: "conversations",
    components: {
      secureContent,
      conversationViewer,
      conversationList,
      conversationsOptions,
      conversationsOrders,
      conversationsContact
    },
    data () {
      return {
        loaded: false,
        testing: false,
        pagination_query: {
          page_size: 20,
          page_number: 0,
          order: 'DESC'
        },
        conversation: {},
        tabIndex:0,
        mustReloadArchive: false,
        tabsContent: ['Active','Archived']
      }
    },
    methods: {
      changeTab(event){
        EventBus.$emit('changed-tab-for', this.tabsContent[event])
        if(this.tabsContent[event]==='Archived' && this.mustReloadArchive){
          this.mustReloadArchive = false
          EventBus.$emit('reload-conversations', {
            contentType: "Archived",
            changeConversation: true
          })
        }
      },
      async closeConversation(){
        let result = await conversationConnector.closeConversation(this,this.conversation.id)
        if(result){
          //remove from active
          EventBus.$emit('remove-conversation',{
            contentType: "Active",
            reload: "Archived",
            conversation: this.conversation
          })

          EventBus.$emit('flash-message', {
            text: "Conversation resolved",
            color: 'info',
            textColor: 'white',
            outlined: false
          });
        }
      },
      async optContactOut() {
        let result = await contactConnector.optedOutContact(this,
            this.conversation.contact_id,
            "Opted Out by conversations")

        EventBus.$emit('remove-conversation',{
          contentType: this.tabsContent[this.tabIndex],
          reload: null,
          conversation: this.conversation
        })

        EventBus.$emit('flash-message', {
          text: "Contact Opted Out",
          color: 'info',
          textColor: 'white',
          outlined: false
        });
      },
    },

    async mounted(){

      EventBus.$on('change-conversation', (data) => {
        this.conversation = data.conversation
      });

      EventBus.$on('reload-active-view', () => {
        this.tabIndex = 0
        EventBus.$emit('reload-conversations', {
          contentType: "Active",
          changeConversation: true
        })
        EventBus.$emit('reload-conversations', {
          contentType: "Archived",
          changeConversation: false
        })
      })

      EventBus.$on('new-conversation', (data) => {
        this.mustReloadArchive = true
      })

      this.loaded = true
    }
  }
</script>
<style scoped>

.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}


</style>