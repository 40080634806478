<template>
  <img :src="dsNew">
</template>

<script>
  import dsNew from '../../images/icons/ds-new.svg'

  export default {
    name: "ds-new",
    components: {
      dsNew
    },
    data () {
      return {
        dsNew: dsNew,

      }
    }
  }
</script>

<style scoped>

</style>