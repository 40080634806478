import connector from './connector'
import {EventBus} from "../packs/app";

const CONTACT_URI = '/contact';
const CONTACT_STATUS_URI = CONTACT_URI+'/status';

const contactConnector = {

  // this method must have this structure in order to works with the paginatedTable component
  findItems (component,query,callback) {
    connector.send(component,
    {
      method: 'post',
      url: CONTACT_URI,
      data: query
    },function (component,response) {
      callback(response.data.response);
    });
  },

  //actions

  async optedOutContact(component, contactId, note) {

    const choice = await component.$confirm(component.$vuetify.lang.t('$vuetify.contactActions.optedOutConfirm.message'),
      {
        subtitle: component.$vuetify.lang.t('$vuetify.contactActions.optedOutConfirm.subtitle'),
        buttonFalseText: component.$vuetify.lang.t('$vuetify.contactActions.optedOutConfirm.cancel'),
        buttonTrueText: component.$vuetify.lang.t('$vuetify.contactActions.optedOutConfirm.ok')
      })
    if (choice){
      return await this._optedOutContact(component,contactId, note)
    }
  },

  _optedOutContact(component, contactId, note){
    return new Promise((resolve, reject) => {
      connector.send(component,
        {
          method: 'put',
          url: CONTACT_STATUS_URI,
          data: { contactId, status: "OptedOut",note }
        },function (component,response) {
          EventBus.$emit('update-contact-list');
          resolve(response.data.response)
        });
    })
  },

  searchContact (component,phone) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'get',
            url: CONTACT_URI + "?phone=" + phone
          },function (component,response) {
            resolve(response.data.response);
          });
      }
    );
  },

 };
export default contactConnector
