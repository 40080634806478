<template>
  <div>
    <v-row justify="start" align="center" class="pl-10 pr-11 pt-4 d-flex">
      <v-col cols="2" class="mr-auto">
        <span class="title background--text"> {{title}} </span>
      </v-col>

      <div class="pb-0 pt-7 px-2" v-if="enableSearch">
        <v-text-field
            outlined
            dense
            label="Search for a contact"
            background-color="clearbackground"
            v-model="searchText"
            :loading="loading"
            @input="searchEvent()"
        ></v-text-field>
      </div>

      <div class="pb-0 pt-0 px-2" v-if="csvReportType">
        <reportPicker :report-type="csvReportType"/>
      </div>

      <div class="pb-0 pt-0 px-2" v-if="enableNew">
        <v-btn right color="primary" @click="newItem()">
            + New {{kind}}
        </v-btn>
      </div>
    </v-row>

    <v-row  align="center"
            justify="center" class="pl-10 pr-10">

      <v-col cols="12" :class="enableSearch ? 'mt-n6':'mt-n2'">
        <v-data-table class="shopsendElevationTable"
                :headers="headers"
                :items="items"
                :page.sync="pageNumber"
                :items-per-page="pageSize"
                :loading="loading"
                hide-default-footer
                @page-count="pageNumber = $event"
        >

          <template v-slot:body="props">
            <tr v-for="(item,idx) in props.items">
              <td  :class="getPadding(props.items.length)" v-for="(header,idx) in headers">
                <div v-if="header.type === 'date'">
                  <dynamic-link :text-to-show="formatDate(item[header.value])" :link="header.link" :item="item" class="background--text"></dynamic-link>
                </div>
                <div v-else-if="header.type === 'datetime'">
                  <dynamic-link :text-to-show="formatDatetime(item[header.value])" :link="header.link" :item="item" class="background--text"></dynamic-link>
                </div>
                <div v-else-if="header.type === 'percentage'">
                  <dynamic-link :text-to-show="item[header.value]+'%'" :link="header.link" :item="item" class="background--text"></dynamic-link>
                </div>
                <div v-else-if="header.type === 'chip'">
                  <v-chip
                          class="ma-1 text-capitalize "
                          :color="getChipColor(item[header.value])"
                          text-color="white"
                  >
                    <dynamic-link :text-to-show="item[header.value]" :link="header.link" :item="item"></dynamic-link>
                  </v-chip>
                </div>
                <div v-else-if="header.type === 'switch'">
                  <div v-if="header.model === 'campaign'">
                    <automated-campaign-status :campaign="item"/>
                  </div>
                  <div v-else-if="header.model === 'popup'">
                    <popup-status :popup="item"/>
                  </div>

                </div>
                <div v-else-if="header.type === 'actions'">
                  <div v-if="header.value === 'campaign'">
                    <campaign-actions :campaign="item"/>
                  </div>
                  <div v-else-if="header.value === 'contact'">
                    <contact-actions :contact="item"/>
                  </div>
                  <div v-else-if="header.value === 'popup'">
                    <popup-actions :popup="item"/>
                  </div>
                </div>

                <div v-else>
                  <div v-if="header.value === 'full_name'">
                    <dynamic-link :text-to-show="prepareName(item)" :link="header.link" :item="item" class="background--text"></dynamic-link>
                  </div>
                  <div v-if="header.value === 'phone'">
                    <dynamic-link :text-to-show="formatPhoneNumber(item['phone'])" :link="header.link" :item="item" class="background--text"></dynamic-link>
                  </div>
                  <div v-else>
                    <dynamic-link :text-to-show="item[header.value]" :link="header.link" :item="item" class="background--text"></dynamic-link>
                  </div>

                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row  align="center"
            justify="center">
      <v-col cols="12" v-show="moreThanOnePage">
        <div class="text-center pt-2">
          <v-pagination v-model="pageNumber" total-visible="10" :length="totalPages" @input="changePage"></v-pagination>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

  import {EventBus} from "../../packs/app";
  import dynamicLink from "./dynamicLink";
  import campaignActions from "../campaign/campaign-actions"
  import contactActions from "../contact/contact-actions"
  import popupActions from "../popup/popup-actions"
  import automatedCampaignStatus from "../campaign/automated-campaign-status"
  import reportPicker from "./reportPicker";
  import popupStatus from "../popup/popup-status";
  import router from "../../router";

  export default {
    name: "paginated-table",
    props: {
      title: {
        type: String,
        default: ''
      },
      kind: {
        type: String,
        default: ''
      },
      headers: {
        type: Array,
        default: () => []
      },
      dataSource: {
        type: Object,
        default: null
      },
      baseQuery: {
        type: Object,
        default: () => {}
      },
      pageSize: {
        type: Number,
        default: 10
      },
      order: {
        type: String,
        default: 'ASC'
      },
      filter: {
        type: Object,
        default: () => {}
      },
      enableSearch: {
        type: Boolean,
        default: false
      },
      enableNew: {
        type: Boolean,
        default: false
      },
      csvReportType: {
        type: String,
        default: null
      },
      updateEvent: {
        type: String,
        default: null
      },
      chipColors: {
        type: Object,
        default: ()=> ({
          "draft": "#AAAAAA",
          "scheduled": "#52CCB7",
          "processing": "#D46AC8",
          "completed": "#357DED"
        })
      }
    },
    components: {
      popupStatus,
      dynamicLink,
      campaignActions,
      contactActions,
      popupActions,
      automatedCampaignStatus,
      reportPicker
    },
    data () {
      return {
        pageNumber: 0,
        totalPages: 0,
        loading: true,
        moreThanOnePage: false,
        items: [],
        searchFilter: {},
        searchText: "",
        pendingSearchText: ""
      }
    },
    methods: {
      getPadding(length){
        if(length === 1){
          return "px-4 py-4"
        }else{
          return "px-4 py-1"
        }
      },
      getChipColor(text) {
        return this.chipColors[text]
      },
      changePage(event) {
        this.findItems()
      },
      formatDate(date) {
        const dateFormat = require('dateformat')
        const createdAt = new Date(date)
        return dateFormat(createdAt, "mm/dd/yyyy")
      },
      formatDatetime(date) {
        const dateFormat = require('dateformat')
        const createdAt = new Date(date)
        return dateFormat(createdAt, "dddd, mm/dd/yyyy @hh:MM tt")
      },
      findItems() {
        if(this.dataSource) {
          this.loading = true
          let query = {}
          if(this.baseQuery !== undefined){
            query = this.baseQuery
          }
          query.pagination_query = {
            page_size: this.pageSize,
            page_number: this.pageNumber-1 < 0 ? 0: this.pageNumber -1,
            order: this.order
          }
          query.search_filter = this.searchFilter
          query.filter = this.filter
          this.dataSource.findItems(this,query,this.receiveItems)
        }
      },
      receiveItems(response) {
        this.items = response.result
        this.totalPages = Math.ceil(response.total / this.pageSize)
        if(this.totalPages > 1){
          this.moreThanOnePage = true
        }
        this.loading = false
        if (this.searchText !== this.pendingSearchText){
          this.searchText = this.pendingSearchText //change it trigger the event
        }

      },
      searchEvent(){
        if(this.loading) {
          this.pendingSearchText = this.searchText
        }else {
          this.pendingSearchText = this.searchText
          let filter = {}
          this.headers.forEach(header => filter[header.value] = this.searchText)
          this.searchFilter = filter
          this.findItems()
        }
      },
      newItem(){
        if(this.kind === 'Tool'){
          router.push('/new-popup')
        }
      },
      prepareName(item){
        if(item['name'] === null || item['name'].length === 0){
          return "Unknown"
        }else{
          let name = item['name']
          if(item['last_name']){
            name = name + ' ' + item['last_name']
          }
          return name
        }
      }
    },
    mounted(){
      this.findItems()
      if(this.updateEvent){
        EventBus.$on(this.updateEvent, () => {
          this.findItems()
        });
      }
    }
  }
</script>
<style scoped>

</style>