<template>

    <div>
      <v-row class="">
        <v-col cols="12" class="pa-0">
        <v-list max-height='60vh' min-height='60vh' class="overflow-y-auto pa-0 pb-2 shopsendBorder" id="messageList" :disabled="!enabled" >
          <div v-for="(message,idx) in messages"
               v-bind:key="idx" class="mt-4">
            <v-row class="text-center mr-1 ml-1" v-if="message.formattedDate">
              <v-col cols="12"
                     class='caption background--text pt-2 pb-2'
                     v-html="message.formattedDate">
              </v-col>
            </v-row>

            <v-row class="mr-1 ml-1 " v-if="message.media_url">
              <v-col cols="auto"
                     v-bind:class="message.type === 'inbound' ? 'pt-0': 'ml-auto'">
                <v-img v-show="message.media_url" :src="message.media_url"
                       :height="300" :max-height="300" :max-width="216" :width="216" class="rounded-lg">
                </v-img>
              </v-col>
            </v-row>
            <v-row v-bind:class="message.type === 'inbound' ? 'text-left mr-16 ml-3': 'text-right ml-10 mr-3'"
                   class="rounded-lg mt-1 mb-1">
              <v-col cols="auto"
                     v-bind:class="message.type === 'inbound' ? 'text-color': 'me-text-color ml-auto'"
                     class='rounded caption pt-3 pb-3 pr-5 pl-5 secondary--text'
                     v-html="message.message">
              </v-col>
            </v-row>
          </div>

        </v-list>
        </v-col>
      </v-row>

      <v-row max-height='22vh' min-height='22vh' class="pt-4">
        <v-col cols="12" class="pa-0">
          <sms-editor
              title=""
              :message.sync="answer"
              :from-phone.sync="fromPhone"
              :to-phone.sync="toPhone"
              :contentType.sync ="contentType"
              conversations-mode
              :enabled.sync="enabled"
          />
        </v-col>
      </v-row>
    </div>
</template>

<script>

import smsEditor from "../base/smsEditor";

export default {
  name: "conversation-viewer",
  components: {
    smsEditor
  },
  props: {
    messages: {
      type: Array,
      default: () => []
    },
    fromPhone: {
      type: String,
      default: null
    },
    toPhone: {
      type: String,
      default: null
    },
    contentType: {
      type: String,
      default: null
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      answer: '',
      dateToShow: null,
    }
  },
  watch: {
     messages (newValue) {

       let idxUsed = 0
       this.messages[idxUsed].formattedDate = this.formatDate(this.messages[idxUsed].created_at)
       for(let i = 1; i <= this.messages.length -1 ; i++){
         let dateValue = this.formatDate(this.messages[i].created_at)
         if(dateValue === this.messages[idxUsed].formattedDate){
           this.messages[i].formattedDate = null
         }else {
           idxUsed = i
           this.messages[i].formattedDate = dateValue
         }

       }
     }
  },
  mounted() {

    this.scrollToEnd()
  },
  updated() {
    this.scrollToEnd()
  },
  methods: {
    scrollToEnd(){
      let element = document.getElementById("messageList")
      element.scrollTop = element.scrollHeight - element.clientHeight;
    },
  }
}
</script>

<style scoped>
.text-color{
  background-color: var(--v-background-base);
  white-space: pre-wrap;
}
.me-text-color{
  background-color: var(--v-primary-base);
  white-space: pre-wrap;
}

</style>