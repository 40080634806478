<template>
  <img :src="dsLogout">
</template>

<script>
  import dsLogout from '../../images/icons/ds-Logout.svg'

  export default {
    name: "ds-logout",
    components: {
      dsLogout
    },
    data () {
      return {
        dsLogout: dsLogout

      }
    }
  }
</script>

<style scoped>

</style>