import connector from './connector'
import {EventBus} from "../packs/app";

const CONVERSATION_URI = '/conversation'
const MESSAGE_URI = CONVERSATION_URI+'/message'
const MESSAGE_READ_URI = MESSAGE_URI+'/read'
const CONFIG_URI = CONVERSATION_URI+'/configuration'

const conversationConnector = {

  findConversations (component,query, type = 'all') {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'post',
            url: CONVERSATION_URI,
            data: {pagination_query: query, type: type}
          },function (component,response) {
            resolve(response.data.response);
          },false,
          null,
          null);
      }
    );
  },

  markAsReadMessages (component,ids) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'put',
            url: MESSAGE_READ_URI,
            data: {ids}
          },function (component,response) {
            resolve(response.data.response);
          },false,
          null,
          null);
      }
    );
  },

  closeConversation (component,id) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'delete',
            url: CONVERSATION_URI+'/'+id,
          },function (component,response) {
            resolve(response.data.response);
            //component.archiveConversation(response.data.response, id);
          });
      }
    );
  },

  sendMessage (component,message) {
    connector.send(component,
      {
        method: 'post',
        url: MESSAGE_URI,
        data: message
      },function (component,response) {
        component.receiveSentConfirmation(response.data.response);
      },false,null,component.handleFail());
  },
  updateConfiguration(component, params) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'put',
            url: CONFIG_URI,
            data: params
          },function (component,response) {
            resolve(response.data.response);
            //component.receiveUpdateConfigurationConfirmation(response.data.response);
          },false,
          null,
          component.handleFail());
      }
    );
  },
  loadConfigurations(component) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'get',
            url: CONFIG_URI
          },function (component,response) {
            resolve(response.data.response);
          },false,
          null,
          component.handleFail());
      }
    );
  }


 };
export default conversationConnector
