import connector from './connector'

const COMPANY_URI = '/company';

const companyConnector = {

  findCompany (component) {
    connector.send(component,
    {
      method: 'get',
      url: COMPANY_URI
    },function (component,response) {
      component.receiveCompany(response.data.response);
    });
  },

  findCompanyOnBoardingStatus(component,companyId) {
    return new Promise(
      (resolve, reject) => {
        connector.sendToPublicApi(component,
          {
            method: 'get',
            url: COMPANY_URI + '/' + companyId
          },function (component,response) {
            resolve(response.data.response);
          },
          false,
          null,
          function (component, error) {
            component.handleFail(error)
          });
      }
    );
  },

  updateCompany(component,data) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'put',
            url: COMPANY_URI,
            data: data
          },function (component,response) {
            resolve(response.data.response);
          },
          false,
          null,
          function (component, error) {
            component.handleFail(error)
          });
      }
    );
  },
  updateEcommerce(component,query){

    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'post',
            url: COMPANY_URI+ "/ecommerce",
            data: query
          },function (component,response) {
            resolve(response.data);
          },
          false,
          null,
          function (component, error) {
            console.log('fail')
            component.handleFail(error)
          });
      }
    );
  }
 };
export default companyConnector
