import connector from '../connector'

const MARKETING_BLAST_REPORT_SUMMARY_URI = '/report/marketing_blast/summary';

const marketingBlastReportSummaryConnector = {

  // this method must have this estructure in order to works with the summary component

  findItems (component,callback, params=null) {
    let queryString = ""
    if(params && Object.keys(params).length !== 0) {
      queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }
    connector.send(component,
    {
      method: 'get',
      url: MARKETING_BLAST_REPORT_SUMMARY_URI  + queryString,
    },function (component,response) {
      callback(response.data.response);
    });
  }
 };
export default marketingBlastReportSummaryConnector
