<template>

    <v-form  @keyup.native.enter="keyPress($event)" type="" class="pl-16 pr-16" autocomplete="on">
      <div class="pt-16" >
        <p class="text-subtitle-1 pl-4 text--background text-start" v-html="$vuetify.lang.t('$vuetify.company.welcome')"></p>
      </div>
      <v-card-text class="text-subtitle-1 text--background">

        <p class="text-subtitle-1 text--background text-start mb-0">{{$vuetify.lang.t('$vuetify.company.platform')}}</p>
        <v-item-group mandatory class="pb-12">
        <v-row>
            <v-col
                    v-for="store in stores"
                    :key="store.name"
                    cols="12"
                    md="3"
            >
              <v-item v-slot="{ active, toggle }">
                <v-hover v-slot="{ hover }">
                  <v-card
                          :class="defineClass(active,hover)"
                          height="150"
                          @click="setActive(store.name,toggle)"
                  >
                    <div class='flex-grow-1 text-center'>
                      <img :src="store.image" :alt="store.name" width="150px" height="36px">
                    </div>
                  </v-card>
                </v-hover>
              </v-item>

            </v-col>
        </v-row>
        </v-item-group>

        <div v-if="active === 'Shopify'">
        <ValidationObserver ref="vChange" v-slot="{ validate, reset }">
          <p class="text-subtitle-1 text--background text-start mb-0">{{$vuetify.lang.t('$vuetify.company.connect')}}</p>
          <p class="text-subtitle-1 text--background text-start mb-0">{{$vuetify.lang.t('$vuetify.company.shopifyTitle')}}</p>
          <v-row>
            <v-col cols="7">
              <ValidationProvider v-slot="{ errors }" name="Shopify Domain" vid="domain" rules="required">
                <v-text-field name="store" outlined dense autocomplete="on"
                              id="store" v-model="domain"
                              :error-messages="errors"
                              type="text"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="5">
              <p class="text-subtitle-1 text--background text-start pt-1">{{$vuetify.lang.t('$vuetify.company.shopifyDomain')}}</p>
            </v-col>
          </v-row>

        </ValidationObserver>
        </div>



      </v-card-text>
      <v-card-actions>
        <v-btn right color="primary" @click="connectStore()"  :disabled='buttonDisable'>
          <div class="pa-16">
            {{$vuetify.lang.t('$vuetify.company.finishButton')}}
          </div>
        </v-btn>

      </v-card-actions>

    </v-form>

</template>

<script>


  import companyConnector from "../../connectors/companyConnector";
  import router from "../../router";

  import imgBig from '../../images/logo-bigcommerce.png'
  import imgMagento from '../../images/logo-magento.png'
  import imgOpencart from '../../images/logo-opencart.png'
  import imgPrestashop from '../../images/logo-prestashop.png'
  import imgShopify from '../../images/logo-shopify.png'
  import imgWoo from '../../images/logo-woocommerce.png'
  import imgOther from '../../images/logo-other.png'
  import {EventBus} from "../../packs/app";
  import {BASE_URL} from "../../connectors/connector";

  export default {
    name: "setup-e-commerce",
    components: {
    },
    props: {
    },
    data () {
      return {
        domain: '',
        active: 'Shopify',
        stores: [
          {
            image: imgShopify,
            name: 'Shopify'
          },
          /*       {
                 image: imgBig,
                 name: 'Bigcommerce'
               },
             {
                 image: imgMagento,
                 name: 'Magento'
               },
               {
                 image: imgPrestashop,
                 name: 'Prestashop'
               },
               {
                 image: imgWoo,
                 name: 'Woocommerce'
               },
               {
                 image: imgOpencart,
                 name: 'Opencart'
               },
               {
                 image: imgOther,
                 name: 'Other'
               }*/
          ],
        buttonDisable: false
      }
    },
    methods: {
      setActive(storeName,toggle){
        this.active = storeName
        toggle()
      },
      async connectStore(){
        if(this.$refs.vChange){
          let result= await this.$refs.vChange.validate()
          if (result) {
            this.buttonDisable = true
            if(this.$route.path.includes('/on-board/new-costumer')){
              this.initInstallShopifyApp()
            }else {
              await this.updateEcommerce()
            }


          }
        }else {
          //any other e-commerce for now  is hardcode with shopify app
          companyConnector.updateEcommerce(this, {
            store: 'Shopify',
            domain: "noneapp123"
          })
        }
      },
      async updateEcommerce() {
        let query = {
          store: this.active,
          domain: this.domain
        }
        let result = await companyConnector.updateEcommerce(this, query)
        if(result) {

          if(this.active == "Shopify"){
            this.initInstallShopifyApp()
          }else {
            router.push('/dashboard')
            EventBus.$emit('flash-message', {
              text: this.$vuetify.lang.t('$vuetify.company.done'),
              color: 'info',
              textColor: 'white',
              html: false,
              outlined: false
            });
          }
          this.domain = ""
        }
      },
      initInstallShopifyApp(){
        this.$refs.vChange.reset()
        this.buttonDisable = false
        window.open(process.env.SHOPIFY_APP + "/login?shop="+this.domain+".myshopify.com", '_self')
      },
      defineClass(active, hover) {
        let finalClasses = 'd-flex align-center'
        if (active) {
          finalClasses += ' selected'
        }else {
          if(hover) {
            finalClasses += ' hoverEffect'
          }
        }

        return finalClasses
      },
      keyPress (event) {
        event.preventDefault()
        event.stopPropagation()
        this.updateUser()
      }
    },
    mounted() {
    }
  }
</script>

<style scoped>
  .selected {
    border: 4px solid var(--v-primary-base) !important
  }

  .hoverEffect {
    border: 4px solid var(--v-primary-lighten4) !important
  }
</style>