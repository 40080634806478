import Vue from 'vue'
import vuetify from '../plugins/vuetify'

export const EventBus = new Vue();
import { store } from '../store/store.js'

import router from '../router'

import confirmationDialog from "../plugins/confirmationDialog";
Vue.use(confirmationDialog, { vuetify })

import plansDialog from "../plugins/plansDialog";
Vue.use(plansDialog, { vuetify })

import VueTelInputVuetify from "vue-tel-input-vuetify";
Vue.use(VueTelInputVuetify, { vuetify })

import actioncable from "../plugins/actioncableVue" //required to load plugin

//other datetime picker
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
Vue.use(Datetime)
Vue.component('datetime',Datetime)

import App from '../components/app.vue'

import VueCookies from 'vue-cookies'
Vue.use(VueCookies);
VueCookies.config('1d');

Vue.component('flash-message', require('../components/base/flashMessage.vue').default);

import { ValidationProvider,ValidationObserver, extend } from 'vee-validate';
import { required,max,min,size,email,regex,confirmed,between,min_value,max_value } from 'vee-validate/dist/rules';
import dateFormat from "dateformat";
import Vuetify from "vuetify";

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
});

extend('requiredTerms', {
  ...required,
  message: "You must agree to ShopSend's Terms of Service & Privacy Policy to create an account."
});

extend('requiredCompliance', {
  ...required,
  message: "You must check the checkbox to continue."
});

extend('requiredNotEmpty', {
  message: "you must select an option.",
  validate (value) {
    return value.id !== undefined
  }
});

extend('max', {
  ...max,
  message: '{_field_} must be less than {length} characters',
})

extend('regex', {
  ...regex,
  message: '{_field_} must have a valid format',
})

extend('regexChar', {
  ...regex,
  message: "You can't use special character for {_field_}",
})
extend('legal_long', {
  message: "Compliance messaging is required for the welcome flow. Please add the {{legal_long}} variable.",
  validate: value => value.match(/{{\s*legal_long\s*}}/g) !== null
})

extend('not_url', {
  message: '{_field_} mustn\'t have url(s).',
  validate: value => value.match(/((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi) === null
})

extend('cart_url', {
  message: "Don't forget to add the {cart_url} variable so that your customers can purchase their products.",
  validate: value => value.match(/{{\s*cart_url\s*}}/g) !== null
})

extend('highest_priced_item_name', {
  message: "Don't forget to add the {highest_priced_item_name} variable.",
  validate: value => value.match(/{{\s*highest_priced_item_name\s*}}/g) !== null
})

extend('highest_priced_item_url', {
  message: "Don't forget to add the {highest_priced_item_url} variable so that your customers can purchase their products.",
  validate: value => value.match(/{{\s*highest_priced_item_url\s*}}/g) !== null
})

extend('product_viewed_url', {
  message: "Don't forget to add the {product_viewed_url} variable so that your customers can purchase their products.",
  validate: value => value.match(/{{\s*product_viewed_url\s*}}/g) !== null
})

extend('product_viewed', {
  message: "Don't forget to add the {product_viewed} variable so that your customers can purchase their products.",
  validate: value => value.match(/{{\s*product_viewed\s*}}/g) !== null
})


extend('discount_code', {
  message: "Don't forget to add the {discount_code} variable.",
  validate: value => value.match(/{{\s*discount_code\s*}}/g) !== null
})

extend('min', {
  ...min,
  message: '{_field_} may not be less than {length} characters',
})

extend('between', {
  ...between,
})

extend('min_value', {
  ...min_value,
})

extend('max_value', {
  ...max_value,
})

extend('size', {
  ...size,
  message: 'The {_field_} size must be less than {size}KB',
})

extend('email', {
  ...email,
  message: 'Email must be valid',
})

extend('businessEmail', {
  validate: value => value.match(/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/) !== null,
  message: 'Your email address is invalid. Please enter a business email address.',
})

extend('differentTo', {
  params: ['target'],
  validate(value, { target }) {
    return value !== target;
  },
  message: 'The {_field_} must be different than {target}',
})

extend('confirmed', {
  ...confirmed,
  message: 'The {_field_} does not match',
})

extend('upCase', {
  message: "{_field_} must have at least one uppercase character",
  validate: value => value.match(/[A-Z]/g) !== null
})

extend('specialChar', {
  message: "{_field_} must have at least one special character",
  validate: value => value.match(/[^A-Za-z0-9]/g) !== null
})

extend('number', {
  message: "{_field_} must have at least one number",
  validate: value => value.match(/[0-9]/g) !== null
})

extend('phone', {
  message: "Please check the phone number format",
  validate: value => value.match(/^[2-9]\d{2}[2-9]\d{2}\d{4}$/g) !== null
})

extend('intPhone', {
  message: "Your phone number is invalid. Please enter a valid phone number.",
  validate: value => value.match(/\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/g) !== null
})

const maxDimensionsRule = {
  validate(files, [width, height]) {
    const validateImage = (file, width, height) => {
      const URL = window.URL || window.webkitURL;
      return new Promise(resolve => {
        const image = new Image();
        image.onerror = () => resolve(false);
        image.onload = function (e) {

          resolve(image.width <= Number(width) && image.height <= Number(height)) // only change from official rule;
        };
        image.src = URL.createObjectURL(file);
      });
    };
    const list = [];
    if(files.length){
      for (let i = 0; i < files.length; i++) {
        if (this.verifyImage(files[i])) {
          list.push(files[i])
        }
      }
    }else {
        if (this.verifyImage(files)) {
          list.push(files)
        }
    }
    return Promise.all(list.map(file => validateImage(file, width, height)));
  },
  verifyImage(file) {
    return /\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(file.name)
  }
};

extend('maxdimensions', {
  async validate(value, {width, height}) {
    const results = await maxDimensionsRule.validate(value, [width, height])
    return !results.includes(false);
  },
  message: 'The {_field_} must be UP TO {width} pixels by {height} pixels.',
  params: ['width', 'height']
})

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.mixin({
  methods: {
    formatMoney: function(value, decimals = 2) {

      if (value === 'N/A') {
        return value
      }

      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });
      let result= formatter.format(value);
      if(Number.isNaN(result)){
        return formatter.format(0);
      }else {
        return result
      }
    },
    formatPhoneNumber(phoneNumberString) {
      let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      return phoneNumberString;
    },
    formatDate(date, withHour= false) {
      const dateFormat = require('dateformat')
      let value = null
      let today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0, 0);

      let yesterday = new Date();
      yesterday.setHours(0);
      yesterday.setMinutes(0);
      yesterday.setSeconds(0, 0);
      yesterday.setDate(today.getDate()-1);

      let createdAt = new Date(date)
      createdAt.setHours(0);
      createdAt.setMinutes(0);
      createdAt.setSeconds(0, 0);

      if (createdAt.getTime() === today.getTime()){
        if(withHour){
          let createdAt = new Date(date)
          value =  dateFormat(createdAt, "hh:MM tt")
        }else{
          value = "Today";
        }
      }

      else if (createdAt.getTime() === yesterday.getTime()){
        value =  "Yesterday";
      }
      else {

        value =  dateFormat(createdAt, "mm/dd/yyyy")
      }
      return value
    },
    messageVariablesPreview(text,companyName, discountCode, highestPriceName, productViewed){
      let variables_regex = [
        {
          regex: /{{\s*legal_long\s*}}/g,
          text: "Reply STOP to opt out or HELP for help. Msg&data rates may apply."
        },
        {
          regex: /{{\s*legal_short\s*}}/g,
          text: "stop2stop"
        },
        {
          regex: /{{\s*cart_url\s*}}/g,
          text: "https://shpsn.de/xCxt?extId=yyYYy"
        },
        {
          regex: /{{\s*brand_name\s*}}/g,
          text: companyName
        },
        {
          regex: /{{\s*discount_code\s*}}/g,
          text: discountCode
        },
        {
          regex: /{{\s*highest_priced_item_name\s*}}/g,
          text: highestPriceName
        },
        {
          regex: /{{\s*highest_priced_item_url\s*}}/g,
          text: "https://shpsn.de/xCxt?extId=yyYYy"
        },
        {
          regex: /{{\s*product_viewed_url\s*}}/g,
          text: "https://shpsn.de/xCxt?extId=yyYYy"
        },
        {
          regex: /{{\s*product_viewed\s*}}/g,
          text: productViewed
        }
      ]

      for(let i=0; i<variables_regex.length; i++){
        text = text.replace(variables_regex[i].regex, function(url) {
          if(variables_regex[i].text){
            return variables_regex[i].text
          }else {
            return "{{discount_code}}"
          }

        })
      }
      return text
    },
    urlify(text) {
      if(text.charAt(text.size - 1) === '.'){
        text = text.slice(-1);
      }
      let urlRegex = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
      return text.replace(urlRegex, function(url) {
        return '<a href="#">https://shpsn.de/xCx?extId=yyYYy</a>';
      })
    },
  }
})


document.addEventListener('DOMContentLoaded', () => {
  const el = document.body.appendChild(document.createElement('application'));
  const app = new Vue({
    el,
    router,
    store,
    vuetify,
    render: h => h(App)
  })
})