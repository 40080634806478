<template>
  <secure-content>
    <summary-view class="mt-4" :data-source="getMarketingBlastSummaryReportDataSource()"></summary-view>
    <paginated-table
            :title="automationsTitle"
            :headers="automationsHeaders"
            :data-source="getAutomatedCampaignsDataSource()"
            order="DESC"
            :filter="{'campaign_type': 'Automations'}"
            update-event = "update-automated-campaigns-list"
            csv-report-type="campaign-automation"
            kind="Campaign"
    ></paginated-table>

    <paginated-table
            :title=title
            :headers="marketingBlastReportHeaders"
            :data-source="getMarketingBlastReportDataSource()"
            order="DESC"
            :filter="{'campaign_type': 'Standard'}"
            update-event = "update-campaigns-list"
            csv-report-type="campaign-standard"
    ></paginated-table>
  </secure-content>
</template>

<script>

  import secureContent from '../secure-content.vue'
  import marketingBlastReportConnector from "../../connectors/reports/marketingBlastReportConnector"
  import marketingBlastReportSummaryConnector from "../../connectors/reports/marketingBlastReportSummaryConnector"
  import paginatedTable from "../base/paginatedTable"
  import summaryView from "../base/summaryView"
  import router from "../../router";

  export default {
    name: "reports",
    components: {
      secureContent,
      paginatedTable,
      summaryView
    },
    data () {
      return {
        title: this.$vuetify.lang.t('$vuetify.reports.marketingBlast.title'),
        marketingBlastReportHeaders:  [
          {
            text: 'CAMPAIGN',
            sortable: false,
            value: 'name',
            type: 'text'
          },
          {
            text: 'SCHEDULE',
            align: 'start',
            sortable: false,
            value: 'schedule_time',
            type: 'datetime'
          },
          {
            text: 'CREATED',
            align: 'start',
            sortable: false,
            value: 'created_at',
            type: 'date'
          },
          {
            text: 'DELIVERED',
            align: 'start',
            sortable: false,
            value: 'delivered',
            type: 'text'
          },
          {
            text: 'CLICKED',
            align: 'start',
            sortable: false,
            value: 'clicked',
            type: 'percentage'
          },
          // {
          //   text: 'OPTED-OUT',
          //   align: 'start',
          //   sortable: false,
          //   value: 'opted_out',
          //   type: 'percentage'
          // },
          {
            text: 'STATUS',
            align: 'start',
            sortable: false,
            value: 'status',
            type: 'chip'
          },
          {
            text: 'ACTION',
            align: 'start',
            sortable: false,
            value: 'campaign',
            type: 'actions'
          }
        ],
        automationsTitle: this.$vuetify.lang.t('$vuetify.reports.marketingBlast.automationsTitle'),
        automationsHeaders:  [
          {
            text: 'CAMPAIGN',
            sortable: false,
            value: 'name',
            type: 'text',
           // link: '/campaign/%id'
          },
          {
            text: 'TYPE',
            sortable: false,
            value: 'campaign_type',
            type: 'text'
          },
          {
            text: 'SCHEDULE',
            align: 'start',
            sortable: false,
            value: 'schedule_time',
            type: 'text'
          },
          {
            text: 'CREATED',
            align: 'start',
            sortable: false,
            value: 'created_at',
            type: 'date'
          },
          {
            text: 'DELIVERED',
            align: 'start',
            sortable: false,
            value: 'delivered',
            type: 'text'
          },
          {
            text: 'CLICKED',
            align: 'start',
            sortable: false,
            value: 'clicked',
            type: 'percentage'
          },
          {
            text: 'STATUS',
            align: 'start',
            sortable: false,
            value: 'status',
            model: 'campaign',
            type: 'switch'
          },
        ]
      }
    },
    methods: {
      getMarketingBlastReportDataSource() {
        return marketingBlastReportConnector
      },
      getAutomatedCampaignsDataSource() {
        return marketingBlastReportConnector
      },
      getMarketingBlastSummaryReportDataSource() {
        return marketingBlastReportSummaryConnector
      },
      goTo (url) {
        if(router.currentRoute.path != url){
          router.push(url);
        }
      }
    },
    mounted(){
    }
  }
</script>
<style scoped>

</style>