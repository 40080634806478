var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"vChange",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('div',{staticClass:"pl-16"},[(_vm.showWelcome)?_c('p',{staticClass:"text-subtitle-1 font-weight-bold pl-4 text--background text-start mb-0"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.company.tellUs')))]):_vm._e()]),_vm._v(" "),_c('v-form',{staticClass:"pl-16 pr-16",attrs:{"type":"","autocomplete":"on"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.keyPress($event)}}},[_c('v-card-text',{staticClass:"text-subtitle-1 text--background"},[_c('p',{staticClass:"text-subtitle-1 text--background text-start mb-0"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.company.name')))]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"name","outlined":"","autocomplete":"on","id":"name","type":"text","placeholder":"Cool Brand","dense":"","error-messages":errors},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('p',{staticClass:"text-subtitle-1 text--background text-start mb-0"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.company.website')))]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"webDomain","vid":"webDomain","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"webDomain","outlined":"","autocomplete":"on","prefix":"http(s)://","id":"webDomain","type":"text","placeholder":"https://www.coolbrand.com","dense":"","error-messages":errors},model:{value:(_vm.company.webDomain),callback:function ($$v) {_vm.$set(_vm.company, "webDomain", $$v)},expression:"company.webDomain"}})]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"right":"","color":"primary","disabled":_vm.buttonDisable},on:{"click":function($event){return _vm.sendData()}}},[_c('div',{staticClass:"pa-16"},[_vm._v("\n          "+_vm._s(_vm.$vuetify.lang.t('$vuetify.company.continueButton'))+"\n        ")])])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }