import connector from './connector'

const LIST_URI = '/list';

const listConnector = {

  findCompanyListsAndSegments (component) {
    connector.send(component,
    {
      method: 'get',
      url: LIST_URI
    },function (component,response) {
      component.receiveCompanyList(response.data.response);
    });
  }
 };
export default listConnector
