<template>
  <div>
    <v-row class="planHeader">
      <v-col cols="12" >

        <p class="text-h6 background--text text-center pt-0">{{$vuetify.lang.t('$vuetify.plans.title')}}</p>
        <p class="text-body-2 background--text text-center mb-0">{{$vuetify.lang.t('$vuetify.plans.subtitle')}}</p>
        <p class="text-body-2 font-weight-bold background--text text-center">{{$vuetify.lang.t('$vuetify.plans.offer')}}</p>

      </v-col>
    </v-row>
    <div v-if="loading">
      <v-row class="planContent d-flex justify-center">
        <v-col cols="auto" class="mt-16">
          <v-progress-circular
              :size="100"
              :width="7"
              color="secondary"
              indeterminate
          />
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row class="planContent d-flex justify-center">
        <v-col cols="auto" class="mt-6">
          <span class="text-body-2 secondary--text text-center mb-0">{{$vuetify.lang.t('$vuetify.plans.payAnnually')}}</span>
        </v-col>
        <v-col cols="auto">
          <v-switch
              class="mt-6 pt-0"
              v-model="isMonthly"
              color="secondary"
              hide-details
          ></v-switch>
        </v-col>
        <v-col cols="auto" class="mt-6">
          <span class="text-body-2 secondary--text text-center">{{$vuetify.lang.t('$vuetify.plans.payMonthly')}}</span>
        </v-col>
      </v-row>
      <v-row class="planContent d-flex justify-center">
        <div v-for="(plan,idx) in plans" v-bind:key="idx">
          <v-col cols="auto" class="mt-2" v-if="plan.show" >
            <v-card
                max-height='42vh' min-height='42vh' max-width="16vw" min-width="16vw"
                class="pa-4" :style="'border-top: 10px solid '+ plan.border +' !important'">
              <v-card-text>
                <p class="text-h6 font-weight-bold primary--text text-left mb-0">{{ plan.name }}</p>
                <p class="text-body-2 shopgray--text text-left" v-html="plan.subtitle"/>
                <p class="text-h6 shopgray--text text-left mb-0">
                  <span class="text-h3 font-weight-bold background--text text-left">{{ getMonthlyPrice(plan) }}</span> /mo
                </p>
                <p class="text-body-2 shopgray--text text-left">
                  {{getPriceCopy(plan)}}
                </p>
                <v-btn color="primary" block class="mt-8" @click="subscribe(plan)">Get {{ plan.name }}</v-btn>
                <ul class="mt-4">
                  <li v-for="(feature,fidx) in plan.features" v-bind:key="fidx">
            <span class="text-body-2 shopgray--text text-left">
              {{ feature }}
            </span>
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </div>

      </v-row>
      <v-row class="planContent pb-4 d-flex justify-center">
        <v-col cols="auto" class="mt-2">
          <p class="text-body-2 secondary--text text-center mb-0">{{$vuetify.lang.t('$vuetify.plans.asYouGoTitle')}}</p>
          <p class="text-body-2 secondary--text text-center mb-0">{{$vuetify.lang.t('$vuetify.plans.asYouGoSub')}}
            <a @click="subscribe(null)" class="secondary--text text-decoration-underline">
              {{$vuetify.lang.t('$vuetify.plans.asYouGoAction')}}
            </a>
          </p>
        </v-col>
      </v-row>
    </div>


  </div>
</template>

<script>

import stripeConnector from "../../connectors/stripeConnector";
import {EventBus} from "../../packs/app";

export default {
  name: "plan-selector",
  components: {
  },
  async mounted(){
    console.log("search plans")
    await this.findPlans()
    console.log('end')
  },
  data() {
    return {
      loading: false,
      isMonthly: false,
      plans:[]
      //[{
      // :id=>4,
      // :name=>"Pro",
      // :subtitle=>"For Med & Lg Brands That Require a Hands-On Approach<br><br>",
      // :price=>999.0,
      // :yearPrice=>5988.0,
      // :mmsPrice=>0.04,
      // :smsPrice=>0.01,
      // :features=>["Custom Short URL", "Conversations"],
      // :border: #C240D8
      // :show: true
      // }]
    };
  },
  methods: {
    async findPlans(){
      this.loading = true
      console.log('calling stripeCon')
      this.plans = await stripeConnector.listPlans(this)
      console.log('end stripeCon')
      this.plans.forEach(plan => plan.features.unshift(`$${plan.smsPrice} sms | $${plan.mmsPrice} mms`))
      this.loading = false
    },
    getMonthlyPrice(plan) {
      if(this.isMonthly){
        return this.formatMoney(plan.price, 0)
      }else{
        return this.formatMoney(plan.yearMonthlyPrice, 0)
      }
    },
    getPriceCopy(plan){
      if(this.isMonthly){
        return 'Billed monthly'
      }else{
        return 'Billed annually @ '+this.formatMoney(plan.yearPrice, 0)+'/yr'
      }
    },
    async subscribe(plan) {
      if(plan=== null){
        plan = this.plans.find((plan) => plan.name === 'pay-as-you-go')
      }
      EventBus.$emit('plan-selected', {plan: plan, isMonthly: this.isMonthly});
    },
    handleFail(error){
      //console.log(error.data.message)
    },
  },
}

</script>

<style scoped>
  .planHeader {
    background-color: var(--v-secondary-base);
  }
  .planContent {
    background-color: var(--v-primary-base);
  }
</style>