import connector from '../connector'

const ON_DEMAND_URI = '/report/on_demand/csv';

const onDemandReportConnector = {

  getCSV (component, params=null) {
    let queryString = ""
    if(params && Object.keys(params).length !== 0) {
      queryString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }

    return new Promise(
      (resolve, reject) => {
        connector.sendForCSV(component,
          {
            method: 'get',
            url: ON_DEMAND_URI + queryString,
            responseType: 'blob', // important
          },function (component,response) {
            let headerLine = response.headers['content-disposition'];
            let startFileNameIndex = headerLine.indexOf('"') + 1
            let endFileNameIndex = headerLine.lastIndexOf('"');
            let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
            response["filename"] = filename
            resolve(response);
          },false,null,function (){
            component.loading = false
          }
        );
      }
    );
  }
 };
export default onDemandReportConnector
