<template>
  <img :src="dsDocuments">
</template>

<script>
  import dsDocuments from '../../images/icons/ds-documents.svg'

  export default {
    name: "ds-documents",
    components: {
      dsDocuments
    },
    data () {
      return {
        dsDocuments: dsDocuments,

      }
    }
  }
</script>

<style scoped>

</style>