<template>
  <v-card-title class="headline justify-center margins">
    <br>
    <span class="headline">{{$vuetify.lang.t('$vuetify.login.validating')}}</span>
  </v-card-title>
</template>

<script>
  import auth from '../../models/auth'

  export default {
    name: "loginWithToken",
    props: {
      source: {
        type: String,
        default: 'link'
      }
    },
    mounted() {
      if(this.source === 'token'){
        auth.loginWithLink(this, this.$route.params.token)
      }else {
        auth.loginWithShopify(this, this.$route.params.token)
      }

    }
  }
</script>

<style>
  .margins {
    margin: 20px 70px;
  }
</style>