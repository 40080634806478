<template>
  <secure-content>
    <div>

      <v-row class="pl-10 pr-10">
        <v-card outlined
                class="mx-auto"
                min-width="500"
                min-height="400">

          <v-card-title class="headline justify-center margins">
            <br>
            <span class="headline">{{$vuetify.lang.t('$vuetify.profile.changePassword')}}</span>
          </v-card-title>
          <ValidationObserver ref="vChange" v-slot="{ validate, reset }">
            <v-form  @keyup.native.enter="keyPress($event)" type="" class="margins" autocomplete="on">
              <v-card-text class="text--primary">

                <span>{{$vuetify.lang.t('$vuetify.profile.currentPassword')}}</span>
                <ValidationProvider v-slot="{ errors }" name="current password" rules="required" vid="currentPass">
                  <v-text-field name="login" filled autocomplete="on"
                              id="currentPassword" type="password" v-model="user.currentPassword"
                              :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
                <span>{{$vuetify.lang.t('$vuetify.profile.newPassword')}}</span>
                <ValidationProvider v-slot="{ errors }" name="new password" vid="newPassword" rules="required|min:8|upCase|number|differentTo:@currentPass">
                  <v-text-field name="newPassword" filled autocomplete="on"
                                id="newPassword" v-model="user.newPassword"
                                 :error-messages="errors"
                                :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showNew = !showNew"
                                :type="showNew ? 'text' : 'password'"
                  ></v-text-field>
                </ValidationProvider>
                <span>{{$vuetify.lang.t('$vuetify.profile.newPasswordConfirmation')}}</span>
                <ValidationProvider v-slot="{ errors }" name="new password confirmation" rules="required|confirmed:newPassword">
                  <v-text-field name="newPasswordConfirmation" filled autocomplete="on"
                                id="newPasswordConfirmation"v-model="user.newPasswordConfirmation"
                                :error-messages="errors" data-vv-as="password"
                                :append-icon="showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showConfirmation = !showConfirmation"
                                :type="showConfirmation ? 'text' : 'password'">
                  </v-text-field>
                </ValidationProvider>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn right color="primary" @click="changePassword()"  :disabled='buttonDisable'>{{$vuetify.lang.t('$vuetify.profile.changePasswordButton')}}</v-btn>
              </v-card-actions>

            </v-form>
          </ValidationObserver>

        </v-card>

      </v-row>
    </div>

  </secure-content>
</template>

<script>

  import secureContent from '../secure-content.vue'
  import userConnector from "../../connectors/userConnector";
  import {EventBus} from "../../packs/app";
  export default {
    name: "change-password",
    components: {
      secureContent
    },
    data () {
      return {
        user:{
          currentPassword: '',
          newPassword: '',
          newPasswordConfirmation: ''
        },
        showNew: false,
        showConfirmation: false,
        buttonDisable: false

      }
    },
    methods: {
      async changePassword() {
        let result= await this.$refs.vChange.validate()
        if (result) {
          this.buttonDisable = true
          userConnector.changePassword(this, {
            currentPassword: this.user.currentPassword,
            newPassword: this.user.newPassword,
            confirmation: this.user.newPasswordConfirmation
          })
        }
      },
      changePasswordDone(result) {
        if (result) {
          this.user = {
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: ''
          }
          this.$refs.vChange.reset()
          this.buttonDisable = false
          EventBus.$emit('flash-message', {
            text: this.$vuetify.lang.t('$vuetify.profile.changedPassword'),
            color: 'info',
            textColor: 'white',
            html: false,
            outlined: false
          });
        }
      },
      keyPress (event) {
        event.preventDefault()
        event.stopPropagation()
        this.changePassword()
      }
    }
  }
</script>

<style scoped>

</style>