<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div id="header">
    <v-navigation-drawer
            v-model="drawer"
            :mini-variant="expand"
            :clipped="true"
            color="background"
            app
    >
      <v-list dense class="pt-0">

        <div v-if="isLogged()">
          <v-list-item-group v-model="selected" mandatory>
            <v-list-item @click="handleClick('/dashboard', 0, $event)" >
              <v-list-item-action class="py-2">
                <v-icon>{{dashboardIcon}}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                        class="accent--text">{{ $vuetify.lang.t('$vuetify.header.dashboard') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="handleClick('/contact-list', 1, $event)">
              <v-list-item-action class="py-2">
                <v-icon>{{contactsIcon}}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="accent--text">{{ $vuetify.lang.t('$vuetify.header.contact_lists') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="handleClick('/campaigns', 2, $event)">
              <v-list-item-action class="py-2">
                <v-icon>{{campaignsIcon}}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="accent--text">{{ $vuetify.lang.t('$vuetify.header.campaigns') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

               <v-list-item @click="handleClick('/conversations',3,$event)">
                 <v-list-item-action class="py-2">

                   <v-badge v-if="newMessages" color="success"
                            dot
                   >
                     <v-icon>{{conversationsIcon}}</v-icon>
                   </v-badge>
                   <v-icon v-else>{{conversationsIcon}}</v-icon>

                 </v-list-item-action>
                 <v-list-item-content>
                   <v-list-item-title class="accent--text">{{ $vuetify.lang.t('$vuetify.header.conversations') }}</v-list-item-title>
                 </v-list-item-content>
               </v-list-item>
          </v-list-item-group>


        </div>

      </v-list>
    </v-navigation-drawer>

    <v-app-bar
            :clipped-left="true"
            color="background"
            flat
            app
    >
      <v-app-bar-nav-icon color="accent" @click.stop="expand = !expand"></v-app-bar-nav-icon>
<!--      <a href="https://app.shopsend.io/#/dashboard" target="_blank"  class="pl-8 pr-8 pt-2">-->
      <div class="pl-8 pr-8 pt-2">
        <img :src="logo" :alt="$vuetify.lang.t('$vuetify.header.title')" width="160px" height="32px">
      </div>

<!--      </a>-->

      <a href="https://research.typeform.com/to/DYd1Y4ob" target="_blank"  class="pl-8 pr-4 pt-0 accent--text">
        <span class="pa-0 accent--text text-subtitle-2">{{ $vuetify.lang.t('$vuetify.header.feedback')  }}</span>
      </a>
      <a href="https://research.typeform.com/to/nPlIwxEL" target="_blank"  class="pl-4 pr-8 pt-0 accent--text">
        <span class="pa-0 accent--text text-subtitle-2">{{ $vuetify.lang.t('$vuetify.header.feature')  }}</span>
      </a>

      <div class="flex-md-grow-1"></div>
      <template v-if="isLogged()">
        <v-toolbar-items>

          <v-menu bottom left offset-y max-width="300px" min-width="300px">
            <template v-slot:activator="{ on }">
              <v-btn
                elevation="0"
                text
                v-on="on"
              >
                <v-icon class="pr-0">$vuetify.icons.billing</v-icon>

                <span v-if="!billing.plan.includes('Trial')" class="pl-2 accent--text text-subtitle-2">  {{ billing.contacts }} | {{ formatMoney(billing.balance) }} </span>
                <span v-else class="pl-2 accent--text text-subtitle-2"> {{ billing.contacts }} </span>
                <v-avatar size="30">
                  <v-icon color="accent">mdi-menu-down</v-icon>
                </v-avatar>
              </v-btn>
            </template>


            <v-list class="pb-0">

              <v-list-item-content>
                <v-list-item-subtitle>
                  <div class="d-flex justify-space-between">
                    <span class="text-subtitle-2 text-left pt-0 pb-0 pl-4">
                      {{ this.$vuetify.lang.t('$vuetify.header.plan') }}
                    </span>
                      <span class="text-subtitle-2 align-end pt-0 pb-0 pr-4">
                      <b>{{ billing.plan }}</b>
                    </span>
                  </div>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <div class="d-flex justify-space-between">
                    <span class="text-subtitle-2 text-left pt-0 pb-0 pl-4">
                     {{ this.$vuetify.lang.t('$vuetify.header.active') }}
                    </span>
                    <span class="text-subtitle-2 align-end pt-0 pb-0 pr-4">
                      <b>{{ activeContacts }}</b>
                    </span>
                  </div>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <br>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <div class="d-flex justify-space-between">
                    <span class="text-subtitle-2 text-left pt-0 pb-0 pl-4">
                      {{ this.$vuetify.lang.t('$vuetify.header.messages') }}
                    </span>
                    <span class="text-subtitle-2 align-end pt-0 pb-0 pr-4">
                      <b>{{ billing.messages }}</b>
                    </span>
                  </div>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <div class="d-flex justify-space-between" v-if="!billing.plan.includes('Trial')">
                    <span class="text-subtitle-2 text-left pt-0 pb-0 pl-4">
                      {{ this.$vuetify.lang.t('$vuetify.header.currentBalance') }}
                    </span>
                    <span class="text-subtitle-2 align-end pt-0 pb-0 pr-4">
                      <b>{{ formatMoney(billing.balance) }}</b>
                    </span>
                  </div>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <div class="d-flex justify-space-between">
                    <span v-if="!billing.plan.includes('Trial')" class="text-subtitle-2 text-left pt-0 pb-0 pl-4">
                      {{ this.$vuetify.lang.t('$vuetify.header.nextInvoice') }}
                    </span>
                    <span v-else class="text-subtitle-2 text-left pt-0 pb-0 pl-4">
                      {{ this.$vuetify.lang.t('$vuetify.header.trialEnds') }}
                    </span>
                    <span class="text-subtitle-2 align-end pt-0 pb-0 pr-4">
                      <b>{{ billing.nextInvoice }}</b>
                    </span>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item @click="mailTo()" x-small class="tile">
                <v-list-item-content>
                  <v-list-item-title class="background--text">{{ $vuetify.lang.t('$vuetify.header.billing') }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="background">mdi-arrow-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>

          <div class="pa-4">
             <create-campaign-selector/>
          </div>


          <span class="pa-5 accent--text text-subtitle-2">{{ getUserLogged().email }}</span>

          <v-menu bottom left offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                      icon
                      v-on="on"
              >
                <v-avatar size="30">
                  <v-img v-if="getUserLogged().picture" :src="getUserLogged().picture"></v-img>
                  <v-icon v-else>$vuetify.icons.customer</v-icon>
                </v-avatar>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="goTo('/setup/company')">
                <v-list-item-action>
                  <v-icon color="background">mdi-store</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-2">Integrations</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="goTo('/change-password')">
                <v-list-item-action>
                  <v-icon color="red">$vuetify.icons.settings</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-2">{{ $vuetify.lang.t('$vuetify.header.changePassword') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="logOut()">
                <v-list-item-action>
                  <v-icon>$vuetify.icons.logout</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-2">{{ $vuetify.lang.t('$vuetify.header.logOut') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

        </v-toolbar-items>
      </template>
      
     
    </v-app-bar>
  </div>
</template>

<script>
  
  import auth from '../models/auth';
  import { EventBus } from '../packs/app';
  import router from '../router';

  import logo from '../images/shopsend-logo.svg'
  import createCampaignSelector from '../components/campaign/create-campaign-selector'
  
  export default {
    name: "app-header",

    data: () => ({
      drawer: true,
      expand: true,
      logo: logo,
      selected: 0,
      campaignsIcon: "$campaigns",
      contactsIcon: "$contacts",
      dashboardIcon: "$dashboard",
      conversationsIcon: "$conversations"
    }),
    components: {
      createCampaignSelector
    },
    computed: {
      billing: function () {
        return this.$store.getters.billingSummary
      },
      activeContacts: function () {
        return this.$store.getters.activeContacts
      },
      newMessages: function () {
        return this.$store.getters.newMessages
      }

    },
    methods: {
      isLogged () {
        return auth.isLogged(this);
      },
      getUserLogged () {
        return auth.getUserLogged(this);
      },
      getRol () {
        return auth.userRol(this);
      },
      logOut () {
        auth.logOut(this);
      },
      mailTo () {
        window.location.href = "mailto:billing@Shopsend.io";
      },

      handleClick (url, item, $event) {

        if(item === 0){
          this.$store.commit('setIconsMenu', ["$dashboardSelected","$contacts", "$campaigns","$conversations"]);
        } else if(item === 1){
          this.$store.commit('setIconsMenu', ["$dashboard","$contactsSelected", "$campaigns","$conversations"]);
        } else if(item === 2){
          this.$store.commit('setIconsMenu', ["$dashboard","$contacts", "$campaignsSelected","$conversations"]);
        }else if(item === 3){
          this.$store.commit('setIconsMenu', ["$dashboard","$contacts", "$campaigns","$conversationsSelected"]);
        }
        this.$store.commit('setItemMenuSelected', item);
        this.goTo(url)
      },
      goTo (url, external) {
        if (external) {
          window.location.href = url;
        }else {
          if(router.currentRoute.path != url){
            router.push(url);
          }
        }
      }
    },
    mounted() {
      this.selected = this.$store.getters.itemMenuSelected
      let icons = this.$store.getters.iconsMenu
      this.dashboardIcon = icons[0]
      this.contactsIcon = icons[1]
      this.campaignsIcon = icons[2]
      this.conversationsIcon = icons[3]

      EventBus.$on('logIn', () => {
        this.$forceUpdate();
      });
  
      EventBus.$on('logOut', () => {
        this.$forceUpdate();
      });

      EventBus.$on('new-conversation', (data) => {
        this.$store.commit('setNewMessages', true);
      })

      EventBus.$on('new-message-in-conversations', (message) => {
        this.$store.commit('setNewMessages', true);
      })

    }
  }
</script>

<style scoped>
  .tile {
    background: var(--v-accent-base);
  }
  .tile:hover {
    background: var(--v-accent-base);
  }
  .v-list .v-list-item--active {
    background-color: var(--v-clearbackground-base)!important;
    opacity: 1;
  }
  .v-list .v-list-item--link:before {
    background-color: var(--v-clearbackground-base)!important;
  }

  .v-list .v-list-item--active .v-list-item__title {
    color: var(--v-error-base) !important;
  }



</style>