<template>
    <v-list-item>
      <v-dialog max-width="650" persistent id="testDialog" v-model="dialog">
        <template #activator="{ on, attrs }">
          <v-list-item-title class="background--text"   v-bind="attrs"
                             v-on="on">
            <v-icon>mdi-cellphone</v-icon>
            {{ $vuetify.lang.t('$vuetify.campaign.test') }}
          </v-list-item-title>
        </template>

        <v-card>

          <v-card-title class="pt-16 ">
            <div v-show="screen === 1">
              <p class="text-h5 px-16">{{$vuetify.lang.t('$vuetify.campaign.testInfo.title')}}</p>
            </div>
            <div v-show="screen === 2">
              <p class="text-h5 px-16">{{$vuetify.lang.t('$vuetify.campaign.testInfo.titleEmail')}}</p>
              <p class="text-subtitle-1 px-16">{{$vuetify.lang.t('$vuetify.campaign.testInfo.subtitleEmail')}}</p>
            </div>

          </v-card-title>

            <v-form autocomplete="on">
              <v-card-text class="text--primary">
                <div v-show="screen === 1">
                  <ValidationObserver ref="vSendTest1" v-slot="{ validate, reset }">
                    <ValidationProvider v-slot="{ errors }" name="phone" rules="required|phone">
                      <span class="px-16 text-subtitle-1">{{$vuetify.lang.t('$vuetify.campaign.testInfo.phone')}}<br></span>
                      <span class="px-16 text-subtitle-2">{{$vuetify.lang.t('$vuetify.campaign.testInfo.phoneEx')}}</span>
                      <v-text-field class="px-16"
                                    name="phone"
                                    filled
                                    autocomplete="on"
                                    type="text"
                                    v-model="phone"
                                    :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </ValidationObserver>
                </div>

                <div v-show="screen===2">
                  <ValidationObserver ref="vSendTest2" v-slot="{ validate, reset }">
                    <ValidationProvider v-slot="{ errors }" name="email" rules="required|email">
                      <span class="px-16">{{$vuetify.lang.t('$vuetify.campaign.testInfo.email')}}</span>
                      <v-text-field class="px-16"
                                    name="email"
                                    filled
                                    autocomplete="on"
                                    id="email"
                                    type="text"
                                    v-model="email"
                                    :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </ValidationObserver>
                </div>

              </v-card-text>

              <v-card-actions class="justify-center pb-10">
                <v-btn @click="cancel()" :disabled='buttonDisable'>
                  <div class="px-12">
                    {{$vuetify.lang.t('$vuetify.campaign.testInfo.cancel')}}
                  </div>
                </v-btn>
                <v-btn color="primary" @click="sendTest()"  :disabled='buttonDisable'>
                  <div class="px-12">
                    {{$vuetify.lang.t('$vuetify.campaign.testInfo.send')}}
                  </div>
                </v-btn>
              </v-card-actions>

            </v-form>



        </v-card>
      </v-dialog>
    </v-list-item>
</template>

<script>

import contactConnector from "../../connectors/contactConnector";
import {EventBus} from "../../packs/app";

export default {
  name: "send-test",
  data() {
    return {
      dialog: false,
      buttonDisable: false,
      phone: "",
      email: "",
      screen: 1
    }
  },
  methods: {
    cancel() {
      if(this.screen === 2){
        this.screen = 1
      }else if(this.screen === 1){
        this.dialog = false
      }
    },
    async sendTest(){

      if(this.screen === 1){
        let result = await this.$refs.vSendTest1.validate()
        if(result) {
          let contact = await contactConnector.searchContact(this,this.phone)
          if(contact){

            EventBus.$emit('send-test', {
                phone: contact.phone,
                email: contact.email,
                newContact: false
            })

            this.dialog = false

          }else{
            this.screen = 2
          }
        }
      }else {
        let result = await this.$refs.vSendTest2.validate()
        if(result) {
          EventBus.$emit('send-test', {
              phone: this.phone,
              email: this.email,
              newContact: true
          })
          this.dialog = false
          this.screen = 1
        }
      }
    }

  }
}
</script>

<style scoped>

</style>