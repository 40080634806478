import axios from "axios";
import auth from "../models/auth";
import {EventBus} from "../packs/app";
import router from "../router";
import utils from '../models/utils'

const API_URL = utils.apiUrl()
const PUBLIC_API_URL = utils.publicUrl()
export const BASE_URL = utils.baseUrl()

const connector = {
  //successfulCallback must always receive context and response.
  send (context, connectionParams, successfulCallback,disableError, redirectionPageOnError, failCallback) {
    this.sendWithCustomUrl(context,connectionParams,successfulCallback,disableError,API_URL,redirectionPageOnError, failCallback)
  },
  sendToPublicApi (context, connectionParams, successfulCallback,disableError, redirectionPageOnError, failCallback) {
    this.sendWithCustomUrl(context,connectionParams,successfulCallback,disableError,PUBLIC_API_URL,redirectionPageOnError, failCallback)
  },
  sendForCSV (context, connectionParams, successfulCallback,disableError, redirectionPageOnError, failCallback) {
    this.sendWithCustomUrl(context,connectionParams,successfulCallback,disableError,BASE_URL,redirectionPageOnError, failCallback)
  },
  sendToShoppifyApp(context, connectionParams, successfulCallback,disableError){

    //const shopifyAppUrl = "https://sapp.shopsend.io"
    const shopifyAppUrl = process.env.SHOPIFY_APP
    this.sendWithCustomUrl(context,connectionParams,successfulCallback,disableError,shopifyAppUrl)
  },

  sendToShopsendClickerApp(context, connectionParams, successfulCallback,disableError){
    //const shopsendClickerAppUrl = "https://shpsn.de"
    const shopsendClickerAppUrl = process.env.CLICKER_APP || "https://shpsn.de"
    this.sendWithCustomUrl(context,connectionParams,successfulCallback,disableError,shopsendClickerAppUrl)
  },

  sendWithCustomUrl(context, connectionParams, successfulCallback,disableError, url, redirectionPageOnError, failCallback, sendShopsendToken = true){
    let that = this;
    connectionParams.url = url + connectionParams.url;

    if(sendShopsendToken && auth.isLogged(context)){
      axios.defaults.headers.common['Authorization'] = auth.getAuthHeader(context);
    }
    axios(connectionParams).then(function (response) {
      successfulCallback(context, response);
    }).catch(async function (error) {
      console.log(error)
      await that._processErrorMessages(context, error, disableError, redirectionPageOnError, failCallback);
    })
  },

  sendWithFile (context, uri, data, method ,successfulCallback) {
    let that = this;
    const url = API_URL + uri;
    if(auth.isLogged(context)){
      axios.defaults.headers.common['Authorization'] = auth.getAuthHeader(context);
    }
    const formData = new FormData();
    for(const key of Object.keys(data)){
      formData.append(key,data[key])
    }
    if(method === "POST"){
      axios.post(url, formData).then(function (response) {
        successfulCallback(context, response);
      }).catch(async function (error) {
        await that._processErrorMessages(context, error);
      })
    }else if(method === "PUT") {
      axios.put(url, formData).then(function (response) {
        successfulCallback(context, response);
      }).catch(async function (error) {
        await that._processErrorMessages(context, error);
      })
    } else{
      console.log('error sending: '+ method)
    }

  },

  async _processErrorMessages(context, error, disableError, redirectionPageOnError, failCallback) {

    if (error.response) {
      if (error.response.status === 401) {
        this._manageSessionError(context, error, disableError);
      } else if (error.response.status === 400) {
        this._manageValidationErrors(context, error, disableError);
      } else if (error.response.status === 404) {
        this._manageSrvNotFoundError(context, error, disableError)
      } else if (error.response.status === 402) { //plan without that capability
        context.$plans(context, {showSuccess: false})
        //console.log("plant result", planResult)
      } else if (error.response.status === 300) { // setup not complete - redirect to finish onboarding
        await router.push('/on-board/new-costumer/' + error.response.data.company_hashid).catch(err => {})
      } else if (error.response.status === 403) {//inactive company

      } else {
        this._showGenericErrorMessage(context, error, disableError);
      }
    } else {
      this._manageSrvNotFoundError(context, error, disableError)
    }


    if (context.buttonDisable != undefined) {
      context.buttonDisable = false
    }

    if (redirectionPageOnError) {
      router.push(redirectionPageOnError);
    }

    if (failCallback) {
      failCallback(context, error.response)
    }

  },
  _manageSessionError (context, error,disableError) {
    if(!disableError){
      EventBus.$emit('flash-message', {
        text: '' + error.response.data.message,
        color: 'error',
        y: 'top',
        textColor: 'error',
        outlined: true
      });
      auth.logOut(context);

      if(context.$router.currentRoute.path !== '/login') {
        router.push('/login');
      }
    }
  },
  _manageSrvNotFoundError (context, error, disableError) {
    if (!disableError) {
      EventBus.$emit('flash-message', {
        text: context.$vuetify.lang.t('$vuetify.flash.standardError', ['[id:404]']),
        color: 'info',
        textColor: 'white',
        outlined: false
      });
    }
  },
  _manageValidationErrors (context, error,disableError) {
    if (!disableError) {
      let msg = '<br>'
      if(error.response.data.errors){
        // {
        //   "errors": {
        //   "current_password": [
        //     "can't be blank"
        //   ]
        // }
        const errors = error.response.data.errors
        for (let error in errors) {
          msg += error + ": " + errors[error].join(', ') +"<br>"
        }

      }else if(error.response.data.message){
        // {
        //   "message": [
        //   "please review the new and the confirmation password"
        // ]
        // }
        const errors = error.response.data.message
        if(typeof errors === 'string'){
          msg = errors
        }else{
          for (let index in errors) {
            msg += errors[index] +"<br>"
          }
          msg = msg.slice(0, -4);
        }
      }

      EventBus.$emit('flash-message', {
        text:  msg,
        color: 'info',
        textColor: 'white',
        html: true,
        outlined: false
      })
    }
  },
  _showGenericErrorMessage(context, error,disableError) {
    if (!disableError) {
      EventBus.$emit('flash-message', {
        text: context.$vuetify.lang.t('$vuetify.flash.standardError', ["[id:"+error.response.status+"]"]),
        color: 'info',
        textColor: 'white',
        outlined: false
      });
    }
  },
  apiUrl () {
    return API_URL
  }
};

export default connector