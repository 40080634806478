<template>

  <v-col cols="12" sm="10" class="pt-2" style="position: fixed;" >
    <v-row class="mr-8 ml-16 pl-16 mb-3 ml-sm-9 pl-sm-9">

      <v-img :aspect-ratio="16/9" :src="phoneBg"  :height="720*0.92" :max-height="720*0.92" :max-width="380*0.92" :width="380*0.92">
          <v-list height="490px" max-height="490px" class="overflow-y-auto mx-9 mt-14">
            <v-row v-for="(message,idx) in previousMessages"
                   v-bind:key="idx"
                   v-bind:class="message.orientation === 'left' ? 'text-color text-left mr-16 ml-1': 'text-right ml-10 mr-1'"
                   class="rounded-lg mt-1">
              <v-col cols="auto"
                     v-bind:class="message.orientation === 'left' ? 'text-color': 'me-text-color'"
                     class='rounded ml-auto caption pt-3 pb-3 pr-5 pl-5 background--text'
                     v-html="processText(message.sms)"></v-col>
            </v-row>

            <v-row class="mr-1 ml-1 mb-1 mt-1">
              <v-img v-show="url" :src="url"  :height="250*0.9" :max-height="250*0.9" :max-width="180*0.9" :width="180*0.9" class="rounded-lg"></v-img>
            </v-row>
            <v-row  v-bind:class="sms ? 'text-color': 'no-color'" class="rounded-lg mr-16 ml-1">
              <div class='caption pt-3 pb-3 pr-5 pl-5 background--text' v-html="processText(sms)"></div>
            </v-row>
          </v-list>
      </v-img>
    </v-row>

    <div v-if="showEstimates && !isInTrial()">
      <v-row class="ml-12 pl-12">
        <v-col cols="2" class="pb-0"><span class="text-subtitle-2 text-left background--text"><b>{{ this.$vuetify.lang.t('$vuetify.campaign.contacts') }}</b></span></v-col>
        <v-col class="pb-0"><span class="text-subtitle-2 text-Right background--text">{{ estimates.contacts }}</span></v-col>
      </v-row>
      <v-row class="ml-12 pl-12">
        <v-col cols="2" class="pt-0 pb-0"><span class="text-subtitle-2 text-left background--text"><b>{{ this.$vuetify.lang.t('$vuetify.campaign.cost') }}</b></span></v-col>
        <v-col class="pt-0 pb-0"><span class="text-subtitle-2 text-Right pt-0 pb-0 background--text">{{ formatMoney(estimates.cost) }}</span></v-col>
      </v-row>
      <v-row class="ml-12 pl-12">
        <v-col cols="2" class="pt-0"><span class="text-subtitle-2 text-left background--text"><b>{{ this.$vuetify.lang.t('$vuetify.campaign.revenue') }}</b></span></v-col>
        <v-col class="pt-0"><span class="text-subtitle-2 text-Right background--text">{{ formatMoney(estimates.revenue) }}</span></v-col>
      </v-row>
    </div>

  </v-col>

</template>

<script>

  import campaignConnector from "../../connectors/campaignConnector";
  import phoneBg from '../../images/phoneBackground.png'
  import {EventBus} from "../../packs/app";

  export default {
    name: "message-viewer",
    components: {
      phoneBg
    },
    props: {
      sms: {
        type: String,
        default: ''
      },
      discountCode: {
        type: String,
        default: null
      },
      media: {
        type: File|String,
        default: null
      },
      typeId: {
        type: String|Number,
        default: -1
      },
      messageEstimatedSegment: {
        type: Number,
        default: 1
      },
      showEstimates: {
        type: Boolean,
        default: false
      },
      previousMessages: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        campaignFacts: {
          message_estimated_segment: this.messageEstimatedSegment,
          media_included: false,
          type_id: 0
        },
        estimates: {
          contacts: 0,
          cost: 'N/A',
          revenue: "N/A"
        },
        url: null,
        phoneBg: phoneBg
      }
    },
    watch: {
      typeId (newValue) {
        this.campaignFacts.type_id = newValue
        if(newValue !== -1) {
          campaignConnector.estimates(this, this.campaignFacts)
        }
      },
      media (newValue) {
        if(newValue){
          if(typeof newValue === 'string'){
            this.url = newValue
          } else{
            this.url = URL.createObjectURL(newValue)
          }
          this.campaignFacts.media_included = true
          campaignConnector.estimates(this, this.campaignFacts)
        }else {
          this.url = null
          this.campaignFacts.media_included = false
          campaignConnector.estimates(this, this.campaignFacts)
        }
      },
      messageEstimatedSegment(newValue){
        this.campaignFacts.message_estimated_segment = newValue
        campaignConnector.estimates(this, this.campaignFacts)
      }
    },
    mounted() {
      if(typeof this.media === 'string'){
        this.url = this.media
      }
    },
    methods: {
      updateEstimates(response) {
        this.estimates = response
      },
      isInTrial(){
        return this.$store.getters.billingSummary.plan.includes('Trial')
      },
      processText(text){
        text = this.urlify(text)
        text = this.messageVariablesPreview(
            text,
            this.$store.getters.user.company_name,
            this.discountCode,
            "{{highest_priced_item_name}}",
            "{{product_viewed}}"
        )
        return text
      },
    }
  }
</script>

<style>
  .text-color{
    background-color: #e9e9eb;
    white-space: pre-wrap;
  }
  .me-text-color{
    background-color: #25d366;
    white-space: pre-wrap;
  }
  .no-text {
    background-color: white;
    white-space: pre-wrap;
  }
</style>