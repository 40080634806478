<template>
  <v-dialog eager
            @input="change" value="true"
            persistent @keydown.esc="choose(false)"
            :max-width="showSuccess ? '40vh': '70vw'" :min-width="showSuccess ? '40vh': '70vw'"
  >

    <v-card :max-height="showSuccess ? '40vh': '81vh'" :min-height="showSuccess ? '40vh': '81vh'"
            color="primary" id="primary-card">
      <v-toolbar flat :class="planSelected === null && !showSuccess ? '':'transparent-card'" >
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn icon @click="choose(false)">
          <v-icon :color="planSelected === null && !showSuccess ?'':'white'">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div v-if="showSuccess">
        <success-onboard :plan="planSelected" :isMonthly="isMonthly"/>
      </div>
      <div v-else>
       <plans-selector v-if="planSelected === null"/>
        <plan-payment-summary v-else :plan="planSelected" :isMonthly="isMonthly"/>
      </div>


    </v-card>
  </v-dialog>

</template>

<script>

import plansSelector from "./plans-selector";
import planPaymentSummary from "./plan-payment-summary";
import successOnboard from "./success-onboard";
import {EventBus} from "../../packs/app";
import router from "../../router";

export default {
  name: "plans",
  components: {
    plansSelector,
    planPaymentSummary,
    successOnboard
  },
  props: {
    showSuccess: {
      type: Boolean,
      default: false
    },
  },
  async mounted(){
    document.addEventListener('keyup', this.onEnterPressed)
    EventBus.$on('plan-selected', (data) => {
      this.planSelected = data.plan
      this.isMonthly = data.isMonthly
    });

    EventBus.$on('close-plans', (data) => {
      this.choose(false)
    });
    console.log("showed")
  },
  destroyed () {
    document.removeEventListener('keyup', this.onEnterPressed)
  },
  data() {
    return {
      value: false,
      planSelected: null,
      isMonthly: null,
    };
  },
  methods: {

    choose (value) {
      if(this.showSuccess){
        this.$store.commit('setMustShowSuccessPayment', false);
        if(window.location.search){
          window.location.href = window.location.origin+'/'+window.location.hash
        }
      }
      this.$emit('result', value)
      this.value = value
      this.$destroy()
    },
    change (res) {
      this.$destroy()
    },
  },
}

</script>

<style>
  .v-dialog {
    overflow: hidden !important;
  }
  .transparent-card  {
    background-color: transparent !important;
  }
  #primary-card:active {
    background: var(--v-primary-base) !important;
  }

</style>