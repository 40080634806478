<template>
  <v-list three-line v-show="loaded" style="overflow-y: auto;"
                      max-height='79vh' min-height='79vh' :disabled="!enable"
          :close-on-content-click="false">
                <v-list-item-group
                    mandatory
                    color="background"
                    ref="conversationGroup"
                    v-model="selectedConversation"
                    :key="selectedConversation"
                    @change="changeConversationOnViewer()"
                >
                  <v-list-item
                      v-for="(conversation, index) in conversations"
                      :key="conversation.id"
                      @click="resetUnreadMessages(conversation, index)"
                  >
                    <v-badge class="mr-2" v-if="conversation.unread.counter > 0"
                             :content="conversation.unread.counter"
                             inline
                             left
                    >
                      <v-list-item-avatar class="mr-1"  color="secondary" >
                        <span class="text-h6 background--text"> {{ getContactInitials(conversation) }} </span>
                      </v-list-item-avatar>
                    </v-badge>

                    <v-list-item-avatar v-else class="ml-4 mr-4" color="secondary">
                      <span class="text-h6 background--text" > {{ getContactInitials(conversation) }} </span>
                    </v-list-item-avatar>


                    <v-list-item-content>
                      <v-list-item-title :class="conversation.unread.counter > 0 ? 'font-weight-black': ''">
                        <v-row>
                          <v-col>
                            {{prepareFullName(conversation)}}
                          </v-col>
                          <v-col class="text-right mr-2">
                            <span class='caption background--text pt-2 pb-2'>
                              {{formatDate(conversation.messages.at(-1).created_at,true)}}
                            </span>
                          </v-col>
                        </v-row>

                      </v-list-item-title>
                      <v-list-item-subtitle :class="conversation.unread.counter > 0 ? 'font-weight-black': ''" v-html="conversation.messages[conversation.messages.length-1].message"/>

                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>

              </v-list>
</template>

<script>

  import conversationConnector from "../../connectors/conversationConnector"
  import {EventBus} from "../../packs/app"

  export default {
    name: "conversations-list",
    components: {
    },
    props: {
      contentType: {
        type: String,
        default: null//archived
      },
      enable: {
        type: Boolean,
        default: true
      },
    },
    data () {
      return {
        loaded: false,
        pagination_query: {
          page_size: 20,
          page_number: 0,
          order: 'DESC'
        },
        selectedConversation: 0,
        conversations: []
      }
    },
    methods: {
      getContactInitials(conversation){
        let name = conversation.contact_name
        let lastname = conversation.contact_last_name
        if(name){
          let value = name[0].toUpperCase()
          if(lastname){
            value += lastname[0].toUpperCase()
          }
          return value
        }
        return "U"
      },
      prepareFullName(conversation){
        if(conversation.contact_name && conversation.contact_name !== "Unknown"){
          let name =conversation.contact_name
          if(conversation.contact_last_name){
            name = name + ' ' + conversation.contact_last_name
          }
          return name
        }
        return this.formatPhoneNumber(conversation.contact_phone)
      },
      async resetUnreadMessages(conversation,index){
        if(conversation.unread.counter > 0){
          await conversationConnector.markAsReadMessages(this, conversation.unread.ids)
          conversation.unread = {counter:0, ids: []}
          this.updateNewMessageStatus()
        }

      },
      updateNewMessageStatus(){
        if(this.contentType ==='Active'){
          let newMessageStatus = false
          let unread = this.conversations.find(function(conversation) {
            return conversation.unread.counter > 0;
          });
          if(unread){
            newMessageStatus = true
          }
          this.$store.commit('setNewMessages', newMessageStatus);
        }
      },

      addConversation(conversation){
        this.conversations.unshift(conversation)
        this.selectedConversation += 1
      },

      async loadConversations(changeConversationOnViewer = true) {
        this.conversations = null
        let result = await conversationConnector.findConversations(this,this.pagination_query,this.contentType)
        if(this.contentType ==='Active'){
          this.conversations = result.conversations
          this.updateNewMessageStatus()
        }else if(this.contentType ==='Archived'){
          this.conversations = result.conversationsArchived
        }
        this.loaded = true
        this.selectedConversation = 0
        if(changeConversationOnViewer){
          this.changeConversationOnViewer()
        }
      },

      changeConversationOnViewer(){

        if(this.conversations){
          EventBus.$emit('change-conversation', {
            contentType:this.contentType,
            conversation:this.conversations[this.selectedConversation]
          })
        }
      },
      searchConversation(conversationId){
        return this.conversations.filter(function(conversation) {
          return conversation.id === conversationId;
        })[0];
      }
    },
    watch: {
      selectedConversation (newValue) {
      }
    },
    async mounted(){

      await this.loadConversations(true)

      EventBus.$on('changed-tab-for', (contentType) => {
          if(this.contentType === contentType){
            this.changeConversationOnViewer()
          }
      });

      EventBus.$on('remove-conversation', (data) => {
        if(this.contentType === data.contentType){
          this.conversations = this.conversations.filter(function(conversation) {
            return conversation.id !== data.conversation.id
          })
          if(this.selectedConversation !== 0) {
            this.selectedConversation -= 1
          }

          this.changeConversationOnViewer()
          if(data.reload){
            EventBus.$emit('reload-conversations', {
              contentType: data.reload,
              changeConversation: false
            })
          }

        }
      });

      EventBus.$on('reload-conversations', (data) => {
        if(this.contentType === data.contentType){
          this.loadConversations(data.changeConversation)
        }
      })

      EventBus.$on('new-conversation', (data) => {
        if(this.contentType === data.contentType){
          this.addConversation(data.conversation)
        }
      })

      EventBus.$on('new-message-in-conversations', (message) => {
      if(this.contentType === 'Active'){
        let conversation = this.searchConversation(message.conversation_id)
        if(conversation != null) {
          conversation.messages.push(message)
          if(message.type === 'inbound'){
            conversation.unread.counter += 1
            conversation.unread.ids.push(message.id)
          }


          let previousSelectionId = this.conversations[this.selectedConversation].id

          this.conversations = this.conversations.filter(function(conv) {
            return conv.id !== conversation.id
          })
          this.conversations.unshift(conversation)

          this.selectedConversation = this.conversations.findIndex(conv => {
            return conv.id === previousSelectionId;
          });

        }else {
          //not found wait 3 secs for list updated
          setTimeout(
              function() {
               EventBus.$emit('new-message-in-conversations',message)
              }, 1000);
        }
      }

      });
    }
  }
</script>
<style scoped>
.v-list-item-group .v-list-item--active {
  color: inherit;
  border: 2px;
  border-style: solid;
  border-color: var(--v-primary-base);
  background: var(--v-border-base);
  border-radius: 2px;
}

.v-list-item-group .v-list-item--active .v-avatar{
  border-style: solid;
  border-color: var(--v-error-base) !important;
}

.v-list-item-group .v-list-item:hover {

  background: var(--v-border-base);
  border-radius: 2px;
}

</style>