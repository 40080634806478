<template>
  <v-card max-height="50vh" min-height="50vh" class="shopsendBorder" elevation="0">

    <v-card-title>
      <v-row class="ordersCardTitle">
        <v-col cols="12" class="pl-2 pt-2 pb-2 text-center justify-center">
          <span class="pa-0 secondary--text text-subtitle-2 text-center font-weight-bold">CUSTOMER HISTORY</span>
        </v-col>
      </v-row>


    </v-card-title>
    <div v-if="ordersData.orders.length > 0">

          <div v-for="(order, index) in ordersData.orders" :key="order.id" class="mx-2 my-2 py-3">
            <v-row class="pl-4 pt-1">
              <v-col cols="7" class="pa-0 text-left">
                <a :href="order.link" target="_blank" class="pl-4 pt-0">
                  <span class="pa-0 primary--text text-subtitle-2"> Order #{{order.id}}</span>
                </a>
              </v-col>

              <v-col cols="4" class="pa-0 pr-6 text-right">
                <span class="pa-0 background--text text-caption">{{formatDate(order.created_at)}}</span>
              </v-col>
            </v-row>

            <v-row class="pl-4">
              <v-col cols="12" class="pa-0 pl-4 text-left">
                <span class="pa-0 font-weight-bold background--text text-subtitle-2">{{formatMoney(order.total_spent)}}</span>
              </v-col>
            </v-row>

            <v-row class="pl-8">
              <v-col cols="2" class="pa-0 text-right">
                <v-img
                    contain
                    max-height="60"
                    max-width="60"
                    :src='order.item.image'
                    style="border-style: solid;  border-color: var(--v-error-base) !important; border-radius: 15px;"
                ></v-img>
              </v-col>
              <v-col cols="8" class="pa-0 pl-2 pt-3 text-left justify-center">
                <span class="pa-0 mt-6 background--text text-subtitle-2">{{order.item.title}}</span>
                <br>
                <span class="pa-0 background--text text-subtitle-2">{{order.item.quantity}} item(s)</span>
              </v-col>
            </v-row>
          </div>
      <v-row class="pl-4 pt-3">
        <v-col cols="7" class="pa-0 text-left">
          <a :href="ordersData.customer_link" target="_blank" class="pl-6 pt-0">
            <span class="pa-0 primary--text text-subtitle-2">View more on Shopify</span>
          </a>
        </v-col>
      </v-row>
    </div>

    <div v-else>
      <v-card-title class="justify-center text-center pb-6 pt-8 mt-16">
        <img :src="market" :alt="market" >
      </v-card-title>
      <v-card-subtitle class="text-subtitle-2  justify-center text-center background--text">
        No recent data
      </v-card-subtitle>
    </div>

  </v-card>
</template>

<script>
  import marketLogo from '../../images/market-logo.png'
  import shopifyAppConnector from "../../connectors/shopifyAppConnector";

  export default {
    name: "conversations-contact",
    components: {
    },
    props: {
      email: {
        type: String,
        default: null
      },
    },
    data () {
      return {
        market: marketLogo,
        ordersData: {
          orders:[],
          customer_link:""
        },
        ordersCache: {}
      }
    },
    methods: {
      async reloadOrders(){
        this.ordersData = {
          orders:[],
          customer_link:""
        }
        let query = {
          shopify_domain: this.$store.getters.user.company_shopify_domain,
          email: this.email
        }
        let result = this.ordersCache[this.email]
        if(!result){
          result = await shopifyAppConnector.findLastOrders(this,query)
          this.ordersCache[this.email] = result
        }

/*            [
            {
              "id": "3666279399496",
              "link": "https://blackhalo.myshopify.com/admin/orders/3666279399496",
              "created_at": "2021-03-12T12:29:18.000Z",
              "total_spent": "325.0",
              "items_size": 1,
              "item": {
                "product_id": 4593494786120,
                "name": "Corrine Sheath - Ocean Blues / 2",
                "sku": "4852182oceab:2",
                "price": "325.00",
                "title": "Corrine Sheath",
                "quantity": 1,
                "image": "https://cdn.shopify.com/s/files/1/0215/2942/8040/products/BLACKHALO-1491.jpg?v=1590021156"
              }
            }
            ]*/

        this.ordersData = result
      },
    },
    watch: {
      async email(newValue) {
        if(newValue === null){
          this.ordersData = {
            orders:[],
            customer_link:""
          }
        }else{
          await this.reloadOrders()
        }
      }
    },
    async mounted(){
      if(this.email){
        await this.reloadOrders()
      }
    }
  }
</script>
<style scoped>
  .ordersCardTitle {
    background: var(--v-primary-base);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px
  }
</style>