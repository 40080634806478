var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.conversationsMode ? 'shopsendBorder pb-4 mb-4': 'pb-4 mb-4'},[(!_vm.conversationsMode)?_c('p',{staticClass:"text-caption text--background text-left mb-2"},[_c('span',{staticClass:"text-body-2 font-weight-bold text--background"},[_vm._v(" "+_vm._s(_vm.title))])]):_vm._e(),_vm._v(" "),(_vm.showNote && !_vm.conversationsMode)?_c('p',{staticClass:"text-caption accent--text text-center mb-0 p-background pa-2"},[_vm._v("\n    "+_vm._s(_vm.$vuetify.lang.t('$vuetify.smsEditor.note'))+"\n  ")]):_vm._e(),_vm._v(" "),_c('ValidationObserver',{ref:"vMessage",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"SMS","rules":_vm.buildValidators()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"text-caption text--background text-right mb-2",class:_vm.conversationsMode ? 'segment-pos-conv':'segment-pos'},[_vm._v("\n             "+_vm._s(_vm.segment_text)+"\n          ")]),_vm._v(" "),_c('v-textarea',{ref:"textarea",staticClass:"mt-n8",attrs:{"required":"","rows":_vm.conversationsMode ? 4 : 6,"flat":_vm.conversationsMode,"solo":_vm.conversationsMode,"outlined":!_vm.conversationsMode,"disabled":!_vm.enabled || _vm.disableSend,"label":_vm.conversationsMode ? _vm.$vuetify.lang.t('$vuetify.smsEditor.label'): null,"counter":"1600","hide-details":"true","background-color":"white","error-messages":errors},on:{"input":_vm.propagateMessage},model:{value:(_vm.internMessage),callback:function ($$v) {_vm.internMessage=$$v},expression:"internMessage"}}),_vm._v(" "),(_vm.tags)?_c('div',{class:_vm.conversationsMode ? 'tags-style py-0': 'tags-style tags-style-border tags-style py-0'},[_c('v-row',{staticClass:"pt-3 pb-3"},[(_vm.tags.length > 0)?_c('v-col',{staticClass:"pl-6 pr-0 py-2",attrs:{"cols":_vm.conversationsMode ? 2 : 3}},[_c('p',{staticClass:"text-caption font-weight-bold text--background text-center mb-0"},[_vm._v("\n                  Insert Variable\n                ")]),_vm._v(" "),_c('p',{staticClass:"text-caption text--background text-center mb-0"},[_vm._v("\n                  (click to add)\n                ")])]):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":_vm.conversationsMode ? 8 : 9}},[_c('v-slide-group',{attrs:{"show-arrows":""}},_vm._l((_vm.tags),function(tag,idx){return _c('v-slide-item',{key:idx},[_c('v-tooltip',{attrs:{"top":"","color":"background"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-2 accent--text",attrs:{"color":"background","label":"","link":""},on:{"click":function($event){return _vm.handleTag(tag.name)}}},'v-chip',attrs,false),on),[_c('span',{staticClass:"text-body-2 accent--text"},[_vm._v(_vm._s(_vm.tagString(tag.name)))]),_vm._v(" "),_c('v-icon',{staticClass:"px-4",attrs:{"right":"","color":"white"}},[_vm._v("\n                            $vuetify.icons.infoWhite\n                          ")])],1)]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"text-caption accent--text",domProps:{"innerHTML":_vm._s(tag.help)}})])],1)}),1)],1),_vm._v(" "),(_vm.conversationsMode)?_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"secondary--text mr-8",attrs:{"color":"background","rounded":"","disabled":!_vm.enabled || _vm.disableSend},on:{"click":function($event){return _vm.sendMessage()}}},[_vm._v("Send")])],1):_vm._e()],1),_vm._v(" "),_c('span',{staticClass:"v-messages theme--light error--text v-messages__message pl-4"},[_vm._v(" "+_vm._s(errors[0]))])],1):_vm._e()]}}],null,true)})],1)],1),_vm._v(" "),(_vm.discountSelector.discountIsPresent)?_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"5"}},[_c('p',{staticClass:"text-caption text-left mb-2"},[_c('span',{staticClass:"text-body-2 font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.$vuetify.lang.t('$vuetify.smsEditor.discount'))+" |\n        ")]),_vm._v(" "),_c('a',{staticClass:"pr-4 pt-0 primary--text",attrs:{"href":_vm.getLinkToCreateDiscount(),"target":"_blank"},on:{"click":function($event){return _vm.reloadDiscounts()}}},[_c('span',{staticClass:"text-body-2 primary--text"},[_vm._v("\n          "+_vm._s(_vm.$vuetify.lang.t('$vuetify.smsEditor.createDiscount'))+"\n          ")])])]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Discount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"discount",attrs:{"chips":"","solo":"","items":_vm.discounts,"loading":_vm.discountSelector.isLoading,"search-input":_vm.search,"hide-selected":"","auto-select-first":"","deletable-chips":"","item-text":"Description","item-value":"API","error-messages":errors},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":function($event){return _vm.selectDiscount()}},model:{value:(_vm.discountSelector.discountCode),callback:function ($$v) {_vm.$set(_vm.discountSelector, "discountCode", $$v)},expression:"discountSelector.discountCode"}})]}}],null,true)})],1)],1):_vm._e(),_vm._v(" "),(_vm.discountSelector.discountIsPresent)?_c('v-row',[_c('p',{staticClass:"text-caption mb-0 pa-0 pl-4"},[_vm._v("\n      "+_vm._s(_vm.$vuetify.lang.t('$vuetify.smsEditor.noteTwo'))+"\n    ")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }