<template>

<div>
  <v-row class="pl-16 pr-16 pt-16 mt-4 ml-10" v-show="showWelcome && fullName">
    <v-col cols="12">
      <span class="text-h3 background--text">{{$vuetify.lang.t('$vuetify.dashboard.welcomeBack')}}</span>
      <span class="text-h3 font-weight-bold info--text"> {{fullName}}! </span>
    </v-col>
  </v-row>

  <v-row class="pl-16 pr-16 pt-12 ml-12">
    <div class="pr-4 pt-4 pb-0 d-flex flex-row">
      <span class="text-h4 background--text"> {{company.name}} </span>
    </div>
    <v-divider vertical class="pl-1 mt-4 mb-4 divider"/>
    <v-col cols="1" class="pa-0 pt-1 pl-4 pb-0">
      <v-select
              style="min-width: 220px;"
              class="text-h5 font-weight-light background--text"
              v-model="interval"
              :items="intervalOptions"

              @change="findShopifySummary">
      </v-select>

    </v-col>
  </v-row>

  <v-row class="pl-16 pr-16 ml-12" v-if="loaded">
    <v-col cols="4" class="pl-0 flex-grow-0 flex-shrink-0">
      <v-card  max-height='38vh' min-height='38vh'>
        <v-row >
          <v-col cols="12" class="py-5 pl-10" >
            <p class="text-body-2 text-start background--text"> {{$vuetify.lang.t('$vuetify.dashboard.revenue')}} </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"  class="py-5">
            <p class="text-h3 font-weight-bold background--text"> {{formatMoney(summary.shopsend_revenue)}} </p>
            <p class="text-subtitle-2 background--text"> 🎉 {{formatMoney(summary.lifetime_value)}} in lifetime value </p>
          </v-col>
        </v-row>

        <v-row class="pb-4 pl-6">
          <v-col cols="4" offset='1'  class="py-0" style="border-left: 6px solid #ADBCFF;">
            <p class="text-caption text-end mb-0 background--text"> {{$vuetify.lang.t('$vuetify.dashboard.campaigns')}} </p>
            <p class="text-body-1 font-weight-bold text-end mb-0 background--text"> {{formatMoney(summary.campaigns)}} </p>
          </v-col>
          <v-col cols="4" offset='1' class="py-0" style="border-left: 6px solid #859BFF;">
            <p class="text-caption text-end mb-0 background--text"> {{$vuetify.lang.t('$vuetify.dashboard.automations')}} </p>
            <p class="text-body-1 font-weight-bold text-end mb-0 background--text"> {{formatMoney(summary.automations)}} </p>
          </v-col>
        </v-row>
        <v-row class="pb-8 pl-6">
          <v-col cols="4" offset='1' class="py-0" style="border-left: 6px solid #DDE4EE;">
            <p class="text-caption text-end mb-0 background--text"> {{$vuetify.lang.t('$vuetify.dashboard.totalRevenue')}} </p>
            <p class="text-body-1 font-weight-bold text-end mb-0 background--text"> {{formatMoney(summary.total_store_revenue)}} </p>
          </v-col>
          <v-col cols="4" offset='1' class="py-0" style="border-left: 6px solid #5C7AFF;">
            <p class="text-caption text-end mb-0 background--text"> {{$vuetify.lang.t('$vuetify.dashboard.discounts')}} </p>
            <p class="text-body-1 font-weight-bold text-end mb-0 background--text"> {{formatMoney(summary.discounts)}} </p>
          </v-col>
        </v-row>
      </v-card>

    </v-col>

    <v-col cols="8">
      <div v-if="loaded">
        <v-card
            max-height='38vh' min-height='38vh'>
          <dashboard-chart :labels="labels" :data-collection="datasets" />
        </v-card>
      </div>
    </v-col>
  </v-row>
</div>

</template>

<script>


  import dashboardChart from "./dashboard-chart"
  import shopifyAppConnector from "../../connectors/shopifyAppConnector"

  export default {
    name: "dashboard",
    props: {
      company: {
        type: Object,
        default: null
      },
    },
    components: {
      dashboardChart
    },
    data () {
      return {
        loaded: false,
        showWelcome: true,
        fullName: this.getFullName(),
        interval: 'Last 30 Days',
        intervalOptions: ['Last 7 Days', 'Last 30 Days', 'Last 90 Days'],
        summary: {
          total_store_revenue: 0,
          shopsend_orders: 0,
          shopsend_revenue: 0,
          campaigns: 0,
          automations: 0
        },
        labels: [],
        datasets: []
      }
    },
    methods: {

      findShopifySummary(){
        let query = this.buildTimeInterval()
        query.shopify_domain = this.company.shopify_domain
        shopifyAppConnector.findShopifySummary(this, query)
      },
      receiveSummary(response){
        this.summary = response.summary
        this.labels = response.report.labels
        this.datasets = response.report.datasets
        this.loaded = true
      },

      buildTimeInterval() {
        let start = new Date();
        if(this.interval === 'Last 90 Days') {
          start.setDate(start.getDate() - 89);
        }else if(this.interval === 'Last 30 Days'){
          start.setDate(start.getDate() - 29);
        }else{
          start.setDate(start.getDate() - 6);
        }

        return {
          start_date: start,
          end_date: new Date()
        }
      },
      getFullName() {
        let name = null
        if(this.$store.getters.user.name){
          name = this.$store.getters.user.name
        }
        if(name && this.$store.getters.user.last_name){
          name +=" " + this.$store.getters.user.last_name
        }

        return name
      }
    },
    mounted() {
      //this.showWelcome = this.$store.getters.showWelcome
      this.findShopifySummary()
    },
    destroyed() {
      //this.$store.commit('setShowWelcome', false);
    }
  }
</script>

<style>
  .feed{
    color: var(--v-error-base);
  }

  .divider {
    border-color: var(--v-background-base) !important;
    background: var(--v-background-base);
  }

  .v-text-field > .v-input__control > .v-input__slot:before {
    border-style: none;
  }

  .v-text-field > .v-input__control > .v-input__slot:after {
    border-style: none;
  }

  .v-select__selection--comma{
    padding: 5px;
    margin: 0px;
  }

  .v-select__selections > input {
    max-width: 0px;
  }


</style>