<template>
  <img :src="dsContacts">
</template>

<script>
  import dsContacts from '../../images/icons/ds-contacts.svg'

  export default {
    name: "ds-contacts",
    components: {
      dsContacts
    },
    data () {
      return {
        dsContacts: dsContacts

      }
    }
  }
</script>

<style scoped>

</style>