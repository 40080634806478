<template>
  <div>
    <v-card
      outlined
      class="summary-card"
      @click="redirectTo()"
    >
      <h2 class="summary-header background--text">
        {{ title }}
      </h2>

      <p class="summary-description background--text">
        {{ description }}
      </p>
    </v-card>
  </div>
</template>

<script>
  import router from '../../router'

  export default {
    name: "summary-link",
    props: {
      title: {
        type: String,
        default: ''
      },
      description : {
        type: String,
        default: ''
      },
      url : {
        type: String,
        default: ''
      },
      external: {
        type: Boolean,
        default: false
      }
    },
    components: {
    },
    data () {
      return {
      }
    },
    methods: {
      redirectTo () {
        if(this.url !== undefined && this.url !== '') {
          if(this.external){
            window.open(this.url, '_blank');
          }else{
            router.push(this.url);
          }
        }
      }
    },
    mounted(){
    }
  }
</script>
<style scoped>
  .summary-card {
    text-align: left;
    border: none;
    padding: 16px 20px;
    margin-top: 32px;
  }

  .summary-card:hover {
    box-shadow: 0 16px 32px -6px rgba(27, 27, 27, 0.12) !important;
  }

  .summary-header {
    font-size: 1.2rem;
    font-family: 'Sofia Pro';
  }

  .summary-description {
    font-size: 0.9rem;
    opacity: 0.6;
    margin-bottom: 0;
  }
</style>