<template>
  <img :src="dsCampaigns">
</template>

<script>
  import dsCampaigns from '../../images/icons/ds-campaigns-selected.svg'

  export default {
    name: "ds-campaings",
    components: {
      dsCampaigns
    },
    data () {
      return {
        dsCampaigns: dsCampaigns,

      }
    }
  }
</script>

<style scoped>

</style>