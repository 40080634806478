<template>
  <secure-content :show-header="false" :show-back="false" :remove-padding="true">
      <v-row class="pa-0 mr-0">
        <v-col cols="4" class="pa-0 mr-0">
          <v-card tile
                  color="background"
                  max-height='100vh'
                  min-height='100vh'
                  class="disable-events pa-0 mr-0 square"
                  >
            <br>
              <v-card-title class="pt-16 pb-16">
                <p class="text-h3 pt-16 pl-16 secondary--text">{{$vuetify.lang.t('$vuetify.profile.welcome')}}</p>
                <p class="text-h3 pl-16 ml-16 secondary--text">{{$vuetify.lang.t('$vuetify.profile.shopsend')}}</p>
              </v-card-title>

            <v-row class="pl-0 ml-n16">
              <img :src="squareLogo" alt="logo" width="330px" height="512px">
            </v-row>

          </v-card>
        </v-col >
        <v-col cols="8" class="pa-0 mr-0">
          <v-card tile
                  class="pa-0 mr-0 square"
                  height='100vh'>

            <profile :show-welcome="true" v-if="setupPage === 'profile'"/>
            <setup-company v-else-if="setupPage === 'company'"/>

          </v-card>
        </v-col>


      </v-row>
  </secure-content>
</template>

<script>

  import secureContent from '../secure-content.vue'
  import profile from "../user/profile"
  import setupCompany from "../onBoarding/setup-company"
  import logo from "../../images/shopsendiconbeige.svg"
  import {EventBus} from "../../packs/app";

  export default {
    name: "setup",
    components: {
      secureContent,
      profile,
      setupCompany
    },
    data () {
      return {
        squareLogo: logo,
        setupPage: 'profile'
      }
    },
    methods: {

    },
    mounted() {
      if(this.$route.params.page){
        this.setupPage = this.$route.params.page
      }
    },
    created() {

      EventBus.$on('setupPage', (page) => {
        this.setupPage = page
      });
    },
  }
</script>

<style scoped>
  .disable-events {
    pointer-events: none;
  }
  .square {
    border-radius: 0px !important;
  }

  .container {
    padding: 0px !important;
  }
</style>