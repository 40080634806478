<template>
  <v-form  @keyup.native.enter="keyPress($event)" type="" class="margins" autocomplete="on">
    <v-card-text class="text--primary">
      <span>{{$vuetify.lang.t('$vuetify.login.email')}}</span>
      <v-text-field name="login" filled autocomplete="on"
                    type="text" v-model="credentials.email"
      ></v-text-field>
      <span>{{$vuetify.lang.t('$vuetify.login.password')}}</span>
      <v-text-field name="password" filled autocomplete="on"
                    id="password" type="password" v-model="credentials.password">
      </v-text-field>

    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn right color="primary" @click="clickLogin()"  :disabled='buttonDisable'>
        <div class="pa-12">
          {{$vuetify.lang.t('$vuetify.login.loginButton')}}
        </div>
      </v-btn>
    </v-card-actions>

  </v-form>
</template>

<script>

  import auth from "../../models/auth";

  export default {
    name: "login-form.vue",
    data () {
      return {
        credentials: {
          email: '',
          password: ''
        },
        buttonDisable: false
      }
    },
    methods: {
      clickLogin () {
        this.buttonDisable = true
        auth.login(this, this.credentials)
      },
      enableButton() {
        this.buttonDisable = false
      },
      keyPress (event) {
        event.preventDefault()
        event.stopPropagation()
        this.clickLogin()
      }
    }
  }

</script>

<style>
  .margins {
    margin: 20px 70px;
  }
</style>