<template>
  <div :class="conversationsMode ? 'shopsendBorder pb-4 mb-4': 'pb-4 mb-4'">
    <p class="text-caption text--background text-left mb-2" v-if="!conversationsMode">
      <span class="text-body-2 font-weight-bold text--background"> {{ title }}</span>
    </p>
    <p class="text-caption accent--text text-center mb-0 p-background pa-2" v-if="showNote && !conversationsMode">
      {{ $vuetify.lang.t('$vuetify.smsEditor.note') }}
    </p>

    <ValidationObserver ref="vMessage" v-slot="{ validate, reset }">

      <v-row>
        <v-col cols="12" class="pb-0">
          <ValidationProvider v-slot="{ errors }" name="SMS" :rules="buildValidators()">
            <p :class="conversationsMode ? 'segment-pos-conv':'segment-pos'"
                class="text-caption text--background text-right mb-2">
               {{ segment_text}}
            </p>
            <v-textarea
                class="mt-n8"
                ref="textarea"
                required
                :rows="conversationsMode ? 4 : 6"
                :flat="conversationsMode"
                :solo="conversationsMode"
                :outlined="!conversationsMode"
                :disabled="!enabled || disableSend"
                :label="conversationsMode ? $vuetify.lang.t('$vuetify.smsEditor.label'): null"
                counter="1600"
                hide-details="true"
                background-color="white"
                v-model="internMessage"
                :error-messages="errors"
                @input="propagateMessage"
            ></v-textarea>

            <div v-if="tags" :class="conversationsMode ? 'tags-style py-0': 'tags-style tags-style-border tags-style py-0'">
              <v-row class="pt-3 pb-3">
                <v-col v-if="tags.length > 0" :cols="conversationsMode ? 2 : 3" class="pl-6 pr-0 py-2">
                  <p class="text-caption font-weight-bold text--background text-center mb-0">
                    Insert Variable
                  </p>
                  <p class="text-caption text--background text-center mb-0">
                    (click to add)
                  </p>
                </v-col>
                <v-col :cols="conversationsMode ? 8 : 9">
                  <v-slide-group show-arrows>
                    <v-slide-item v-for="(tag,idx) in tags"  v-bind:key="idx">
                      <v-tooltip top color="background">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                              class="mr-2 accent--text"
                              color="background"
                              label
                              link
                              v-bind="attrs"
                              v-on="on"
                              @click="handleTag(tag.name)"
                          >
                            <span class="text-body-2 accent--text">{{ tagString(tag.name) }}</span>
                            <v-icon right color="white" class="px-4">
                              $vuetify.icons.infoWhite
                            </v-icon>
                          </v-chip>
                        </template>
                        <div class="text-caption accent--text" v-html="tag.help"/>
                      </v-tooltip>
                    </v-slide-item>
                  </v-slide-group>
                </v-col>
                <v-col v-if="conversationsMode" class="text-right">
                  <v-btn color="background" class="secondary--text mr-8" rounded @click="sendMessage()" :disabled="!enabled || disableSend">Send</v-btn>
                </v-col>

              </v-row>
              <span class="v-messages theme--light error--text v-messages__message pl-4"> {{ errors[0] }}</span>
            </div>

          </ValidationProvider>
        </v-col>

      </v-row>

    <v-row v-if="discountSelector.discountIsPresent">
      <v-col cols="5" class="pb-0">
        <p class="text-caption text-left mb-2">
          <span class="text-body-2 font-weight-bold">
            {{ $vuetify.lang.t('$vuetify.smsEditor.discount') }} |
          </span>

          <a :href="getLinkToCreateDiscount()" target="_blank" class="pr-4 pt-0 primary--text" @click="reloadDiscounts()">
           <span class="text-body-2 primary--text">
            {{ $vuetify.lang.t('$vuetify.smsEditor.createDiscount') }}
            </span>
          </a>
        </p>
        <ValidationProvider v-slot="{ errors }" name="Discount" rules="required">
          <v-autocomplete
              ref="discount"
              chips
              solo
              v-model="discountSelector.discountCode"
              :items="discounts"
              :loading="discountSelector.isLoading"
              :search-input.sync="search"
              hide-selected
              auto-select-first
              deletable-chips
              item-text="Description"
              item-value="API"
              :error-messages="errors"
              @change = "selectDiscount()"
          ></v-autocomplete>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row v-if="discountSelector.discountIsPresent">
      <p class="text-caption mb-0 pa-0 pl-4">
        {{ $vuetify.lang.t('$vuetify.smsEditor.noteTwo') }}
      </p>
    </v-row>
    </ValidationObserver>
  </div>
</template>

<script>

  const { SegmentedMessage } = require('sms-segments-calculator');
  import {EventBus} from "../../packs/app";
  import shopifyAppConnector from "../../connectors/shopifyAppConnector";
  import campaignAutomationConnector from "../../connectors/campaignAutomationConnector";
  import conversationConnector from "../../connectors/conversationConnector";

  export default {
    name: "sms-editor",
    props: {
      title: {
        type: String,
        default: ''
      },
      trigger: {
        type: String,
        default: null
      },
      message: {
        type: String,
        default: null
      },
      discountCode: {
        type: String,
        default: null
      },
      messageEstimatedSegment: {
        type: Number,
        default: 1
      },
      errors: {
        type: Array,
        default: null
      },
      tags: {
        type: Array,
        default:()=> ([])
      },
      showNote: {
        type: Boolean,
        default: false
      },
      conversationsMode: {
        type: Boolean,
        default: false
      },
      enabled: {
        type: Boolean,
        default: true
      },
      fromPhone: {
        type: String,
        default: null
      },
      toPhone: {
        type: String,
        default: null
      },
      contentType: {
        type: String,
        default: null
      },
    },
    components: {

    },
    data () {
      return {
        discountSelector: {
          discountCode: this.discountCode,
          isLoading: false,
          entries: [],
          search: null,
          discountIsPresent: false
        },
        search: null,
        internMessage: this.message,
        internalTrigger: this.trigger,
        segments: this.messageEstimatedSegment,
        long_remaining:0,
        isMms: false,
        encoding: "",
        segment_text:'',
        discountsInAutomations: [],
        disableSend: false
      }
    },
    mounted() {
      this.$store.commit('setInternalTrigger', this.internalTrigger)

      EventBus.$on('trigger-changed', (data) => {
        this.internalTrigger = data.trigger
        this.$store.commit('setInternalTrigger', this.internalTrigger)
        this.filterDiscounts(this.$store.getters.discounts, this.discountsInAutomations)
        this.setupBaseSMS()
      });

      EventBus.$on('update-sms', (data) => {
        this.internMessage = data.message
        this.propagateMessage()
      });

      EventBus.$on('file-attached', (data) => {
        this.isMms = data.isMms
        this.countSegments()
      });


      this.discountSelector.isLoading = true

      let hasDiscountTag = this.tags.find(tag => tag.name === 'discount_code')
      if(hasDiscountTag){
        shopifyAppConnector.findDiscounts(this,{shopify_domain: this.$store.getters.user.company_shopify_domain})
      }

      this.setupBaseSMS()

      //remove tags if is not config the shopify store
      if(this.$store.getters.user.company_shopify_domain === undefined){
        for(let i = 0; i< this.tags.length; i++){
          if(this.tags[i].name === 'discount_code'){
            this.tags.splice(i, 1);
          }
        }
      }
    },
    computed: {
      discounts: {
        get(){
          return this.discountSelector.entries
        },
        set(newDiscounts){
          this.discountSelector.entries = newDiscounts
        }
      },
    },
    watch: {
      search (val) {
        // Items have already been loaded
        if (this.discounts.length > 0) return

        // Items have already been requested
        if (this.discountSelector.isLoading) return

        this.discountSelector.isLoading = true
        shopifyAppConnector.findDiscounts(this,{shopify_domain: this.$store.getters.user.company_shopify_domain})

      },
      internMessage(val) {
        this.countSegments()
      },
    },
    methods: {

      async sendMessage(){
        let result= await this.$refs.vMessage.validate()
        if(result) {
          this.disableSend = true
          conversationConnector.sendMessage(this, {
            fromPhone: this.fromPhone,
            toPhone: this.toPhone,
            message: this.internMessage,
            contentType: this.contentType
          })
        }
      },
      handleFail(){
        this.disableSend = false
      },
      receiveSentConfirmation(message){
        if(message.content_type === "Archived"){
          EventBus.$emit('reload-active-view')
        }

        this.$refs.vMessage.reset()
        this.internMessage = null
        this.disableSend = false
      },

      receiveDiscounts(discounts){
        campaignAutomationConnector.findDiscountCodesInAutomations(this, discounts)
      },
      receiveDiscountCodesInAutomations(discountsInAutomations, discounts){
        this.discountSelector.isLoading = false
        this.$store.commit('setDiscounts', discounts);
        this.discountsInAutomations = discountsInAutomations
        this.filterDiscounts(discounts,discountsInAutomations)
      },

      filterDiscounts(discounts,discountsInAutomations) {
        let restrictedCodes = []
        let internalTri = this.internalTrigger
        discounts = discounts.filter(function (discount){
          let founded  = discountsInAutomations.filter(function(discountInAutomation) {
            return discountInAutomation.code === discount.code && discountInAutomation.campaign_kind !== internalTri
          })
          if(founded.length === 0){
            return true
          }else {
            restrictedCodes.push(...founded)
            return false
          }
        })
        this.$store.commit('setRestrictedDiscounts', restrictedCodes);

        this.discountSelector.entries = discounts.map(function(discount) {
          return discount.code;
        })
        this.discountSelector.discountCode = this.discountCode
      },
      reloadDiscounts(){
        this.discounts = []
      },
      selectDiscount(){
        this.$emit('update:discountCode', this.discountSelector.discountCode)
        this.countSegments()
      },
      setupBaseSMS(){

          if(this.internalTrigger === "Abandoned-Checkout-automation"){
            this.internMessage ="{{brand_name}}: Hey! It looks like you didn't quite make it through checkout. \n" +
                "\nClick here to continue: {{cart_url}}"
          }else if(this.internalTrigger === "Welcome-Campaign-automation"){
            this.internMessage ="{{brand_name}}: Welcome! \n" +
                "Enjoy 20% off your first order using code {{discount_code}}\n" +
                "Check out our brand new styles!\n" +
                "Click here to shop:\n" +
                "\n{{legal_long}}"
          }else if(this.internalTrigger === "Abandoned-Cart-automation") {
            this.internMessage = "{{brand_name}}: We saw you left the {{highest_priced_item_name}} behind! Act fast, this one sells out often.\n\n"+
                "Shop your cart: {{highest_priced_item_url}}"
          }else if(this.internalTrigger === "Abandoned-Product-automation") {
            this.internMessage = "{{brand_name}}: Hey 👋🏼 You've got great taste!\n\nThe {{product_viewed}} is waiting for you! \n\nShop: {{product_viewed_url}}"
          } else if(this.conversationsMode){
            this.internMessage = ""
          }else {
            this.internMessage = "{{brand_name}}:"
          }

        this.countSegments()
        this.propagateMessage()
      },

      countSegments() {
        let baseCharLong = 160
        if(this.internMessage){
          let preprocessed_text = this.urlify(this.internMessage)
          preprocessed_text = this.messageVariablesPreview(
              preprocessed_text,
              this.$store.getters.user.company_name,
              this.discountSelector.discountCode,
              "-------------------------", //25 chars
              "-------------------------"//25 chars
          )

          const segmentedMessage = new SegmentedMessage(preprocessed_text);
          this.segments = segmentedMessage.segmentsCount
          this.encoding = segmentedMessage.encodingName

          if(this.encoding !== "GSM-7"){
            baseCharLong = 70
          }

          if(this.isMms){
            this.long_remaining = 1600 - preprocessed_text.length
            this.segments = 1
            this.segment_text = this.long_remaining+" | "+this.segments +" MMS"
          }else {
            this.long_remaining = (baseCharLong * this.segments) - preprocessed_text.length
            this.segment_text =  this.long_remaining+" | "+this.segments +" SMS"
          }
          this.$emit('update:messageEstimatedSegment', this.segments)
        }else{
          this.long_remaining = (baseCharLong * this.segments)
          this.segment_text =  this.long_remaining+" | "+this.segments +" SMS"
        }

      },
      propagateMessage() {
        this.discountSelector.discountIsPresent = this.internMessage.match(/{{\s*discount_code\s*}}/g) !== null
        if(!this.conversationsMode){
          this.$emit('update:message', this.internMessage)
        }
      },

      buildValidators() {
        let validators = { required: true, max: 1600 }
        for(let i=0;i<this.tags.length;i++){
          if(this.tags[i].required){
            validators[this.tags[i].name] = true
          }
        }
        return validators
      },
      getLinkToCreateDiscount() {
        return "https://"+ this.$store.getters.user.company_shopify_domain + "/admin/discounts/new"
      },
      handleTag(tagName){
        if (!tagName.length) return
        tagName = this.tagString(tagName)
        const textarea = this.$refs.textarea.$refs.input
        const sentence = textarea.value
        const len = sentence.length
        let pos = textarea.selectionStart
        if (pos === undefined) {
          pos = 0
        }

        const before = sentence.substr(0, pos)
        const after = sentence.substr(pos, len)

        this.internMessage = before + tagName + after
        this.propagateMessage()

        this.$nextTick().then(() => {
          textarea.selectionStart = pos + tagName.length
          textarea.selectionEnd = pos + tagName.length
          textarea.focus()
        })
      },
      tagString(tagName){
        return "{{" + tagName + "}}"
      }
    }
  }
</script>
<style scoped>
  .tags-style {
    background: white;
    border-radius: 0px 0px 10px 10px
  }

  .tags-style-border {
    border-width: 1px;
    border-style: solid;
    border-top-style: none;
    border-color: #BABECA;

  }

  .p-background {
    background-color: #BABECA;
    border-radius: 10px 10px 0px 0px
  }
  .segment-pos {
    position: relative;
    top: 60%;
    z-index: 1;
    right: 2%;
  }
  .segment-pos-conv {
    position: relative;
    top: 50%;
    z-index: 1;
    right: 2%;
  }

</style>
