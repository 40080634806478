<template>
  <v-snackbar
          v-model="show"
          :bottom="y === 'bottom'"
          :top="y === 'top'"
          :left="x === 'left'"
          :right="x === 'right'"
          :multi-line="mode === 'multi-line'"
          :vertical="mode === 'vertical'"
          :outlined="outlined"
          :timeout="timeout"
          :color="color"
          :content-class="getColorClass()"
  >
    <div v-if="html">
      <div v-html="text"></div>
    </div>
    <div v-else>
      {{ text }}
    </div>

    <template v-slot:action="{ attrs }">
      <v-btn
              dark
              text
              v-bind="attrs"
              :class="getColorClass()"
              @click="show = false"
      >
        Close
      </v-btn>
    </template>

  </v-snackbar>
</template>

<script>

  import { EventBus } from '../../packs/app';

  export default {
    name: "FlashMessage",
    data () {
      return {
        show :false,
        y: 'bottom',
        x: null,
        mode: '',
        timeout: 6000,
        text: '',
        html: false,
        color: 'info', //success', 'info', 'error', 'cyan darken-2'
        textColor: null,
        outlined: false
      }
    },
    methods: {
      getColorClass() {
        if(this.textColor !== null){
          return this.textColor + '--text'
        }else {
          return ''
        }
      }
    },
    beforeCreate() {
      EventBus.$on('flash-message', (message) => {
        this.show = true;
        this.y = message.y!== undefined ? message.y : this.y
        this.x = message.x!== undefined ? message.x : this.x
        this.html = message.html !== undefined ? message.html : this.html
        this.mode = message.mode!== undefined ? message.mode : this.mode
        this.outlined = message.outlined!== undefined ? message.outlined : this.outlined
        this.color = message.color!== undefined ? message.color : this.color
        this.textColor = message.textColor!== undefined ? message.textColor : this.textColor
        this.text = message.text!== undefined ? message.text : "Oops. Something went wrong. Please try again. If the problem persists please email support@Shopsend.io"
      });
    }

  }
</script>

<style scoped>

</style>