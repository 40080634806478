var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('secure-content',[_c('div',[_c('v-row',{staticClass:"pl-10 pr-10"},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":"","min-width":"500","min-height":"400"}},[_c('v-card-title',{staticClass:"headline justify-center margins"},[_c('br'),_vm._v(" "),_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.profile.changePassword')))])]),_vm._v(" "),_c('ValidationObserver',{ref:"vChange",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('v-form',{staticClass:"margins",attrs:{"type":"","autocomplete":"on"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.keyPress($event)}}},[_c('v-card-text',{staticClass:"text--primary"},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.profile.currentPassword')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"current password","rules":"required","vid":"currentPass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"login","filled":"","autocomplete":"on","id":"currentPassword","type":"password","error-messages":errors},model:{value:(_vm.user.currentPassword),callback:function ($$v) {_vm.$set(_vm.user, "currentPassword", $$v)},expression:"user.currentPassword"}})]}}],null,true)}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.profile.newPassword')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"new password","vid":"newPassword","rules":"required|min:8|upCase|number|differentTo:@currentPass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"newPassword","filled":"","autocomplete":"on","id":"newPassword","error-messages":errors,"append-icon":_vm.showNew ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showNew ? 'text' : 'password'},on:{"click:append":function($event){_vm.showNew = !_vm.showNew}},model:{value:(_vm.user.newPassword),callback:function ($$v) {_vm.$set(_vm.user, "newPassword", $$v)},expression:"user.newPassword"}})]}}],null,true)}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.profile.newPasswordConfirmation')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"new password confirmation","rules":"required|confirmed:newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"newPasswordConfirmation","filled":"","autocomplete":"on","id":"newPasswordConfirmation","error-messages":errors,"data-vv-as":"password","append-icon":_vm.showConfirmation ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showConfirmation ? 'text' : 'password'},on:{"click:append":function($event){_vm.showConfirmation = !_vm.showConfirmation}},model:{value:(_vm.user.newPasswordConfirmation),callback:function ($$v) {_vm.$set(_vm.user, "newPasswordConfirmation", $$v)},expression:"user.newPasswordConfirmation"}})]}}],null,true)})],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"right":"","color":"primary","disabled":_vm.buttonDisable},on:{"click":function($event){return _vm.changePassword()}}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.profile.changePasswordButton')))])],1)],1)]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }