<template>
  <div>
      <v-btn color="primary" block rounded @click="visibleForm = true">{{ $vuetify.lang.t('$vuetify.segment.newSegment') }}</v-btn>

    <v-dialog v-model="visibleForm"
              width="80vw" height="80vh" persistent>
      <v-card>

        <v-toolbar flat style="height: 32px;">
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-subtitle class="text-none text-h5 text-center py-0 px-16 background--text">
          {{ $vuetify.lang.t('$vuetify.segment.create') }}
        </v-card-subtitle>

        <v-card-subtitle class="text-none text-body-2 text-center pt-0 px-16 background--text">
          {{ $vuetify.lang.t('$vuetify.segment.subtitle') }}
        </v-card-subtitle>
        <v-card-text>
          <ValidationObserver :disabled="!visibleForm" ref="vCriteria" v-slot="{ validate, reset }">
            <v-container >
              <v-row class="px-6">
                <v-col cols="6">
                  <ValidationProvider v-slot="{ errors }" name="Segment name" :rules="{ required: true, min: 5, regexChar: /^[\w\s]+$/g }">
                    <p class="text-caption text-left mb-2">
                      <span class="text-body-2 font-weight-bold text--background"> {{$vuetify.lang.t('$vuetify.segment.name')}}</span>
                    </p>
                    <v-text-field
                        class="background--text"
                        required
                        outlined
                        counter="60"
                        background-color="white"
                        v-model="name"
                        :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <p class="text-left mb-2 px-6">
                <span class="text-body-2 font-weight-bold text--background"> {{$vuetify.lang.t('$vuetify.segment.definition')}}</span>
              </p>
              <div v-for="(criteria,idx) in rules" v-bind:key="idx">
                <v-row class="px-6">
                  <v-col class="px-1 py-0" cols="1">
                    <p class="text-center pt-3">
                      <span class="text-caption text--background"> {{$vuetify.lang.t('$vuetify.segment.contact')}}</span>
                    </p>
                  </v-col>
                  <v-col class="px-1 py-0" cols="2">
                    <ValidationProvider v-slot="{ errors }" :vid="'rule' + idx" name="rule" rules="required">
                      <v-select
                          :items="ruleNames"
                          class="background--text"
                          item-text="name"
                          item-value="id"
                          required
                          outlined
                          :key="criteria.id"
                          v-model="criteria.id"
                          :error-messages="errors" >
<!--                       @change="selectRuleName(idx, $event)" > -->
                        <template slot="selection" slot-scope="data">
                          <!-- HTML that describe how select should render selected items -->
                          <span class="text-caption text--background">
                            {{ data.item.name }}
                          </span>
                        </template>
                        <template slot="item" slot-scope="data">
                          <!-- HTML that describe how select should render items when the select is open -->
                          <span class="text-caption text--background">
                        {{ data.item.name }}
                      </span>
                        </template>
                      </v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col class="px-1 py-0" cols="2" v-if="criteria.id !== null && criteria.id !== '' && operators[criteria.id]">
                    <v-row>
                      <v-col class="pt-3 pl-2 pr-2 py-0">
                        <ValidationProvider v-slot="{ errors }" :vid="'operator' + idx" name="operator" rules="required">
                          <v-select
                              :items="operators[criteria.id]"
                              class="background--text"
                              item-text="name"
                              item-value="id"
                              required
                              outlined
                              :key="criteria.operator"
                              v-model="criteria.operator"
                              :error-messages="errors"
                          >
                            <template slot="selection" slot-scope="data">
                              <!-- HTML that describe how select should render selected items -->
                              <span class="text-caption text--background">
                        {{ data.item.name }}
                      </span>
                            </template>
                            <template slot="item" slot-scope="data">
                              <!-- HTML that describe how select should render items when the select is open -->
                              <span class="text-caption text--background">
                        {{ data.item.name }}
                      </span>
                            </template>
                          </v-select>
                        </ValidationProvider>
                      </v-col>
                      <v-col class="pt-3 pl-0 py-0 pr-2" v-if="criteria.id.includes('Spent')">
                        <ValidationProvider  v-slot="{ errors }" :vid="'keyValue' + idx" name="key value" :rules="{ required: true }">
                          <v-text-field
                              class="background--text"
                              required
                              outlined
                              background-color="white"
                              v-model="criteria.key"
                              :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="px-1 py-0" cols="1" v-if="criteria.id !== null && criteria.id !== '' && timeOperators[criteria.id]">
                    <ValidationProvider v-slot="{ errors }" :vid="'timeOption' + idx" name="time option" rules="required">
                      <v-select
                          :items="timeOperators[criteria.id]"
                          class="background--text"
                          item-text="name"
                          item-value="id"
                          required
                          outlined
                          :key="criteria.timeOperator"
                          v-model="criteria.timeOperator"
                          :error-messages="errors"
                      >
                        <template slot="selection" slot-scope="data">
                      <span class="text-caption text--background">
                        {{ data.item.name }}
                      </span>
                        </template>
                        <template slot="item" slot-scope="data">
                      <span class="text-caption text--background">
                        {{ data.item.name }}
                      </span>
                        </template>
                      </v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col class="px-1 py-0" cols="4" v-if="criteria.timeOperator != null && criteria.timeOperator != '' && criteria.timeOperator != 'ever'">
                    <v-row>
                      <v-col class="pt-3 px-2 py-0" cols="4">
                        <ValidationProvider v-slot="{ errors }"
                                            name="start date"
                                            rules="required"
                                            mode="eager"
                                            :vid="'startDate' + idx"
                        >

                          <custom-datetime type="date"
                                           class="background--text"
                                           v-model="criteria.startDate"
                                           auto
                                           :text-field-props="{
                                     outlined: true,
                                     backgroundColor: 'white',
                                     appendIcon: 'mdi-calendar-question',
                                     errorMessages: errors
                              }"
                          ></custom-datetime>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="1" class="px-2 py-0" v-if="criteria.timeOperator == 'between'">
                        <p class="text-center pt-6">
                          <span class="text-caption text--background"> and </span>
                        </p>
                      </v-col>
                      <v-col cols="4" class="pt-3 px-2 py-0" v-if="criteria.timeOperator == 'between'">
                        <ValidationProvider v-slot="{ errors }"
                                            name="end date"
                                            rules="required"
                                            mode="eager"
                                            :vid="'endDate' + idx"
                        >

                          <custom-datetime type="date"
                                           class="background--text"
                                           v-model="criteria.endDate"
                                           auto
                                           :text-field-props="{
                                     outlined: true,
                                     backgroundColor: 'white',
                                     appendIcon: 'mdi-calendar-question',
                                     errorMessages: errors
                              }"
                          ></custom-datetime>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="pt-3 px-1 py-0 my-2 mr-auto" cols="2" >
                    <v-row>
                      <v-btn color="primary" outlined @click="addOtherRule(idx,'and')">
                        + AND
                      </v-btn>
                      <v-btn color="primary" outlined @click="addOtherRule(idx,'or')">
                        + OR
                      </v-btn>
                      <v-btn depressed @click="removeRule(idx)" v-if="rules.length > 1">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-row>

                  </v-col>
                </v-row>
                <v-divider class="my-4" v-if="criteria.nextOperation==='and'"></v-divider>
                <div  v-else>
                  <p class="text-left pa-0 ma-0 mt-n6 pb-2 pl-12">
                    <span class="text-caption text--background"> OR </span>
                  </p>
                </div>
              </div>

              <p class="text-left mb-2 px-6">
                <span class="text-body-2 font-weight-bold text--background"> {{$vuetify.lang.t('$vuetify.segment.estimation')}}</span>
                <span class="text-body-2 text--background">   {{estimation}} Contacts </span>
                <v-btn @click="runRules()" small :loading="loading">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </p>

            </v-container>
          </ValidationObserver>
        </v-card-text>


        <v-card-actions class="justify-center pb-12">

          <v-btn color="primary" @click="saveRules()" :loading="loading">
            <v-icon>mdi-lock</v-icon>
            {{$vuetify.lang.t('$vuetify.segment.save')}}
          </v-btn>


        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>


  import router from "../../router";
  import customDatetime from "../base/customDatetime";
  import segmentConnector from "../../connectors/segmentConnector";
  import {EventBus} from "../../packs/app";

  export default {
    name: "create-segment",
    components: {
      customDatetime,
    },
    props: {

    },
      watch: {

    },
    data () {
      return {
        visibleForm: false,
        loading: false,
        estimation: 0,
        name: '',
        rules: [{
          id: '',
          key: '',
          operator: '',
          value: '',
          timeOperator: '',
          startDate: '',
          endDate: '',
          nextOperation : 'and'
        }],
        ruleNames:[
          {
            id: "hasSpent",
            name: "Has Spent"
          },
          {
            id: "hasNotSpent",
            name: "Has Not Spent"
          },
          {
            id: "hasBeenCreated",
            name: "Has Been Created"
          },
          {
            id: "hasReceived",
            name: "Has Received"
          },
          {
            id: "hasNotReceived",
            name: "Has Not Received"
          },
          {
            id: "hasClicked",
            name: "Has Clicked"
          },
          {
            id: "hasNotClicked",
            name: "Has Not Clicked"
          },
        ],
        operators: {
          hasSpent: [
            {
              id: ">",
              name: "More than"
            },
            {
              id: "<",
              name: "Less than"
            },
            {
              id: "=",
              name: "Equal to"
            },
            {
              id: ">=",
              name: "At least"
            },
            {
              id: "<=",
              name: "At most"
            }
          ],
          hasNotSpent: [
            {
              id: ">",
              name: "More than"
            },
            {
              id: "<",
              name: "Less than"
            },
            {
              id: "=",
              name: "Equal to"
            },
            {
              id: ">=",
              name: "At least"
            },
            {
              id: "<=",
              name: "At most"
            }
          ],
          hasReceived: [ {
            id: "automation",
            name: "Automation"
          }],
          hasNotReceived: [ {
            id: "automation",
            name: "Automation"
          }],
          hasClicked: [ {
            id: "text",
            name: "Text message link"
          }],
          hasNotClicked: [ {
            id: "text",
            name: "Text message link"
          }],
        },
        timeOperators: {
          hasSpent: [
            {id: "ever", name: "Ever"},
            {id: "before", name: "Before"},
            {id: "after", name: "After"},
            {id: "between", name: "Between"}
          ],
          hasNotSpent: [
            {id: "ever", name: "Ever"},
            {id: "before", name: "Before"},
            {id: "after", name: "After"},
            {id: "between", name: "Between"}
          ],
          hasBeenCreated: [
            {id: "before", name: "Before"},
            {id: "after", name: "After"},
            {id: "between", name: "Between"}
          ],
          hasReceived: [
            {id: "ever", name: "Ever"},
            {id: "before", name: "Before"},
            {id: "after", name: "After"},
            {id: "between", name: "Between"}
          ],
          hasNotReceived: [
            {id: "ever", name: "Ever"},
            {id: "before", name: "Before"},
            {id: "after", name: "After"},
            {id: "between", name: "Between"}
          ],
          hasClicked: [
            {id: "ever", name: "Ever"},
            {id: "before", name: "Before"},
            {id: "after", name: "After"},
            {id: "between", name: "Between"}
          ],
          hasNotClicked: [
            {id: "ever", name: "Ever"},
            {id: "before", name: "Before"},
            {id: "after", name: "After"},
            {id: "between", name: "Between"}
          ],
        },
      }
    },
    mounted () {

    },
    destroyed () {
    },
    methods: {
      close() {
        this.visibleForm = false
      },
      async saveRules(){
        let result = await this.$refs.vCriteria.validate()

        if (result) {
          this.loading = true
          let transformedRules = this.prepareRules(this.rules);

          let newSegment = await segmentConnector.createSegment(this, this.name, transformedRules)
          EventBus.$emit('createdNewSegment', newSegment);
          this.loading = false
          this.visibleForm = false
          this.rules = [{
            id: '',
            key: '',
            operator: '',
            value: '',
            timeOperator: '',
            startDate: '',
            endDate: '',
            nextOperation : 'and'
          }]
        }
      },
      async runRules(){
        let result = await this.$refs.vCriteria.validate()

        if (result) {
          this.loading = true
          let transformedRules = this.prepareRules(this.rules);

          let result = await segmentConnector.runRules(this,transformedRules)
          this.estimation = result
          this.loading = false
        }
      },
      handleFail(error){
        //console.log(error.data.message)
        this.loading = false
      },
      addOtherRule(idx,type){
        let buffer = this.rules[idx].nextOperation
        this.rules[idx].nextOperation = type
        this.rules.splice(idx+1, 0, {
          id: '',
          key: '',
          operator: '',
          value: '',
          timeOperator: '',
          startDate: '',
          endDate: '',
          nextOperation : buffer
        })
      },
      removeRule(idx){
        if (idx > 0) {
          if(this.rules[idx-1].nextOperation === 'or' && this.rules[idx].nextOperation === 'and'){
            this.rules[idx-1].nextOperation = 'and'
          }
        }
        this.rules.splice(idx, 1);
      },

      selectRuleName(idx,newValue) {
        const newLastCamelCaseWord = newValue.split(/(?=[A-Z][^A-Z]+$)/).at(-1)
        const oldLastCamelCaseWord = this.rules[idx].id.split(/(?=[A-Z][^A-Z]+$)/).at(-1)
        if(newLastCamelCaseWord !== oldLastCamelCaseWord){
          this.rules[idx].key = ''
          this.rules[idx].operator = ''
        }
        this.rules[idx].id = newValue
      },
      addRule() {
        this.rules.push({
          id: '',
          key: '',
          operator: '',
          value: '',
          timeOperator: '',
          startDate: '',
          endDate: '',
          nextOperation : 'and'
        })
      },
      prepareRules(rules) {
        let transformedRules = {}
        let orBuffer = {}
        let uniqueIdIndex = 0
        for (let i = 0; i < rules.length; i++) {

          let rule = {
            key: rules[i].key,
            operator: rules[i].operator,
            timeOperator:  rules[i].timeOperator
          }
          if (rules[i].timeOperator === 'between') {
            rule.value = {
              startDate: rules[i].startDate,
              endDate: rules[i].endDate.replace('00:00:00.000','23:59:59.999')
            }
          } else if (rules[i].timeOperator !== 'ever') {
            rule.value = rules[i].startDate
          }

          let ruleId = rules[i].id
          if(ruleId === 'hasReceived' || ruleId === 'hasNotReceived' ||
              ruleId === 'hasClicked' || ruleId === 'hasNotClicked') {
            ruleId = ruleId + rules[i].operator.charAt(0).toUpperCase() + rules[i].operator.slice(1);
          }

          if(rules[i].nextOperation === 'or'){
            orBuffer[ruleId+"_"+ ++uniqueIdIndex] = rule
          }else{
            if(Object.keys(orBuffer).length !== 0){ // and closing an OR
              orBuffer[ruleId+"_"+ ++uniqueIdIndex] = rule
              transformedRules['orGroup_'+ ++uniqueIdIndex] = orBuffer
              orBuffer = {}
            }else{
              transformedRules[ruleId+"_"+ ++uniqueIdIndex] = rule
            }
          }
        }
        return transformedRules
      }

    }
  }
</script>

<style scoped>
  .v-card__text, .v-card__title {
    word-break: normal; /* maybe !important  */
  }
</style>