import connector from './connector'
import {EventBus} from "../packs/app";

const SEGMENT_ESTIMATION_URI = '/segment/estimate';
const CREATE_SEGMENT_URI = '/segment';

const contactConnector = {

  createSegment (component, name, rules) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'post',
            url: CREATE_SEGMENT_URI,
            data: {name: name, rules: rules}
          },function (component,response) {
            resolve(response.data.response);
          },
          false,
          null,
          function (component, error) {
            component.handleFail(error)
          });
      }
    );
  },

  runRules (component,rules) {
    return new Promise(
      (resolve, reject) => {
        connector.send(component,
          {
            method: 'post',
            url: SEGMENT_ESTIMATION_URI,
            data: {rules: rules}
          },function (component,response) {
            resolve(response.data.response);
          },
          false,
          null,
          function (component, error) {
            component.handleFail(error)
          });
      }
    );
  },

 };
export default contactConnector
