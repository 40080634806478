import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'vuex', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: state => ({
    apiKey: state.apiKey,
    abilities: state.abilities,
    user: state.user,
    company: state.company,
    itemMenuSelected: state.itemMenuSelected,
    iconsMenu: state.iconsMenu,
    billingSummary: state.billingSummary,
    activeContacts: state.activeContacts,
    discounts: state.discounts,
    newMessages: state.newMessages,
    restrictedDiscounts: state.restrictedDiscounts,
    internalTrigger: state.internalTrigger,
    mustShowSuccessPayment: state.mustShowSuccessPayment,
    planSelected: state.planSelected,
  })
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export const store = new Vuex.Store({
  state: {
    apiKey: null,
    user: {},
    company: {},
    abilities: {},
    itemMenuSelected: 0,
    iconsMenu: ["$dashboardSelected","$contacts", "$campaigns", "$conversations"],
    billingSummary: {},
    activeContacts: 0,
    discounts: [],
    newMessages: false,
    restrictedDiscounts: [],
    internalTrigger: null,
    mustShowSuccessPayment: false,
    planSelected: null
  },
  getters: {
    apiKey: state => state.apiKey,
    user: state => state.user,
    company: state => state.company,
    abilities: state => state.abilities,
    billingSummary: state => state.billingSummary,
    itemMenuSelected: state => state.itemMenuSelected,
    iconsMenu: state => state.iconsMenu,
    activeContacts: state => state.activeContacts,
    discounts: state => state.discounts,
    newMessages: state => state.newMessages,
    restrictedDiscounts: state => state.restrictedDiscounts,
    internalTrigger: state => state.internalTrigger,
    mustShowSuccessPayment: state => state.mustShowSuccessPayment,
    planSelected: state => state.planSelected,

  },
  mutations: {
    setApiKey(state, apiKey) {
      state.apiKey = apiKey
    },
    setUser(state, user) {
      state.user = user
    },
    setCompany(state, company) {
      state.company = company
    },
    setAbilities(state, abilities) {
      state.abilities = abilities
    },
    setBillingSummary(state, billingSummary) {
      state.billingSummary = billingSummary
    },
    setItemMenuSelected(state, itemMenuSelected) {
      state.itemMenuSelected = itemMenuSelected
    },
    setIconsMenu(state, iconsMenu) {
      state.iconsMenu = iconsMenu
    },
    setActiveContacts(state, activeContacts) {
      state.activeContacts = activeContacts
    },
    setNewMessages(state, newMessages) {
      state.newMessages = newMessages
    },
    setDiscounts(state, discounts) {
      state.discounts = discounts
    },
    setRestrictedDiscounts(state, restrictedDiscounts) {
      state.restrictedDiscounts = restrictedDiscounts
    },
    setInternalTrigger(state, internalTrigger) {
      state.internalTrigger = internalTrigger
    },
    setMustShowSuccessPayment(state, mustShowSuccessPayment) {
      state.mustShowSuccessPayment = mustShowSuccessPayment
    },
    setPlanSelected(state, planSelected) {
      state.planSelected = planSelected
    }
  },
  actions: {

  },
  plugins: [vuexLocalStorage.plugin]
});