<template>
  <div class="align-right">
    <v-item-group class="v-btn-toggle">
    <div>
      <v-btn  small outlined @click="eventHandler($event, 'edit')" class="text-capitalize background--text ">
        {{ this.$vuetify.lang.t('$vuetify.popupActions.edit') }}
      </v-btn>
    </div>
    <div>
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
                  outlined
                  v-on="on"
                  small
                  class="background--text"
          >
            <v-icon >mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item  @click="eventHandler($event,'report')"  v-if="popup.campaign_id">
            <v-list-item-title class="background--text">
              <v-icon color="black">mdi-format-list-checkbox</v-icon>
              {{ this.$vuetify.lang.t('$vuetify.popupActions.viewReport') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="eventHandler($event,'archive')">
            <v-list-item-title class="background--text">
              <v-icon color="red">mdi-close-circle-outline</v-icon>
              {{ this.$vuetify.lang.t('$vuetify.popupActions.archive') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-item-group>
  </div>
</template>

<script>
  import router from '../../router'
  import {EventBus} from "../../packs/app";
  import popupConnector from "../../connectors/popupConnector";
  import campaignConnector from "../../connectors/campaignConnector";

  export default {
    name: "campaign-actions",
    props: {
      popup: {
        type: Object,
        default: {}
      }
    },
    components: {
    },
    data () {
      return {
      }
    },
    methods: {
      eventHandler(event, action){
        if(action === "edit"){
          this.edit()
        }else if(action === "archive"){
          this.archive()
        }else if(action === "report"){
          this.openReport()
        }
      },
      edit(){
        router.push('/popup-editor/edit/' + this.popup.id)
      },
      openReport() {
        router.push('/campaign/'+ this.popup.campaign_id)
      },
      async archive(){

        const choice = await this.$confirm(this.$vuetify.lang.t('$vuetify.popupActions.archiveConfirm.message'),
            {
              subtitle: this.$vuetify.lang.t('$vuetify.popupActions.archiveConfirm.subtitle'),
              buttonFalseText: this.$vuetify.lang.t('$vuetify.popupActions.archiveConfirm.cancel'),
              buttonTrueText: this.$vuetify.lang.t('$vuetify.popupActions.archiveConfirm.ok'),
              width: 650
            })
        if (choice){
          let response = await popupConnector.archivePopup(this,this.popup.id)
          if(response === true){
            EventBus.$emit('update-popup-list');
          }
        }
      }
    },
    mounted(){
    }
  }
</script>
<style scoped>

</style>