var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"vChange",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('v-form',{staticClass:"pl-16 pr-16",attrs:{"type":"","autocomplete":"on"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.keyPress($event)}}},[_c('div',{staticClass:"pt-8"},[_c('p',{staticClass:"text-subtitle-1 pl-4 text--background text-start",domProps:{"innerHTML":_vm._s(_vm.$vuetify.lang.t('$vuetify.emailService.welcome'))}})]),_vm._v(" "),_c('v-card-text',{staticClass:"text-subtitle-1 text--background"},[_c('p',{staticClass:"text-subtitle-1 text--background text-start mb-0"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.emailService.platform')))]),_vm._v(" "),_c('v-item-group',{staticClass:"pb-12",attrs:{"mandatory":""}},[_c('v-row',_vm._l((_vm.services),function(service){return _c('v-col',{key:service.name,attrs:{"cols":"12","md":"3"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:_vm.defineClass(active,hover),attrs:{"height":"150"},on:{"click":function($event){return _vm.setActive(service.name,toggle)}}},[_c('div',{staticClass:"flex-grow-1 text-center"},[_c('img',{attrs:{"src":service.image,"alt":service.name,"width":"180px","height":"60px"}})])])]}}],null,true)})]}}],null,true)})],1)}),1)],1),_vm._v(" "),(_vm.active === 'Klaviyo')?_c('div',[_c('p',{staticClass:"text-subtitle-1 text--background text-start mb-0"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.emailService.key')))]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"Api key","vid":"api","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"api","outlined":"","dense":"","autocomplete":"on","id":"api","placeholder":"pk_xxxxxxxxxxxxxxxxxxx","error-messages":errors,"type":"text"},model:{value:(_vm.apiKey),callback:function ($$v) {_vm.apiKey=$$v},expression:"apiKey"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('p',{staticClass:"text-subtitle-1 text--background text-start mb-0"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.emailService.lists')))]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"list","vid":"list","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"list","outlined":"","dense":"","autocomplete":"on","id":"list","placeholder":"New Subscribers","error-messages":errors,"type":"text"},model:{value:(_vm.lists),callback:function ($$v) {_vm.lists=$$v},expression:"lists"}})]}}],null,true)})],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.active === 'Omnisend')?_c('div',[_c('p',{staticClass:"text-subtitle-1 text--background text-start mb-0"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.emailService.key')))]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"Api key","vid":"api","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"api","outlined":"","dense":"","autocomplete":"on","id":"api","placeholder":"pk_xxxxxxxxxxxxxxxxxxx","error-messages":errors,"type":"text"},model:{value:(_vm.apiKey),callback:function ($$v) {_vm.apiKey=$$v},expression:"apiKey"}})]}}],null,true)})],1)],1)],1):_vm._e()],1),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"right":"","color":"primary","disabled":_vm.buttonDisable},on:{"click":function($event){return _vm.sendData()}}},[_c('div',{staticClass:"pa-16"},[_vm._v("\n          "+_vm._s(_vm.$vuetify.lang.t('$vuetify.emailService.continue'))+"\n        ")])]),_vm._v(" "),_c('v-btn',{attrs:{"right":"","plain":""},on:{"click":function($event){return _vm.skip()}}},[_c('div',{staticClass:"pa-16"},[_vm._v("\n          "+_vm._s(_vm.$vuetify.lang.t('$vuetify.emailService.skip'))+"\n        ")])])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }