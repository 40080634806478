import connector from './connector'


const shopifyAppConnector = {

  findShopifySummary(component,query){
    connector.sendToShoppifyApp(component,
      {
        method: 'post',
        url: "/api/v1/orders/summary",
        data: query
      },function (component,response) {
        component.receiveSummary(response.data);
      })
  },

  findLastOrders(component,query){
    return new Promise(
      (resolve, reject) => {
        connector.sendToShoppifyApp(component,
          {
            method: 'post',
            url: "/api/v1/orders/last",
            data: query
          },function (component,response) {
            resolve(response.data);
          })
      }
    );
  },

  findDiscounts(component,query){
    connector.sendToShoppifyApp(component,
      {
        method: 'post',
        url: "/api/v1/discounts",
        data: query
      },function (component,response) {
        component.receiveDiscounts(response.data);
      })
  }
 };
export default shopifyAppConnector
